import React from 'react';
import PropTypes from 'prop-types';
import MatterportIcon from '../../../images/svg/icons/MatterportIcon';

export const SectionButton = ({
  matterportUrl,
  gaLabel,
  onMouseEnter,
  onMouseLeave,
  trackGAConversion,
  onClick,
}) => {
  const handleClick = () => {
    trackEvent();
    onClick();
  };

  const trackEvent = () => {
    trackGAConversion('CTA', 'CTA Click', gaLabel);
  };

  return (
    <div className="section-button">
      <button
        type="button"
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <MatterportIcon />
        <div className="see-3d">See it in 3D</div>
      </button>
      <a
        className="mobile-section-link"
        href={matterportUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackEvent}
      >
        <MatterportIcon />
        <div>See it in 3D</div>
      </a>
    </div>
  );
};

SectionButton.defaultProps = {
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  matterportUrl: '',
  gaLabel: '',
};

SectionButton.props = {
  matterportUrl: PropTypes.string,
  gaLabel: PropTypes.string,
};

export default SectionButton;
