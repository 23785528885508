import styled from 'styled-components';
import Colors from '../../../colors';

const EnergySmartZeroSliderStyles = styled.div`
  background: ${Colors.secondary.grey6.standard};
  min-height: 400px;
  position: relative;

  @media only screen and (min-width: 768px) {
  }

  @media only screen and (min-width: 1025px) {
    margin-top: -75px;
    padding: 103px 108px 62px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 762px;

    &.promo-background {
      background-color: #00b0ad;
      margin-top: -95px !important;
      min-height: none;
    }
  }

  .superscript {
    vertical-align: top;
    position: absolute;
  }

  .energy-smart-img-container {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 50px 0;

    @media only screen and (min-width: 768px) {
      width: 92%;
    }

    @media only screen and (min-width: 1025px) {
      width: 66%;
      align-self: flex-start;
      padding: 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .custom-dots {
      @media only screen and (max-width: 1024px) {
        display: none;
      }
      .dot {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background: ${Colors.accent.claytonGreen.standard};
        display: inline-block;
        border: solid 2px #fff;
        cursor: pointer;
        position: absolute;
        &.active {
          background: ${Colors.secondary.kiddiePool.standard};
        }
      }
    }

    .dot0 {
      bottom: 51%;
      left: 14%;
    }

    .dot9 {
      bottom: 39%;
      left: 27%;
    }

    .dot1 {
      bottom: 41%;
      left: 23%;
    }

    .dot2 {
      bottom: 29.5%;
      left: 49%;
    }

    .dot3 {
      bottom: 41%;
      left: 60.5%;
    }

    .dot4 {
      bottom: 41%;
      left: 77%;
    }

    .dot5 {
      bottom: 49%;
      left: 79%;
    }

    .dot6 {
      bottom: 65%;
      left: 30%;
    }

    .dot7 {
      bottom: 74%;
      left: 73.5%;
    }

    .dot8 {
      bottom: 41.75%;
      left: 36.75%;
    }
  }

  .energy-smart-slider-container {
    padding: 0 0 35px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    align-self: flex-start;
    position: relative;
    margin: 0 auto;
    width: 87%;
    @media only screen and (min-width: 768px) {
      width: 67%;
      max-width: 512px;
      min-height: 617px;
      padding: 0 0 68px;
    }

    @media only screen and (min-width: 1025px) {
      max-width: 392px;
      padding: 0 0 32px;
      min-height: auto;
      bottom: unset;
      top: 50px;
    }

    @media only screen and (min-width: 1200px) {
      width: 33%;
      top: unset;
    }

    .carrier {
      max-height: 532px;
    }

    .ecobee {
      max-height: 432px;
    }

    .thermoply {
      max-height: 494px;
    }

    .lux {
      max-height: 510px;
    }

    .rheem {
      max-height: 531px;
    }

    .lasalle {
      max-height: 467px;
    }

    .insulation {
      max-height: 558px;
    }

    .solar {
      max-height: 376px;
    }

    .frigidaire {
      max-height: 508px;
    }

    .samsung {
      max-height: 508px;
    }

    .energy-smart-btn-prev {
      position: absolute;
      z-index: 9;
      border: none;
      background: none;
      outline: none;
      top: 86px;
      left: 16px;
      cursor: pointer;

      @media only screen and (min-width: 1025px) {
        left: 39px;
      }

      @media only screen and (min-width: 1200px) {
        left: 5px;
      }

      @media only screen and (min-width: 1400px) {
        left: 19px;
      }

      &.disabled {
        opacity: 0.3;
      }

      img {
        max-width: 55px;

        @media only screen and (min-width: 1200px) {
          max-width: 48px;
        }

        @media only screen and (min-width: 1400px) {
          max-width: 58px;
        }
      }
    }
    .energy-smart-btn-next {
      position: absolute;
      z-index: 99;
      border: none;
      background: none;
      outline: none;
      top: 86px;
      right: 16px;
      cursor: pointer;

      @media only screen and (min-width: 1025px) {
        right: 39px;
      }

      @media only screen and (min-width: 1200px) {
        right: 5px;
      }

      @media only screen and (min-width: 1400px) {
        right: 19px;
      }

      &.disabled {
        opacity: 0.3;
      }

      img {
        max-width: 55px;

        @media only screen and (min-width: 1200px) {
          max-width: 48px;
        }

        @media only screen and (min-width: 1400px) {
          max-width: 58px;
        }
      }
    }

    .energy-smart-slider {
      .carousel-slider {
        .slide {
          display: flex;
          align-self: flex-start;
          height: auto;
        }
      }
      .energy-smart-slide {
        display: flex;
        flex-direction: column;

        .slide-headline {
          margin-bottom: 21px;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.35px;
          text-align: center;
          margin-top: 22px;
          position: relative;

          @media only screen and (min-width: 768px) {
            font-size: 25px;
            line-height: 30px;
          }

          @media only screen and (min-width: 1025px) {
            text-align: left;
            padding: 0 24px;
            margin-top: 31px;
          }

          span {
            font-size: 16px;
            font-weight: 700;
            top: 24px;
          }
        }

        .slide-text {
          margin-bottom: 34px;
          color: ${Colors.primary.black.standard};
          padding: 0 21px;
          text-align: left;
          position: relative;

          @media only screen and (min-width: 768px) {
            margin-bottom: 50px;
            padding: 0 36px;
          }

          @media only screen and (min-width: 1025px) {
            margin-bottom: 32px;
            padding: 0 24px;
          }

          &.has-disclaimer {
            margin-bottom: 15px;
          }

          span {
            top: 26px;
            font-size: 11px;
          }
        }

        .slide-link {
          color: ${Colors.primary.black.standard};
          padding: 0 21px;
          text-align: left;
          font-size: 16px;
          line-height: 1.69;
          text-decoration: underline;
          font-weight: 400;
          font-family: 'source-sans-pro', sans-serif;
          margin-bottom: 32px;
          &:hover {
            cursor: pointer;
          }
        }

        .slide-disclaimer {
          color: ${Colors.primary.black.standard};
          margin-bottom: 34px;
          padding: 0 21px;
          text-align: left;
          font-size: 12px;
          line-height: 1.5;

          @media only screen and (min-width: 768px) {
            margin-bottom: 50px;
            padding: 0 36px;
          }

          @media only screen and (min-width: 1025px) {
            margin-bottom: 32px;
            padding: 0 24px;
          }
        }

        .slide-img {
          margin: 0 auto;
          display: block;
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .slide-logo {
          margin: 0 auto;
        }

        .smart-comfort-logo {
          max-width: 136px;
        }

        .ecobee-logo {
          max-width: 119px;
        }

        .thermoply-logo {
          max-width: 147px;
        }

        .lux-windows-logo {
          max-width: 68px;
        }

        .rheem-logo {
          max-width: 64px;
        }

        .lasalle-bristol-logo {
          max-width: 171px;
        }

        .johns-manville-logo {
          max-width: 203px;
        }

        .knauf-logo {
          max-width: 150px;
          margin-top: 18px;
        }

        .frigidaire-logo {
          max-width: 193px;
        }

        .samsung-logo {
          max-width: 193px;
          margin-bottom: -17px;
        }
      }
    }
  }

  .energy-smart-disclaimer {
    flex-basis: 100%;
    font-family: 'source-sans-pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: #000000;
    margin-left: 14%;
    position: relative;
    top: 20px;
  }
`;

export default EnergySmartZeroSliderStyles;
