import React from 'react';
import PropTypes from 'prop-types';

const LeafIconConfirmation = ({ color, width, height }) => (
  <svg
    id="leafIcon"
    width={width}
    height={height}
    viewBox="0 0 155 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.4881 0L0 45.0986V135.296L77.4881 180.418L155 135.296V45.0986L77.4881 0Z"
      fill={color}
    />
    <path
      d="M106.821 63.4941L87.9265 82.54H109.018C109.783 76.2315 109.066 69.7063 106.821 63.4941Z"
      fill="white"
    />
    <path
      d="M108.326 86.5618H83.938L69.9644 100.648H102.259C105.173 96.3375 107.204 91.546 108.302 86.5618"
      fill="white"
    />
    <path
      d="M80.8562 59.1843C75.8878 60.3401 71.1583 62.4349 66.8826 65.3965V98.0466L80.8562 83.9608V59.1843Z"
      fill="white"
    />
    <path
      d="M84.8457 79.9399L104.003 60.6292C97.7445 58.3417 91.1995 57.6194 84.8457 58.438V79.9158V79.9399Z"
      fill="white"
    />
    <path
      d="M65.9748 104.668L59.1672 111.531C58.3789 112.325 57.1368 112.325 56.3485 111.531C55.5603 110.736 55.5603 109.484 56.3485 108.689L62.8935 102.092V68.575C62.2963 69.1047 61.723 69.6344 61.1497 70.2123C49.8753 81.5772 46.2684 98.3838 51.6907 113.385L46.2445 118.874C45.4563 119.669 45.4563 120.945 46.2445 121.716C46.6267 122.101 47.1522 122.318 47.6538 122.318C48.1554 122.318 48.6809 122.125 49.0631 121.716L54.5093 116.226C59.0716 117.887 63.7773 118.754 68.4829 118.754C79.1124 118.754 89.527 114.564 97.3379 106.691C97.9828 106.041 98.6039 105.367 99.2011 104.668H65.9748Z"
      fill="white"
    />
  </svg>
);

LeafIconConfirmation.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LeafIconConfirmation.defaultProps = {
  color: '#00B0AD',
  width: '155',
  height: '181',
};

export default LeafIconConfirmation;
