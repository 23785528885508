import React from 'react';
import { StyledHouseSmartPromoGrid } from './HouseSmartPromoGrid.styled';
import HouseSmartPromoGridContent from './HouseSmartPromoGridContent';

const HouseSmartPromoGrid = (disclaimerRef) => {
  return (
    <StyledHouseSmartPromoGrid id="HouseSmart">
      {HouseSmartPromoGridContent && (
        <div className="grid-container">
          <h2 className="main-headline">
            {HouseSmartPromoGridContent.mainHeadline}
          </h2>
          <div className="housesmart-grid">
            {HouseSmartPromoGridContent.columns.map((item, i) => (
              <div className="column" key={i}>
                <div className="image">
                  <img src={item.image} alt={item.title + ' image'} />
                </div>
                <div className="content-wrap">
                  <h4 className="title">{item.title}</h4>
                  <div className="line"></div>
                  <div className="content">
                    <p>{item.content}</p>
                    {item.disclaimer && (
                      <p className="disclaimer">{item.disclaimer}</p>
                    )}
                  </div>
                  {item.tradeMarkDisclaimer && (
                    <p className="trademark-disclaimer-desktop">
                      {item.tradeMarkDisclaimer}
                    </p>
                  )}
                </div>
                <div className={item.color + '-box' + ' box'}>
                  <div className="box-content">
                    <div className="line"></div>
                    <p className="box-quote">{item.boxQuote}</p>
                    <p className="box-attribution">{item.boxAttribution}</p>
                    <img
                      className="icon"
                      src={item.boxIcon}
                      alt={item.title + ' icon'}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="disclaimer" id="PromoDisclaimer">
            <p className="trademark-disclaimer-mobile">
              Trademarks of companies other than Clayton are the property of
              those other companies. ENERGY STAR® and the ENERGY STAR® mark are
              registered trademarks owned by the U.S. Environmental Protection
              Agency.
            </p>
          </div>
        </div>
      )}
    </StyledHouseSmartPromoGrid>
  );
};

export default HouseSmartPromoGrid;
