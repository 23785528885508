import LocationLineSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/location-line.svg';
import PhoneSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/phone.svg';
import FacebookIcon from 'pubweb-smokey/dist/images/svg/iconography-social/facebook.svg';
import InstagramIcon from 'pubweb-smokey/dist/images/svg/iconography-social/instagram.svg';
import PinterestIcon from 'pubweb-smokey/dist/images/svg/iconography-social/pinterest.svg';
import YoutubeIcon from 'pubweb-smokey/dist/images/svg/iconography-social/youtube.svg';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import txLogo from '../../../images/txLogo.png';
import { fireSegmentEvent } from '../../../utils';
import { shouldFeatureWelcomeHomeGallery } from '../../../utils/utils';
import Accordion from '../Accordion/Accordion';
import {
  formatDealerName,
  formatPhoneNumberFromNumber,
} from '../SmokeyTransitional/utils/formatters';
import { FooterStyles } from './Footer.styled';

const getServiceableAreasString = (dealer) => {
  try {
    if (dealer && dealer.servicableAreas.length === 0) {
      return `${formatDealerName(
        dealer.dealerName
      )} offers land and home opportunities.`;
    }
    const areas = dealer.servicableAreas.map((area) => area.city);
    return `${formatDealerName(
      dealer.dealerName
    )} offers land and home opportunities in the following areas:
    ${areas.slice(0, -1).join(', ') + ', and ' + areas.slice(-1)}.`;
  } catch (e) {
    console.error(e);
  }
};

const getFacebookUrl = (dealer) => {
  if (dealer?.uris?.filter(({ urlTypeId }) => urlTypeId === 2)[0]) {
    return dealer.uris.filter(({ urlTypeId }) => urlTypeId === 2)[0]
      .description;
  }

  if (dealer?.brandAcronym === 'CLA') {
    return 'https://www.facebook.com/ClaytonHomes/';
  }

  return null;
};

const Footer = ({ dealer, homes }) => {
  // use type and state in order to make it a router link and not a normal a tag link

  const homesOnLand = homes?.filter(
    (home) => home.isLand == true || home.isOnLand == true
  );

  const homesOnSale = homes?.filter(
    (home) => home.isSaleHome == true || home.isDefaultSaleHome == true
  );

  const landLink =
    homesOnLand && homesOnLand.length
      ? {
          path: '/land',
          text: 'Land',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const saleHomeLink =
    dealer?.websiteTemplateDescription !== 'BuilderModel' &&
    homesOnSale &&
    homesOnSale.length
      ? {
          path: '/sale-homes',
          text: 'Sale Homes',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const houseSmartLink =
    dealer?.websiteTemplateDescription !== 'BuilderModel' &&
    dealer?.websiteTemplateDescription !== 'NoSpecialOffersNoEnergySmart'
      ? {
          path: '/housesmart',
          text: 'Our Construction',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const aboutUsLink =
    dealer?.websiteTemplateDescription !== 'BuilderModel'
      ? {
          path: '/about-us',
          text: 'About Us',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const interactFacilityTourLink =
    dealer?.websiteTemplateDescription !== 'BuilderModel'
      ? {
          path: '/Interactive-Facility-Tour',
          text: 'Interactive Facility Tour',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const crossModLink =
    dealer?.websiteTemplateDescription !== 'BuilderModel'
      ? {
          path: '/CrossMod',
          text: 'CrossMod',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const siteFeedbackLink =
    dealer?.websiteTemplateDescription !== 'BuilderModel'
      ? {
          path: '/site-feedback',
          text: 'Site Feedback',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const testimonialLink =
    dealer?.websiteTemplateDescription !== 'BuilderModel'
      ? {
          path: '/give-a-testimonial',
          text: 'Give A Testimonial',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const specialOffersLink =
    dealer?.websiteTemplateDescription !== 'NoSpecialOffers' &&
    dealer?.websiteTemplateDescription !== 'BuilderModel' &&
    dealer?.websiteTemplateDescription !== 'NoSpecialOffersNoEnergySmart'
      ? {
          path: '/special-offers/ebuilt-sales-event',
          text: 'Special Offers',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const energySmartLink =
    dealer?.websiteTemplateDescription !== 'NoSpecialOffersNoEnergySmart' &&
    dealer?.websiteTemplateDescription !== 'BuilderModel'
      ? {
          path: '/energysmart',
          text: 'EnergySmart Zero',
          type: 'link',
          state: { dealer, homes },
        }
      : null;

  const navLinks = [
    {
      path: '/homes',
      text: 'View Homes',
      type: 'link',
      state: { dealer, homes },
    },
    landLink,
    saleHomeLink,
    specialOffersLink,
  ];

  let navLinks2 = [
    houseSmartLink,
    energySmartLink,
    ...(shouldFeatureWelcomeHomeGallery(dealer)
      ? [
          {
            path: '/our-homeowners',
            text: 'Our Homeowners',
            type: 'link',
            state: { dealer, homes },
          },
        ]
      : []),
    interactFacilityTourLink,
    crossModLink,
  ].filter((link) => link !== null);

  if (shouldFeatureWelcomeHomeGallery(dealer)) {
    navLinks2;
  }
  const navLinks3 = [
    aboutUsLink,
    {
      path: '/contact-us',
      text: 'Contact Us',
      type: 'link',
      state: { dealer, homes },
    },
    siteFeedbackLink,
    testimonialLink,
    {
      path: '/faq',
      text: 'FAQs',
      type: 'link',
      state: { dealer, homes },
    },
    {
      path: '/news-events',
      text: 'News & Events',
      type: 'link',
      state: { dealer, homes },
    },
  ];

  const facebookUrl = dealer ? getFacebookUrl(dealer) : null;

  const formatRBINumber = (number) => {
    let dbNumber = number.replace(/\D/g, '').replace(/^0+/, '');
    let formattedNumber;

    if (dbNumber.length > 5) {
      formattedNumber = dbNumber.slice(number.length - 5);
    } else {
      formattedNumber = dbNumber;
    }

    return formattedNumber;
  };

  const { pathname } = useLocation();

  return (
    <FooterStyles>
      <div className="footer-container">
        <div className="top-content">
          <Accordion
            accordionTitle="hours"
            weekdayHours={dealer?.weekdayHours}
            fridayHours={dealer?.fridayHours}
            saturdayHours={dealer?.saturdayHours}
            sundayHours={dealer?.sundayHours}
          />
          <Accordion accordionTitle="shop" navLinks={navLinks} />
          {navLinks2 && navLinks2.length > 0 && (
            <Accordion accordionTitle="discover" navLinks={navLinks2} />
          )}
          <Accordion accordionTitle="connect" navLinks={navLinks3} />
          <div className="store-info">
            <Link
              className="footer-logo"
              to={{ pathname: '/', state: { dealer, homes } }}
            >
              <img
                src={dealer?.whiteLogoReference}
                alt="white logo"
                loading="lazy"
              />
            </Link>
            <div className="hc-address">
              <div>
                <ReactSVG className="location-icon" src={LocationLineSvg} />
              </div>
              <a
                target="_blank"
                rel="noopener"
                className="footer-link footer-address"
                href={
                  'http://maps.google.com/?q=' +
                  dealer?.address1 +
                  '+' +
                  dealer?.city +
                  '+' +
                  dealer?.stateProvince +
                  '+' +
                  dealer?.postalCode
                }
                onClick={() => {
                  fireSegmentEvent('External Link Clicked', {
                    type: 'button',
                    isNav: false,
                    eventContext: 'footer_address',
                  });
                }}
              >
                {`${dealer?.address1}, ${dealer?.city}, ${dealer?.stateProvince} ${dealer?.postalCode}`}
              </a>
            </div>
            <div className="hc-phone">
              <div>
                <ReactSVG
                  className="phone-icon"
                  src={PhoneSvg}
                  alt="phone-icon"
                />
              </div>
              <a
                className="footer-link footer-phone"
                href={`tel:${dealer?.phoneNumber}`}
                onClick={() => {
                  fireSegmentEvent('Phone Number Link Clicked', {
                    link: dealer?.phoneNumber
                      ? formatPhoneNumberFromNumber(dealer.phoneNumber)
                      : '',
                    eventContext: 'footer_phone',
                  });
                }}
              >
                {formatPhoneNumberFromNumber(dealer?.phoneNumber)}
              </a>
            </div>
            <Link
              to={{ pathname: '/contact-us', state: { dealer, homes } }}
              className="contact-btn"
              onClick={() => {
                if (
                  pathname === '/contact-us' &&
                  typeof window !== 'undefined'
                ) {
                  window?.scrollTo({ top: 0 });
                }
              }}
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="bottom-content">
          <div className="social-wrap">
            <div className="footer-social-icons">
              {facebookUrl && (
                <a
                  href={facebookUrl}
                  target="_blank"
                  rel="noopener"
                  aria-label="facebook logo"
                >
                  <ReactSVG
                    className="facebook-icon"
                    src={FacebookIcon}
                    alt="facebook logo"
                  />
                </a>
              )}

              {dealer?.brandAcronym === 'CLA' && (
                <>
                  <a
                    href="https://www.instagram.com/clayton/"
                    target="_blank"
                    rel="noopener"
                    aria-label="instagram logo"
                  >
                    <ReactSVG
                      className="instagram-icon"
                      src={InstagramIcon}
                      alt="instagram logo"
                    />
                  </a>
                  <a
                    href="https://www.pinterest.com/claytonhomes"
                    target="_blank"
                    rel="noopener"
                    aria-label="pinterest logo"
                  >
                    <ReactSVG
                      className="pinterest-icon"
                      src={PinterestIcon}
                      alt="pinterest logo"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/user/claytonhomes"
                    target="_blank"
                    rel="noopener"
                    aria-label="youtube logo"
                  >
                    <ReactSVG
                      className="youtube-icon"
                      src={YoutubeIcon}
                      alt="youtube logo"
                    />
                  </a>
                </>
              )}
            </div>
            {dealer?.stateProvince == 'TX' && (
              <div className="tx-logo-wrap">
                <a
                  className="tx-logo"
                  target="_blank"
                  href="http://www.tdhca.state.tx.us/mh/"
                >
                  <img src={txLogo} alt="Texas Department of Housing Logo" />
                </a>
                {dealer?.licenseRegistrationNumber && (
                  <p>
                    RBI# MHDRET000
                    {formatRBINumber(dealer.licenseRegistrationNumber)}
                  </p>
                )}
              </div>
            )}
          </div>
          {dealer && dealer.servicableAreas && (
            <p className="location-blurb">
              {getServiceableAreasString(dealer)}
            </p>
          )}
        </div>
      </div>

      <div className="legal-privacy">
        &copy; 1998&ndash;{new Date().getFullYear()} Clayton. All rights
        reserved.
        <span className="mobile-wrap">
          <a href="/legal">Legal</a>
          <a href="https://www.claytonhomes.com/privacy/">Privacy</a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              typeof window !== 'undefined' &&
                window?.Osano?.cm &&
                window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
            }}
          >
            Do Not Sell or Share My Personal Information
          </a>
        </span>
      </div>
    </FooterStyles>
  );
};

export default Footer;
