import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, Link } from 'react-router-dom';
import App from '../../App';
import useHomes from '../../hooks/useHomes';
import EnergySmartSlide from '../../images/energy-smart-tile-update.jpg';
import HomePlannerImg from '../../images/portal-cta-background.svg';
import eBuiltPromoTile from '../../images/promo-images/ebuilt-promo-tile.jpg';
import ArrowRight from '../../images/svg/icons/ArrowRight';
import ArrowLeft from '../../images/svg/icons/ArrowLeft';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import {
  getUniqueHomeId,
  parseInitialPropsContext,
  fireSegmentEvent,
} from '../../utils';
import { findMetaDescription } from '../../utils/utils';
import HomeListFilter from '../Shared/HomeListFilter/HomeListFilter';
import HomeListSort from '../Shared/HomeListSort/HomeListSort';
import PromoSlide from '../Shared/PromoSlide/PromoSlide';
import RecentlyViewedHomes from '../Shared/RecentlyViewedHomes/RecentlyViewedHomes';
import { formatDealerName } from '../Shared/SmokeyTransitional/utils/formatters';
import {
  checkOverflow,
  scrollToNextItem,
  scrollToPrevItem,
} from '../Shared/SmokeyTransitional/utils/utils';
import Toggle from '../Shared/Toggle/Toggle';
import HomeListStyles from './HomeList.styled';
import HomeListingStructuredData from './HomeListStructuredData';
import HomeTile from './HomeTile/HomeTile';
import useWindowSize from '../../hooks/useWindowSize';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Colors from '../../colors';
import CuratedCollectionsLinks from '../CuratedCollections/CuratedCollectionsLinks';

const HomeList = ({
  dealer,
  homes,
  initialHomesOffset,
  initialFilters,
  homesInStock,
  homesOnSale,
  moveInReadyHomes,
  budgetHomes,
  focusHomeIndex,
  isLoading,
}) => {
  const location = useLocation();
  const isLandPage =
    location.pathname == '/land' || location.pathname == '/Land';
  const noSpecialOffers =
    dealer?.websiteTemplateDescription === 'NoSpecialOffers' ? true : false;
  const builderModel =
    dealer?.websiteTemplateDescription === 'BuilderModel' ? true : false;
  const params = new URLSearchParams(useLocation().search);
  const [showFloorPlans, setShowFloorPlans] = useState(
    params.get('showFloorPlans')
  );

  const [currentSlide, setCurrentSlide] = useState(0);
  const [curatedCollectionLength, setCuratedCollectionLength] = useState(0);

  const [width, height] = useWindowSize();
  const isMobile = width <= 700;
  // const showAvailabilityFilter = dealer.isInventoryOnlyForWebsiteHomes
  //   ? false
  //   : true;

  const nextClick = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prevClick = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  useEffect(() => {
    let collectionLength = 0;
    if (homesOnSale.length > 0) {
      collectionLength = collectionLength + 1;
      setCuratedCollectionLength(collectionLength);
    }
    if (homesInStock.length > 0) {
      collectionLength = collectionLength + 1;
      setCuratedCollectionLength(collectionLength);
    }
    if (moveInReadyHomes.length > 0) {
      collectionLength = collectionLength + 1;
      setCuratedCollectionLength(collectionLength);
    }
    if (budgetHomes.length > 0) {
      collectionLength = collectionLength + 1;
      setCuratedCollectionLength(collectionLength);
    }
  }, [curatedCollectionLength]);

  useEffect(() => {
    if (params.get('showFloorPlans') == 'true') {
      setShowFloorPlans(true);
    } else {
      setShowFloorPlans(false);
    }
  }, []);

  if (params.get('homesOffset')) {
    initialHomesOffset = parseInt(params.get('homesOffset'));
  } else {
    // TODO Remove this else case when promo drops

    switch (dealer?.websiteTemplateDescription) {
      case 'BuilderModel':
        initialHomesOffset = 12;
        break;
      case 'NoSpecialOffersNoEnergySmart':
        initialHomesOffset = 12;
        break;
      case 'NoSpecialOffers':
        initialHomesOffset = 11;
        break;
      case 'Default':
        initialHomesOffset = 10;
        break;
    }
  }

  homes.forEach((e, i) => {
    e.originalIndex = i;
  });

  const {
    visibleHomes,
    filters,
    updateFilters,
    clearFilter,
    isFilterActive,
    updateSortOrder,
    loadMoreHomes,
    totalHomes,
    homesOffset,
    isLoading: areHomesLoading,
    isError: isHomesError,
    error: homesError,
  } = useHomes(
    homes,
    initialHomesOffset,
    initialFilters,
    params,
    noSpecialOffers,
    builderModel
  );

  const homeTileRefs = useRef(new Array());

  const handleFloorPlanToggle = () => {
    setShowFloorPlans(!showFloorPlans) +
      fireSegmentEvent('Facility Model List Filtered', {
        list: 'Homes List',
        filters: [
          {
            type: 'show floor plans',
            value: !showFloorPlans == true ? 'true' : 'false',
          },
        ],
        eventContext: 'available_homes_show_floor_plans',
      });
  };

  const overflowcheck = checkOverflow();

  const history = useHistory();

  const [currentOrder, setCurrentOrder] = useState(params.get('sortOrder'));

  const getNewParams = (filters, homesOffset, currentOrder, showFloorPlans) => {
    let newParams = new URLSearchParams();
    let newOrderParam = '';

    if (currentOrder == 'Price (Low to High)') {
      newOrderParam = 'PriceLowHigh';
    } else if (currentOrder == 'Price (High to Low)') {
      newOrderParam = 'PriceHighLow';
    } else if (currentOrder == 'Square Feet') {
      newOrderParam = 'SquareFeet';
    } else {
      newOrderParam = currentOrder;
    }

    newParams.append('sortOrder', newOrderParam);
    newParams.append('homesOffset', homesOffset);
    newParams.append('showFloorPlans', showFloorPlans);
    newParams.append('isOnLand', !!filters['isOnLand']);
    newParams.append('isInStock', !!filters['isInStock']);
    if (filters['text']) {
      newParams.append('name', filters['text']);
    }

    if (filters['features'].selected.length > 0) {
      newParams.append('features', filters['features'].selected.join(','));
    }

    newParams.append('minBeds', filters['beds'].min);
    newParams.append('maxBeds', filters['beds'].max);
    newParams.append('minBaths', filters['baths'].min);
    newParams.append('maxBaths', filters['baths'].max);
    newParams.append('minPrice', filters['priceSort'].min);
    newParams.append('maxPrice', filters['priceSort'].max);
    newParams.append('minSquareFeet', filters['squareFeet'].min);
    newParams.append('maxSquareFeet', filters['squareFeet'].max);
    newParams.append('onlyModels', !!filters['onlyModels']);
    newParams.append('isMultiSection', !!filters['isMultiSection']);
    newParams.append('isCrossMod', !!filters['isCrossMod']);
    newParams.append('isSingleSection', !!filters['isSingleSection']);
    newParams.append('minAcres', filters['acres'].min);
    newParams.append('maxAcres', filters['acres'].max);

    return newParams.toString();
  };

  const historySearch = getNewParams(
    filters,
    homesOffset,
    currentOrder,
    showFloorPlans
  );

  // be sure to save homes, dealer etc to history so back button loads home list
  // with its current state
  useEffect(() => {
    history.replace({
      pathname: location.pathname,
      search: historySearch,
      state: {
        dealer,
        homes,
        filters,
        focusHomeIndex,
      },
    });
  }, [filters, currentOrder, showFloorPlans]);

  const handleHomeClick = (index, home) => {
    fireSegmentEvent('Facility Model Clicked', {
      baths: home?.baths,
      beds: home?.beds,
      modelName: home?.name || home?.modelName || '',
      basePrice: parseInt(home?.priceSort) || 0,
      length: 0,
      width: 0,
      modelSquareFeet: home?.squareFeet,
      modelNumber: home?.modelNumber || '',
      serialNumber: home?.serialNumber || '',
      eventContext: 'available_homes_home_card',
    }),
      // when a home card is clicked, we remember the index so we
      // can scroll to it when/uf user hits back from the details page
      // (see getInitialProps)
      history.replace({
        pathname: location.pathname,
        search: historySearch,
        state: {
          dealer,
          homes,
          homesOffset,
          filters,
          focusHomeIndex: index,
        },
      });
  };

  useEffect(() => {
    // if focusHomeIndex is set, scroll to the home
    // note: this was needed b/c for some reason the react/router code
    // that does this for us keeps creeping the scroll position down the page
    // on every trip to the details page and back
    if (focusHomeIndex > -1 && visibleHomes && visibleHomes.length > 1) {
      window?.setTimeout(() => {
        homeTileRefs?.current[focusHomeIndex].scrollIntoView();
      }, 10);
    }
  }, [focusHomeIndex]);

  const customPageMetaDescription = findMetaDescription(
    dealer?.websiteMetaDescriptions,
    'homes'
  );
  const filtersArray = [
    'isLand',
    'isOnLand',
    'isInStock',
    'priceSort',
    'beds',
    'baths',
    'features',
    'text',
    'squareFeet',
    'isMultiSection',
    'isSingleSection',
    'isCrossMod',
    'acres',
  ];
  const hasActiveFilters = filtersArray.some((filter) =>
    isFilterActive(filter)
  );

  const curatedCollectionLengthClass = () => {
    let collectionLength = 0;
    if (homesOnSale.length > 0) {
      collectionLength = collectionLength + 1;
    }
    if (homesInStock.length > 0) {
      collectionLength = collectionLength + 1;
    }
    if (moveInReadyHomes.length > 0) {
      collectionLength = collectionLength + 1;
    }
    if (budgetHomes.length > 0) {
      collectionLength = collectionLength + 1;
    }
    if (collectionLength === 4) {
      return 'four';
    } else if (collectionLength === 3) {
      return 'three';
    } else if (collectionLength === 2) {
      return 'two';
    } else if (collectionLength === 1) {
      return 'one';
    } else {
      return '';
    }
  };

  const listName = (list) => {
    if (list === 'homesOnSale') {
      return homesOnSale;
    }
    if (list === 'homesInStock') {
      return homesInStock;
    }
    if (list === 'moveInReadyHomes') {
      return moveInReadyHomes;
    }
    if (list === 'budgetHomes') {
      return budgetHomes;
    }
  };

  const newList = CuratedCollectionsLinks.filter(
    (link) => listName(link.listName).length > 0
  );

  const arrowStyles = {};

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <HomeListStyles>
        <Helmet>
          <title>
            {isLandPage
              ? 'Land Available Near Me'
              : 'Mobile Homes For Sale Near Me'}
            {' | '}
            {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            property="og:description"
            content={
              isLandPage
                ? 'Looking for land to place the home of your dreams? Our Find Land tool can help you get started today!'
                : customPageMetaDescription?.metaDescription
            }
          />
        </Helmet>
        <HomeListingStructuredData dealer={dealer} homes={homes} />
        <div className="top-area-container">
          {overflowcheck && (
            <>
              <span
                className="btn-prev hidden-arrow"
                onClick={() => {
                  scrollToPrevItem();
                }}
              >
                <ArrowRight />
              </span>
              <span
                className="btn-next"
                onClick={() => {
                  scrollToNextItem();
                }}
              >
                <ArrowRight />
              </span>
            </>
          )}

          <div className="top-area-centered-content">
            <HomeListFilter
              filters={filters}
              updateFilters={updateFilters}
              clearFilter={clearFilter}
              isFilterActive={isFilterActive}
              isLandPage={isLandPage}
              homes={homes}
              hasActiveFilters={hasActiveFilters}
            />

            <HomeListSort
              className="sort-wrap"
              updateSortOrder={updateSortOrder}
              setCurrentOrder={setCurrentOrder}
            ></HomeListSort>
          </div>
        </div>
        {(homesOnSale.length > 0 ||
          homesInStock.length > 0 ||
          moveInReadyHomes.length > 0 ||
          budgetHomes.length > 0) && (
          <div className="curated-collection-container">
            <h1 className="find-home-headline">Find A Home</h1>
            <p className="list-headline">Curated Collections</p>

            {isMobile ? (
              <div
                className={`curated-collections ${curatedCollectionLengthClass()}`}
              >
                <button
                  type="button"
                  onClick={prevClick}
                  className={`energy-smart-btn-prev slider-btns slider-btn-prev ${
                    currentSlide === 0 ? 'disabled' : ''
                  }`}
                >
                  <ArrowLeft color={Colors.primary.white.standard} />
                </button>

                <button
                  type="button"
                  onClick={nextClick}
                  className={`energy-smart-btn-next slider-btns slider-btn-next ${
                    curatedCollectionLength.length === currentSlide + 1
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <ArrowRight color={Colors.primary.white.standard} />
                </button>
                <Carousel
                  showArrows={false}
                  autoPlay={false}
                  showIndicators={false}
                  showStatus={false}
                  showThumbs={false}
                  swipeable={true}
                  emulateTouch={true}
                  infiniteLoop={true}
                  isFocusWithinTheCarousel={false}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                >
                  {newList.map((slide, i) => {
                    return (
                      <div className="slide" key={i}>
                        <Link
                          className="collection"
                          to={{
                            pathname: slide.pathName,
                            state: { dealer, homes },
                          }}
                          onClick={() => {
                            fireSegmentEvent('CTA Clicked', {
                              isNav: false,
                              type: 'button',
                              text: slide.segmentText,
                              eventContext: slide.segmentEventContext,
                            });
                          }}
                        >
                          <img src={slide.icon} className="icon" />
                          {slide.linkText}
                          <ArrowRight />
                        </Link>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            ) : (
              <div
                className={`curated-collections ${curatedCollectionLengthClass()}`}
              >
                {newList.map((slide, i) => {
                  return (
                    <Link
                      key={i}
                      className="collection"
                      to={{
                        pathname: slide.pathName,
                        state: { dealer, homes },
                      }}
                      onClick={() => {
                        fireSegmentEvent('CTA Clicked', {
                          isNav: false,
                          type: 'button',
                          text: slide.segmentText,
                          eventContext: slide.segmentEventContext,
                        });
                      }}
                    >
                      <img src={slide.icon} className="icon" />
                      {slide.linkText}
                      <ArrowRight />
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div className="centered-page-content-container">
          {hasActiveFilters && (
            <span
              className="clear-filters-mobile"
              onClick={() => {
                clearFilter('isLand');
                clearFilter('isOnLand');
                clearFilter('isInStock');
                clearFilter('priceSort');
                clearFilter('beds');
                clearFilter('baths');
                clearFilter('features');
                clearFilter('text');
                clearFilter('squareFeet');
                clearFilter('isMultiSection');
                clearFilter('isSingleSection');
                clearFilter('isCrossMod');
                clearFilter('acres');
              }}
            >
              Clear Filters
            </span>
          )}
          <div className="top-tools-container">
            <p className="counter">{`${totalHomes} results`}</p>
            <div className="toggle-container ga-toggle-floor-plans">
              {!isLandPage && (
                <Toggle
                  key="showFloorplans"
                  label="SHOW FLOOR PLANS"
                  mobileLabel="FLOOR PLANS"
                  onToggle={handleFloorPlanToggle}
                  inputProps={{
                    id: 'showFloorplans',
                    checked: showFloorPlans ? true : false,
                  }}
                />
              )}
            </div>

            <HomeListSort
              className="sort-wrap-mobile"
              updateSortOrder={updateSortOrder}
              setCurrentOrder={setCurrentOrder}
            ></HomeListSort>
          </div>

          <div className="list-container">
            <p className="counter-mobile">{`${totalHomes} Results`}</p>
            {visibleHomes &&
              visibleHomes.map((home, i) => (
                <React.Fragment
                  key={
                    home.serialNumber ? home.serialNumber : home?.modelNumber
                  }
                >
                  <div
                    className="hidden"
                    ref={(element) => homeTileRefs.current.push(element)}
                  ></div>
                  <HomeTile
                    key={getUniqueHomeId(home)}
                    index={i}
                    retailHomeObject={home}
                    showFloorPlans={showFloorPlans}
                    dealer={dealer}
                    cardLinkState={{
                      dealer: dealer,
                      homes: homes,
                      homesOffset: homesOffset,
                      filter: filters,
                      prevPath: location.pathname,
                    }}
                    handleClick={(e) => {
                      handleHomeClick(i, home);
                    }}
                  />

                  {i === 1 &&
                    dealer?.websiteTemplateDescription !== 'BuilderModel' &&
                    dealer?.websiteTemplateDescription !== 'NoSpecialOffers' &&
                    dealer?.websiteTemplateDescription !==
                      'NoSpecialOffersNoEnergySmart' && ( // TODO uncomment this when promo drops
                      <PromoSlide
                        promoSlide={eBuiltPromoTile}
                        promoText="View this limited time offer on select homes."
                        promoUrl="/special-offers/ebuilt-sales-event"
                        dealer={dealer}
                        homes={homes}
                        gaClass="ga-card-seasonal-campaign"
                        retailEventLabel="available_homes_gallery_card_1"
                        reactLink="true"
                        customClass="energy-smart-promo-link"
                      />
                    )}
                  {i ===
                    (dealer?.websiteTemplateDescription !== 'NoSpecialOffers'
                      ? 7
                      : 8) &&
                    dealer?.websiteTemplateDescription !== 'BuilderModel' &&
                    dealer?.websiteTemplateDescription !==
                      'NoSpecialOffersNoEnergySmart' && (
                      <PromoSlide
                        promoSlide={EnergySmartSlide}
                        promoText="Save big on energy bills and keep money in your wallet."
                        promoUrl="/energysmart"
                        dealer={dealer}
                        homes={homes}
                        gaClass="ga-card-learn"
                        retailEventLabel="available_homes_gallery_card_2"
                        reactLink="true"
                      />
                    )}
                  {i === 17 &&
                    dealer?.websiteTemplateDescription !== 'BuilderModel' && (
                      <PromoSlide
                        portalLink
                        promoSlide={HomePlannerImg}
                        promoText="MyHome Planner: A guide for your home buying journey."
                        promoUrl="/portal/planner"
                        dealer={dealer}
                        homes={homes}
                        gaClass="ga-card-portal"
                        retailEventLabel="available_homes_gallery_card_3"
                      />
                    )}
                </React.Fragment>
              ))}
          </div>

          {visibleHomes && visibleHomes.length === 0 && (
            <div className="no-results">
              <h3>
                {isMobile
                  ? 'No homes match your filter selections. Remove some of your filters to continue your search.'
                  : 'No homes match your filter selections.'}
              </h3>
              <p className="no-results-text">
                Remove some of your filters to continue your search.
              </p>
              <p
                className="clear-filters"
                onClick={() => {
                  clearFilter('isLand');
                  clearFilter('isOnLand');
                  clearFilter('isInStock');
                  clearFilter('priceSort');
                  clearFilter('beds');
                  clearFilter('baths');
                  clearFilter('features');
                  clearFilter('text');
                  clearFilter('squareFeet');
                  clearFilter('isMultiSection');
                  clearFilter('isSingleSection');
                  clearFilter('isCrossMod');
                  clearFilter('acres');
                }}
              >
                Clear Filters
              </p>
            </div>
          )}

          {visibleHomes.length > 0 && (
            <div className="button-container">
              <Button
                className="center-horizontal button"
                text="LOAD MORE HOMES"
                onClick={() => {
                  loadMoreHomes();
                }}
                automationId="homeListBtn"
                disabled={loadMoreHomes === null}
              />
            </div>
          )}

          <p className="pricing-dislcaimer">
            *Advertised starting sales prices are for the home only. Delivery
            and installation costs are not included unless otherwise stated.
            Starting prices shown on this website are subject to change, see
            your local Home Center for current and specific home and pricing
            information. Sales price does not include other costs such as taxes,
            title fees, insurance premiums, filing or recording fees, land or
            improvements to the land, optional home features, optional delivery
            or installation services, wheels and axles, community or homeowner
            association fees, or any other items not shown on your Sales
            Agreement, Retailer Closing Agreement and related documents (your
            SA/RCA). If you purchase a home, your SA/RCA will show the details
            of your purchase. Homes available at the advertised sales price will
            vary by retailer and state. Artists’ renderings of homes are only
            representations and actual home may vary. Floor plan dimensions are
            approximate and based on length and width measurements from exterior
            wall to exterior wall. We invest in continuous product and process
            improvement. All home series, floor plans, specifications,
            dimensions, features, materials, and availability shown on this
            website are subject to change. Images may show options not included
            in base price.
          </p>
        </div>
        <RecentlyViewedHomes
          dealer={dealer}
          homes={homes}
          homesOffset={homesOffset}
          filters={filters}
        />
      </HomeListStyles>
    </App>
  );
};

HomeList.getInitialProps = async (ctx) => {
  // pass any saved state back into component

  // if focusHomeIndex was saved, be sure to pass it back into home list component
  const focusHomeIndex = ctx?.location?.state?.hasOwnProperty('focusHomeIndex')
    ? ctx?.location?.state?.focusHomeIndex
    : -1;

  const {
    homesInStock,
    homesOnSale,
    moveInReadyHomes,
    budgetHomes,
    dealer,
    homes,
  } = await parseInitialPropsContext(ctx, dealerService, homesService).catch(
    (e) => {
      console.error(e);
      return { redirectTo: '/error' };
    }
  );

  // gather default state and combine
  return {
    homesInStock,
    homesOnSale,
    moveInReadyHomes,
    budgetHomes,
    focusHomeIndex,
    dealer,
    homes,
  };
};

export default HomeList;
