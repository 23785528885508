import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const TextWithVideoStyles = styled.section`
  position: relative;
  padding: 50px 0px;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    display: flex;
    @media only screen and (max-width: 970px) {
      flex-wrap: wrap;
    }
  }
  .triangle-box {
    position: absolute;
    left: 0;
    height: 468px;
    width: 44vw;
    min-width: 532px;
    background-color: #ffc50a;
    @media only screen and (max-width: 642px) {
      height: 79vw;
      width: 91vw;
      min-width: 0px;
    }
    img {
      position: absolute;
      left: 0;
      bottom: 0;
      @media only screen and (max-width: 642px) {
        max-width: 192px;
        width: 20vw;
      }
    }
  }
  .wistia_embed {
    height: 360px;
    width: 100%;
    position: relative;
    width: 640px;
  }
  .video-wrap {
    position: relative;
    cursor: pointer;
    .video-graphic {
      max-width: 566px;
      width: 90vw;
      @media only screen and (max-width: 642px) {
        width: 99vw;
        max-width: 100%;
      }
    }
    .video-box {
      position: absolute;
      height: 56vw;
      max-height: 343px;
      width: 86vw;
      max-width: 496px;
      top: 0;
      bottom: 0;
      right: -120px;
      left: 0;
      margin: auto;
      background-size: contain;
      background-position: center;
      box-shadow: 0px 4px 11px rgba(37, 41, 45, 0.0467657),
        0px 26px 30px rgba(0, 0, 0, 0.15);
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 642px) {
        right: -6vw;
      }
      #playBtn {
        margin-bottom: -50px;
        position: absolute;
        @media only screen and (max-width: 642px) {
          width: 34vw;
          margin-bottom: -12vw;
        }
      }
      span,
      img {
        height: 100%;
        width: 100%;
      }
      img {
        object-fit: contain;
      }
    }
  }
  .content-wrap {
    position: relative;
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: 1100px) {
      padding-left: 120px;
    }
    @media only screen and (max-width: 970px) {
      width: 100%;
      padding: 60px 20px;
      align-items: center;
    }
    .content {
      max-width: 500px;
      .large-headline {
        margin-bottom: 20px;
      }
      .feature-list {
        position: relative;
        column-count: 2;
        column-gap: 0;
        list-style: none;
        margin-top: 20px;
        @media only screen and (min-width: 970px) and (max-width: 1100px) {
          column-count: 1;
        }
        @media only screen and (max-width: 642px) {
          column-count: 1;
        }
        li {
          display: flex;
          margin-bottom: 5px;
          padding-right: 10px;

          p {
            padding-top: 10px;
            margin-left: 10px;
            line-height: 1.3;
          }
        }
      }
    }
  }
  .video-embed-wrap {
    width: 95vw;
    max-width: 640px;
  }
`;

export default TextWithVideoStyles;
