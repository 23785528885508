import React from 'react';
import PropTypes from 'prop-types';
import CrossModThreeUpStyles from './CrossModThreeUp.styled';
import Markdown from '../Markdown/Markdown';

export const CrossModThreeUp = ({ items }) => {
  return (
    <CrossModThreeUpStyles>
      <div className="container">
        {items && (
          <div className="three-up-items">
            {items.map((item, i) => {
              return (
                <div key={i} className="item">
                  {item.image && (
                    <img
                      className="logo"
                      src={item.image.source}
                      alt={item.image.alt}
                      loading="lazy"
                    />
                  )}
                  {item.content && <Markdown markdown={item.content} />}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </CrossModThreeUpStyles>
  );
};

CrossModThreeUp.props = {
  items: PropTypes.array,
};

export default CrossModThreeUp;
