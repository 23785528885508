import styled from 'styled-components';
import Colors from '../../../colors';

const HomeListFilterStyles = styled.div`
  display: flex;
  align-items: start;
  .remove-for-mobile {
    @media screen and (max-width: 568px) {
      display: none !important;
    }
  }

  .radio-button span {
    font-family: source-sans-pro, sans-serif;
    font-weight: normal;
  }
  .mobile-spacer {
    display: none;
  }

  .filter-container {
    position: relative;
    @media screen and (max-width: 767px) {
      width: 100vw;
    }
  }

  .scroller {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 0px 50px 0 150px;
    justify-content: flex-start !important;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    .selectable-card {
      &:first-child {
        margin-left: 30px;
      }
    }
    .mobile-spacer {
      display: flex;
      height: 1px;
      flex-shrink: 0;
      width: 50px;
    }
    .selectable-card {
      scroll-snap-align: start;
      /* margin: 0px 20px; */
      &:first-child {
        margin-left: 30px !important;
      }
    }
  }

  .scroller::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .filter-controls {
    display: flex;
    width: 100%;
    margin-top: 4px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
      margin-left: 12px;
    }

    .filter-by-label {
      font-size: 12px;
      font-weight: 700;
      line-height: 14.52px;
      text-align: left;
      align-content: center;
      margin-right: 12px;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .selectable-card:first-child {
      @media screen and (max-width: 1024px) {
        margin-left: 0px !important;
      }
    }

    .selectable-card {
      height: 30px;
      padding: 2px 6px !important;
      margin-right: 12px !important;
      border: 1px solid #585c5f !important;
      border-radius: 4px;
      align-content: center;
      letter-spacing: unset !important;
      text-transform: none;
      display: flex;
      outline: none !important;
      align-items: center;
      background-color: white;

      .card-text {
        color: #4e5255 !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: unset !important;
      }

      .card-icon {
        margin-left: 6px;
        margin-bottom: 4px;
      }

      &.focused {
        background-color: ${Colors.primary.claytonBlue.standard} !important;
        .card-text {
          color: white !important;
        }
      }

      &.selected {
        border: 1px solid ${Colors.primary.claytonBlue.standard} !important;
        background-color: ${Colors.primary.claytonBlue.standard};
        .card-text {
          color: #fff !important;
        }
      }

      .card-text {
        font-family: source-sans-pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #4e5255 !important;
        padding: 0 !important;
        outline: none !important;
      }

      @media screen and (max-width: 842px) {
        margin-right: 10px !important;
      }
    }
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .flyout {
    @media screen and (max-width: 768px) {
      position: absolute;
      left: calc(50% - 136px) !important;
      margin-top: 12px;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 10px;
    }
  }
  .clear-filters {
    display: none;
    @media screen and (min-width: 1024px) {
      display: flex;
      color: #0773df;
      font-family: source-sans-pro;
      font-size: 12px;
      text-decoration: underline;
      align-self: center;
      cursor: pointer;
    }
  }
`;

export default HomeListFilterStyles;
