import styled from 'styled-components';

const TestimonialsThankYouStyles = styled.div`
  text-align: center;
  padding: 47px 36px 41px;
  height: auto;
  width: 100%;
  @media screen and (min-width: 768px) {
    padding: 47px 58px 67px;
  }
  @media only screen and (min-width: 1025px) {
    padding: 32px 87px 58px;
  }

  .h3 {
    margin-bottom: 71px;
  }
  p {
    text-align: center;
    margin-bottom: auto;
    font-size: 18px;
    font-weight: 400;
    color: #000;
  }

  svg {
    max-width: 200px;
    margin: 15px auto 30px;
    display: block;
    max-height: 160px;
  }

  button {
    width: 100%;
    margin-top: 85px;
  }
`;

export default TestimonialsThankYouStyles;
