import styled from 'styled-components';
import Colors from '../../../colors';

const StyledHomeCenterInfo = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .store-info {
    margin: 1.5rem 0;
    padding: 0;

    @media (min-width: 1025px) {
      margin: 3rem 0;
      padding: 0 2rem;
      border-left: 1px solid #d8d8d8;
    }

    .address {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      box-sizing: border-box;

      @media only screen and (min-width: 768px) {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
      }

      .section-header {
        font-family: 'acumin-pro', sans-serif;
        font-size: 12px;
        line-height: 1.75;
        letter-spacing: 2.8px;
        text-align: left;
        color: ${Colors.primary.claytonBlue.standard};
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      p {
        font-family: 'source-sans-pro', sans-serif;
        font-size: 19px;
        letter-spacing: -0.3px;
        color: #6d6e71;
        line-height: 1.7;
        margin-top: 0;
        margin-bottom: 1rem;
      }

      .phone-number {
        font-weight: 700;
        margin-bottom: 2rem;
        color: #6d6e71;
        text-decoration: none;
        display: block;
        font-family: 'source-sans-pro', sans-serif;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .hours {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      box-sizing: border-box;

      @media only screen and (min-width: 768px) {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }

      .section-header {
        font-family: 'acumin-pro', sans-serif;
        font-size: 12px;
        line-height: 1.75;
        letter-spacing: 2.8px;
        text-align: left;
        color: ${Colors.primary.claytonBlue.standard};
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      p {
        font-family: 'source-sans-pro', sans-serif;
        font-size: 19px;
        letter-spacing: -0.3px;
        color: #6d6e71;
        line-height: 1.7;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
`;

export default StyledHomeCenterInfo;
