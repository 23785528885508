import styled from 'styled-components';
import Colors from '../../colors';

const EnergySmartStyles = styled.div`
  position: relative;
  /* .temp-gray-bground {
    background: ${Colors.secondary.grey6.standard};
    min-height: 400px;
    position: relative;

    @media only screen and (min-width: 1025px) {
      margin-top: -75px;
    }
  } */
`;

export default EnergySmartStyles;
