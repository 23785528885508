import React from 'react';
import PropTypes from 'prop-types';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import TestimonialsThankYouStyles from './TestimonialsThankYou.styled';
import ContactHouse from './ContactHouse';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';

const TestimonialsThankYou = ({ closeFunction, dealer }) => {
  return (
    <TestimonialsThankYouStyles>
      <div className="h3">{formatDealerName(dealer?.dealerName)}</div>
      <ContactHouse />
      <p>Thank you for reaching out.</p>
      <Button
        text="Okay"
        onClick={closeFunction}
        automationId="giveTestimonialTYBtn"
      />
      <div className="disclaimer" />
    </TestimonialsThankYouStyles>
  );
};

TestimonialsThankYou.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TestimonialsThankYou;
