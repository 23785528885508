import styled from 'styled-components';
import Colors from '../../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const StyledFooter = styled.footer`
  background-color: ${Colors.accent.claytonBlue2.standard};
  color: ${Colors.primary.white.standard};
  text-align: center;
  padding: 1.5rem;
  font-size: 14px;
  flex: none;

  .footer-wrap {
    max-width: 700px;
    text-align: center;
    margin: 0 auto;

    &.tx {
      max-width: 800px;
    }

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      display: flex;
      justify-content: center;
    }
  }

  .footer-copyright {
    display: block;
    margin-right: 1rem;
    color: ${Colors.primary.white.standard};
    font-family: 'source-sans-pro', sans-serif;
    line-height: normal;

    @media (min-width: 768px) {
      display: inline;
      margin-right: 0;
    }
  }

  a {
    color: ${Colors.primary.white.standard};
    margin: 0 0.5rem;
    text-decoration: none;
    font-family: 'source-sans-pro', sans-serif;
    font-size: 13px;

    &:hover {
      cursor: pointer !important;
    }

    @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
      font-size: 14px;
    }
  }

  .tx-logo-wrap {
    position: relative;
    display: block;
    @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
      margin-top: 10px;
    }
    p {
      font: 400 12px / 17px 'Source Sans Pro', sans-serif;
      color: ${Colors.accent.grey2.standard};
    }
    .tx-logo {
      img {
        margin-left: -4px;
        margin-bottom: 4px;
      }
      @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        margin-bottom: 10px;
        img {
          align-self: center;
          margin-bottom: 4px;
        }
      }
    }
  }
`;

export default StyledFooter;
