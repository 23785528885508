import React from 'react';
import StyledStoreSection from './StoreSection.styled';
import CityBlurb from './CItyBlurb';
import HomeCenterInfo from './HomeCenterInfo';

const StoreSection = ({
  dealer,
  toTitleCase,
  formatPhone,
  formatStoreName,
}) => {
  const formattedDealerName = dealer.dealerName
    ? formatStoreName(dealer.dealerName)
    : 'Clayton Homes';

  return (
    <StyledStoreSection>
      <div className="row">
        <CityBlurb
          dealerName={formattedDealerName}
          dealerDescription={dealer.contactUsPageData.dealerDescription}
        />
        <HomeCenterInfo
          dealerName={formattedDealerName}
          dealer={dealer}
          toTitleCase={toTitleCase}
          formatPhone={formatPhone}
        />
      </div>
    </StyledStoreSection>
  );
};

export default StoreSection;
