import React from 'react';
import { Helmet } from 'react-helmet';
import { getNewsAndEvents } from '../../Api.js';
import App from '../../App';
import gallery from '../../content/sampleImageGallery';
import threeUpItems from '../../content/threeUpData';
import useSalesAgents from '../../hooks/useSalesAgents';
import homeHeaderPreHeadlineImage from '../../images/eszLogo.svg';
import homeHeaderImage from '../../images/homeHeaderImage.jpg';
import InventoryClearanceLogo from '../../images/promo-images/inventory-clearance-event-logo.svg';
import homeHeaderImageWebp from '../../images/webp/homeHeaderImage.webp';
import homeHeaderImageWebpMobile from '../../images/webp/homeHeaderImageMobile.webp';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import {
  findFeaturedHomes,
  findMetaDescription,
  findTempoHomes,
  getFOBShortName,
  shouldFeatureWelcomeHomeGallery,
} from '../../utils/utils';
import DualImageCTA from '../Shared/DualImageCTA/DualImageCTA';
import FeaturedHomes from '../Shared/FeaturedHomes/FeaturedHomes';
import HeaderWithHero from '../Shared/HeaderWithHero/HeaderWithHero';
import ImageGalleryWithContent from '../Shared/ImageGalleryWithContent/ImageGalleryWithContent';
import LargeCTA from '../Shared/LargeCTA/LargeCTA';
import MeetOurTeam from '../Shared/MeetOurTeam/MeetOurTeam';
import NewsEventsShared from '../Shared/NewsEventsShared/NewsEventsShared';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import { formatDealerName } from '../Shared/SmokeyTransitional/utils/formatters';
import TextContent from '../Shared/TextContent/TextContent';
import ThreeUp from '../Shared/ThreeUp/ThreeUp';
import XLCTA from '../Shared/XLCTA/XLCTA';
import HomeStyles from './Home.styled';
import StructuredData from './StructuredData';
import BuilderModelForm from '../Shared/BuilderModelForm/BuilderModelForm.js';
import useWindowSize from '../../hooks/useWindowSize';

const Home = ({ dealer, homes, homesOnSale, isLoading, newsAndEvents }) => {
  const [width, height] = useWindowSize();
  // only being used by LargeCTA, updated for ebuilt summer promo styling
  const isMobile = width <= 1199;

  const dealerFriendlyName =
    formatDealerName(dealer?.dealerName) || 'Clayton Homes';

  const { salesAgents } = useSalesAgents(dealer.dealerId);

  const highestPricedHome = homes.reduce(
    (a, h) => (a = a > h.priceSort ? a : h.priceSort),
    0
  );

  const dealerDescription = dealer?.contactUsPageData?.dealerDescription
    .replace('<p>', '')
    .replace('</p>', '');

  const minMaxBeds = homes.reduce((acc, val) => {
    acc[0] = acc[0] === undefined || val.beds < acc[0] ? val.beds : acc[0];
    acc[1] = acc[1] === undefined || val.beds > acc[1] ? val.beds : acc[1];
    return acc;
  }, []);

  const minMaxBaths = homes.reduce((acc, val) => {
    acc[0] = acc[0] === undefined || val.baths < acc[0] ? val.baths : acc[0];
    acc[1] = acc[1] === undefined || val.baths > acc[1] ? val.baths : acc[1];
    return acc;
  }, []);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  const shuffledGallery = React.useMemo(() => {
    return shuffleArray(gallery);
  }, []);

  let featuredHomes;

  if (homes.length) {
    featuredHomes = findFeaturedHomes(homes);
  }

  const landRecordCheck = homes?.filter(
    (home) => home.isLand == true || home.isOnLand == true
  );

  const tempoHomes = findTempoHomes(homes);

  const customPageMetaDescription = findMetaDescription(
    dealer?.websiteMetaDescriptions,
    'index'
  );

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <HomeStyles>
        <Helmet>
          <title>
            Modular, Manufactured, Mobile Homes For Sale | {dealerFriendlyName}
          </title>
          <meta
            name="description"
            content={customPageMetaDescription?.metaDescription}
          />
        </Helmet>
        <StructuredData
          dealer={dealer}
          salesAgents={salesAgents}
          homes={homes}
        />
        <HeaderWithHero
          smallHeadline={'Our most energy efficient home now available!'}
          largeHeadline={dealerFriendlyName}
          hideDisclaimer={true}
          preHeadlineImage={{
            source: homeHeaderPreHeadlineImage,
            alt: 'energy smart zero logo',
          }}
          image={{
            source: homeHeaderImage,
            srcSet: [
              { source: homeHeaderImageWebp, minWidth: '769px' },
              { source: homeHeaderImageWebpMobile },
            ],
            alt: 'home hero image',
          }}
          showHomesForm={true}
          highestPricedHome={highestPricedHome}
          highestBathCount={minMaxBaths[1]}
          highestBedCount={minMaxBeds[1]}
          lowestBathCount={minMaxBaths[0] ? minMaxBaths[0] : 0}
          lowestBedCount={minMaxBeds[0] ? minMaxBeds[0] : 0}
          showLogo={
            dealer?.websiteTemplateDescription !== 'BuilderModel' &&
            dealer?.websiteTemplateDescription !==
              'NoSpecialOffersNoEnergySmart'
              ? true
              : false
          }
          isBuilderModel={
            dealer?.websiteTemplateDescription === 'BuilderModel' ? true : false
          }
          isNoSpecialOffersNoEnergySmart={
            dealer?.websiteTemplateDescription ===
            'NoSpecialOffersNoEnergySmart'
              ? true
              : false
          }
        />
        {(featuredHomes || tempoHomes) && (
          <FeaturedHomes
            featuredHomes={tempoHomes ? tempoHomes : featuredHomes}
            dealer={dealer}
            homes={homes}
            buttonText={'View All Available Homes'}
            buttonURL={'/homes'}
            secondButtonText={
              homesOnSale && homesOnSale.length > 0 ? 'View Sale Homes' : null
            }
            secondButtonURL={
              homesOnSale && homesOnSale.length > 0 ? '/sale-homes' : null
            }
            gaClass={'ga-homepage-view-all-homes'}
            gaSecondClass={'ga-homepage-view-featured-sale-homes'}
            gaClassTwo={'ga-contact-home-item'}
            retailEventLabel={'home_featured_homes_view_all'}
            retailEventLabelTwo={'home_featured_homes_view_sale'}
          />
        )}

        <DualImageCTA
          key="dual-image-cta"
          slideOneSmallText="OPENING DOORS SALES EVENT"
          slideOneLargeText={
            <>
              Select new homes discounted
              <br />
              for a limited time.
            </>
          }
          slideOneButtonText="View Offer"
          slideOneButtonURL="/special-offers/ebuilt-sales-event"
          slideOneButtonClass="cta-btn promo-btn"
          slideOneBackgroundImage={{
            source: require('../../images/promo-images/ebuilt-dual-cta-background.jpg'),
            srcSet: [
              {
                source: require('../../images/webp/ebuilt-dual-cta-background.webp'),
              },
            ],
            alt: 'Inventory Clearance Event Kitchen',
          }}
          slideTwoSmallText={'MYHOME'}
          slideTwoLargeText={'Start your home buying journey with our planner.'}
          slideTwoButtonText={'View Planner'}
          slideTwoButtonURL={'/portal/planner'}
          slideTwoBackgroundImage={{
            source: require('../../images/portal-cta-background.jpg'),
            srcSet: [
              {
                source: require('../../images/webp/portal-cta-background.webp'),
              },
            ],
            alt: 'Planner Background Image',
          }}
          backgroundColor="green"
          gaClass="ga-homepage-dual-cta-one"
          gaSecondClass="ga-homepage-dual-cta-two"
          dealer={dealer}
          homes={homes}
          portalLink={true}
        />
        <TextContent
          smallHeadline={'Our mission'}
          largeHeadline={'Opening doors to a better life.'}
          customHeadlineWidth={'560px'}
          backgroundColor={'#F3F5F7'}
          largePadding={true}
          content={
            dealerDescription
              ? dealerDescription
              : `At ${dealerFriendlyName} we are ready to help you find your dream home. We are here to help answer all your questions, from floor plans to design features. Check out our fully furnished new home models and see all the options available to create the perfect home. With many manufactured, mobile and modular home floor plans to choose from, we are certain we can help you find the home you’ve been looking for.`
          }
        />
        {dealer?.websiteTemplateDescription == 'BuilderModel' ? (
          <BuilderModelForm dealer={dealer} />
        ) : (
          <>
            {homesOnSale && homesOnSale.length > 0 && (
              <SaleHomeSlider
                largeHeadline="Sale Homes"
                saleHomes={homesOnSale}
                buttonText={'View All Sale Homes'}
                buttonURL={'/sale-homes'}
                gaClass={'ga-homepage-view-sale-homes'}
                retailEventLabel={'home_sale_homes_view_all'}
                dealer={dealer}
              />
            )}
            {dealer?.websiteTemplateDescription !==
              'NoSpecialOffersNoEnergySmart' && (
              <ThreeUp
                threeUpHeadline="Built to Last a Lifetime. Or Two."
                items={threeUpItems}
                dealer={dealer}
                homes={homes}
                disclaimer="* With proper home maintenance."
                secondDisclaimer="** EnergySmart Zero™ homes are built with energy efficient features and made to DOE Zero Energy Ready Home™ program specifications."
              />
            )}
            {dealer?.isCorporateAdCampaignParticipant &&
              dealer?.websiteTemplateDescription !== 'NoSpecialOffers' &&
              dealer?.websiteTemplateDescription !==
                'NoSpecialOffersNoEnergySmart' && (
                <LargeCTA
                  key="large-cta"
                  buttonText="View Offer"
                  buttonURL="/special-offers/ebuilt-sales-event"
                  backgroundColor="ebuilt-summer-promo"
                  linkState={(dealer, homes)}
                  smallText={isMobile && 'OPENING DOORS SALES EVENT'}
                  largeText={
                    isMobile ? (
                      <p className="h2 large-text">
                        New homes discounted for a limited time.
                      </p>
                    ) : (
                      <>
                        <p
                          className="h2 large-text"
                          style={{ maxWidth: '750px' }}
                        >
                          New homes starting <br />
                          <b className="bold"> under $150,000</b>
                          <span>*.</span>
                        </p>
                      </>
                    )
                  }
                  disclaimerText={
                    !isMobile && (
                      <>
                        *Sales price does not include required taxes, delivery &
                        installation. For complete pricing details{' '}
                        <a href="/special-offers/ebuilt-sales-event#PromoDisclaimer">
                          click here.
                        </a>
                      </>
                    )
                  }
                  backgroundImage={
                    !isMobile && {
                      source: require('../../images/promo-images/ebuilt-large-cta.jpg'),
                      srcSet: [
                        {
                          source: require('../../images/webp/ebuilt-large-cta.webp'),
                        },
                      ],
                      alt: 'Opening Doors Sales Event Banner Image',
                    }
                  }
                  backgroundPosition={'top left'}
                  gaClass="ga-sale-homes-seasonal-campaign"
                  dealer={dealer}
                  homes={homes}
                  logoText={''}
                  retailEventLabel={'home_banner_campaign'}
                />
              )}
            {dealer?.websiteTemplateDescription !==
              'NoSpecialOffersNoEnergySmart' && (
              <section className="spacer"></section>
            )}
          </>
        )}

        <ImageGalleryWithContent
          smallHeadline={'Opening Doors to a Better Life'}
          largeHeadline={'Welcome Home'}
          content={
            'As you get the keys to your new home, we want to be the first to celebrate with you. From our family to yours, welcome home! '
          }
          buttonText={
            shouldFeatureWelcomeHomeGallery(dealer)
              ? 'Meet Our Homeowners'
              : dealer?.websiteTemplateDescription !== 'BuilderModel'
              ? 'Learn More About ' + getFOBShortName(dealer.brandAcronym)
              : 'Contact Us'
          }
          buttonURL={
            shouldFeatureWelcomeHomeGallery(dealer)
              ? '/our-homeowners'
              : dealer?.websiteTemplateDescription !== 'BuilderModel'
              ? '/about-us'
              : '/contact-us'
          }
          images={
            dealer?.welcomeHomeImageReferences
              ? dealer.welcomeHomeImageReferences
              : shuffledGallery
          }
          dealer={dealer}
          homes={homes}
          gaClass="ga-home-welcome-home-cta-click"
        />
        {newsAndEvents && newsAndEvents.length > 0 ? (
          <NewsEventsShared
            dealer={dealer}
            homes={homes}
            newsAndEvents={newsAndEvents}
            linkText="View All"
            headerlabel={'Keep up with ' + getFOBShortName(dealer.brandAcronym)}
            headline="News &amp; Events"
          />
        ) : (
          <section className="spacer"></section>
        )}

        <MeetOurTeam dealer={dealer} homes={homes} gaClass="ga-meet-our-team" />
        <XLCTA
          largeText="Find Your Perfect Home"
          dealer={dealer}
          homes={homes}
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              gaClass:
                'ga-homepage-get-more-information ga-find-perfect-home-request-info-click',
              openForm: 'requestInfo',
              retailEventLabel: 'footer_dual_cta_request',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              gaClass:
                'ga-homepage-schedule-a-visit ga-find-perfect-home-cta-schedule-appt-click',
              openForm: 'scheduleVisit',
              retailEventLabel: 'footer_dual_cta_schedule',
            },
          ]}
        />
      </HomeStyles>
    </App>
  );
};

Home.getInitialProps = async (ctx) => {
  let newsAndEvents;

  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  try {
    const result = await getNewsAndEvents(dealer.dealerId);
    newsAndEvents = result.filter((res) => res.isPlaceholder == false);
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, homesOnSale, newsAndEvents };
};

export default Home;
