import styled from 'styled-components';
import Colors from '../../../colors';

const StyledPricingLegal = styled.section`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00324f;
  color: ${Colors.primary.white.standard};
  font-family: 'acumin-pro', sans-serif;
  transition: all 0.5s ease-in-out;
  height: 0;
  opacity: 0;

  &.open {
    opacity: 1;
    min-height: 189px;
    transition: all 0.5s ease-in-out;
  }

  .content-wrapper {
    position: relative;
    padding: 1.25rem 1.5rem 2rem;
    max-height: 100vh;

    .close-icon {
      position: absolute;
      top: 1.25rem;
      right: 1rem;
      cursor: pointer;
      background: none;
      border: none;
    }

    .description {
      margin-top: 2rem;
      font-size: 0.875rem;
    }
  }
`;

export default StyledPricingLegal;
