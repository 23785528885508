import React from 'react';
import PropTypes from 'prop-types';
import { aboutUsPageStructuredData } from '../../utils/structuredData';

const AboutUsStructuredData = ({ dealer, salesAgents, description }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          aboutUsPageStructuredData(dealer, salesAgents, description)
        ),
      }}
    ></script>
  );
};

export default AboutUsStructuredData;

AboutUsStructuredData.propTypes = {
  dealer: PropTypes.shape({
    dealerName: PropTypes.string,
    address1: PropTypes.string,
    city: PropTypes.string,
    stateProvince: PropTypes.string,
    postalCode: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  salesAgents: PropTypes.arrayOf(
    PropTypes.shape({
      avatarImageReference: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      salesAgentTitle: PropTypes.string,
    })
  ),
  description: PropTypes.string,
};
