import styled from 'styled-components';
import Colors from '../../colors';
import PromoBannerClayton from '../../images/promo-images/fall-promo/energy-smart-zero-promo-banner.jpg';
import PromoBannerClaytonTablet from '../../images/promo-images/fall-promo/energy-smart-zero-promo-banner-tablet.jpg';
import PromoBannerClaytonMobile from '../../images/promo-images/fall-promo/energy-smart-zero-promo-banner-mobile.jpg';

export const StyledBanner = styled.div`
  width: 100%;

  @media only screen and (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
  }

  .banner {
    background-color: #fefbfc;
    position: relative;
    background: url(${PromoBannerClaytonMobile}) no-repeat top center;
    background-size: cover !important;
    height: 360px;
    padding: 19px 0 0;
    box-sizing: border-box;

    @media only screen and (min-width: 400px) {
      height: 400px;
    }

    @media only screen and (min-width: 500px) {
      height: 600px;
    }

    @media only screen and (min-width: 768px) {
      height: auto;
      background: url(${PromoBannerClaytonTablet}) no-repeat top center;
      /* height: 234px; */
      padding: 20px 0 40px;
    }

    @media only screen and (min-width: 1025px) {
      height: auto;
      width: 50%;
      background: url(${PromoBannerClayton}) no-repeat top center;
      min-height: 800px;
      padding: 33px 0 0;
    }

    @media only screen and (min-width: 1600px) {
      min-height: calc(100vh - 100px);
      background-position: center;
      overflow: hidden;
    }

    .banner-headline {
      font-size: 23px;
      line-height: 27px;
      font-weight: 400;
      letter-spacing: -0.35px;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      width: 90%;
      margin: 14px 0 0;
      padding-left: 29px;

      @media only screen and (min-width: 768px) {
        width: 69%;
        max-width: 496px;
        font-size: 32px;
        line-height: 1.19;
        margin-top: 2px;
        padding-left: 37px;
      }

      @media only screen and (min-width: 1025px) {
        margin-top: 26px;
        padding-left: 55px;
      }

      .bold {
        font-weight: 700;
      }
    }

    .banner-disclaimer {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      width: 40%;
      margin-left: 6%;

      @media only screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
        max-width: 244px;
        width: 32%;
        margin-left: 128px;
      }

      @media only screen and (min-width: 1025px) {
        text-align: center;
        margin: 0 auto;
        width: 72%;
        max-width: 520px;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    img {
      width: 638px;
      max-width: 90%;
      height: auto;
    }

    .offer-extended-logo-mobile {
      max-width: 158px;
      position: absolute;
      right: 20px;
    }
  }
`;
