import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation } from 'react-router-dom';
import App from '../../App';
import Colors from '../../colors';
import VideoBackgroundImage from '../../images/promo-images/energy-smart-zero-video-banner.jpg';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { formatDealerBrand, parseInitialPropsContext } from '../../utils';
import EnergySmartZeroCostSavings from '../Shared/EnergySmartZeroCostSavings/EnergySmartZeroCostSavings';
import EnergySmartZeroSlider from '../Shared/EnergySmartZeroSlider/EnergySmartZeroSlider';
import { smoothScroll } from '../Shared/SmokeyTransitional/utils/utils';
import TextContent from '../Shared/TextContent/TextContent';
import Video from '../Shared/Video/Video';
import XLCTA from '../Shared/XLCTA/XLCTA';
import Banner from './Banner';
import FallPromoStyles from './FallPromo.styled';
import PromoDisclaimer from './PromoDisclaimer';

const promoIsInactive = false;

const FallPromo = ({ dealer, homes, homesOnSale, isLoading }) => {
  return <Redirect to="/special-offers/ebuilt-sales-event" />;
  const [showTy, setShowTy] = useState(false);
  const disclaimerRef = useRef(null);

  const showThankYou = () => {
    setShowTy(true);
  };

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  const handleScroll = () => {
    smoothScroll('#PromoDisclaimer', -90);
  };

  const { pathname, hash, key } = useLocation();

  useLayoutEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 800);
    }
  }, []);

  if (dealer?.websiteTemplateDescription === 'BuilderModel') {
    return <Redirect to="/" />;
  }

  if (
    promoIsInactive ||
    dealer?.websiteTemplateDescription === 'NoSpecialOffers'
  ) {
    return <Redirect to="/special-offers" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <FallPromoStyles>
        <Helmet>
          <title>
            EnergySmart Zero - Energy Efficient Manufactured, Mobile, Modular
            Homes | {dealer ? formatDealerName(dealer?.dealerName) : ''}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <Banner
          bannerHeadline={
            <>
              Comfort and energy savings,{' '}
              <span className="bold">redefined.</span>
            </>
          }
          formHeadline={
            'Get $2,000* towards utility bills when you buy a new EnergySmart Zero™ home!'
          }
          formTermsText={
            <>
              *Restrictions apply.{' '}
              <span onClick={handleScroll}>Click here</span> for complete terms
              and conditions.
            </>
          }
          formText={
            'Complete this form to receive information about our new energy efficient homes and other special offers.'
          }
          formDisclaimer={
            'By submitting my information, I agree that Clayton Homes and its service providers may send me marketing text messages and telephone calls using automated dialing technology to the mobile number I provided in this form. Consent not required for purchase. See our Terms & Conditions, which includes opt-out instructions.'
          }
          dealer={dealer}
          homes={homes}
          showThankYouCallBack={showThankYou}
          showTy={showTy}
          isClayton={isClayton}
        />
        <TextContent
          smallHeadline={`EnergySmart Zero™`}
          largeHeadline={'The home of tomorrow is here. Today.'}
          content={
            <>
              Every home we build now comes equipped with EnergySmart Zero™
              making them up to 50% more efficient than the average home
              <span className="superscript">1</span>, and saving you tens of
              thousands of dollars over time
              <span className="superscript">2</span>. Enjoy a whole new level of
              home performance with industry leading energy savings, comfort,
              health and durability.
            </>
          }
          links={[
            {
              linkContent: (
                <>
                  <span className="bold">1</span>{' '}
                  <a
                    href="https://www.energy.gov/sites/default/files/2022-11/ZERH%20Name%20and%20Logo%20Use%20Guidelines_0.pdf"
                    target="_blank"
                  >
                    ZERH Logo Use Guidelines (energy.gov)
                  </a>
                </>
              ),
            },
            {
              linkContent: (
                <>
                  <span className="bold">2</span>{' '}
                  <a
                    href="https://stage.energy.gov/eere/buildings/articles/ultra-efficient-fact-sheet"
                    target="_blank"
                  >
                    ZERH Factsheets.pdf (energy.gov)
                  </a>
                </>
              ),
            },
          ]}
          backgroundColor={Colors.primary.white.standard}
          mobileBackgroundColor={Colors.secondary.grey6.standard}
          aboveVideoContent={false}
        />
        {/* {homesOnSale && homesOnSale.length > 0 && (
          <SaleHomeSlider
            largeHeadline="Explore Sale Homes"
            saleHomes={homesOnSale}
            buttonText={'View All Sale Homes'}
            buttonURL={'/sale-homes'}
            gaClass={'ga-seasonal-campaign-view-sale-homes'}
            dealer={dealer}
          />
        )} */}
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          retailEventLabel={'campaign_video'}
          image={{ source: VideoBackgroundImage }}
          videoID={isClayton() ? 'impt0zivef' : '1g6vi7x1lf'}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          noSaleHomes={true}
        />
        <EnergySmartZeroSlider dealer={dealer} isPromo={true} />
        <EnergySmartZeroCostSavings
          dealer={dealer}
          isPromoBackgroundImg={true}
          isPromo={true}
        />
        <div id="PromoDisclaimer" ref={disclaimerRef}>
          <PromoDisclaimer
            otherCompaniesDisclaimer={
              <>
                Trademarks of companies other than {formatDealerBrand(dealer)}{' '}
                are the property of those other companies.
              </>
            }
            mainDisclaimer={
              <>
                * CONDITIONS FOR THE ENERGY SMART ZERO™ EXTENDED SALES EVENT
                OFFER (the “Offer”): Offer only available at participating
                Clayton Family of Brands retailers with purchase of a new
                EnergySmart Zero™ home at the posted retail sales price with a
                Sales Agreement signed 1/1/2024 through 3/31/2024. Eligible
                buyers will receive a $2,000 VISA® prepaid debit card for the
                home purchase. Offer will be applied first to the amount of the
                negotiated or lender required reduction of the home sales price
                if applicable. The $2,000 may not be applied towards the buyer's
                down payment if the buyer obtains financing for the home
                purchase. Offer will be in the form of a VISA® prepaid debit
                card issued in the name of the primary buyer by Pathward®
                pursuant to a license from VISA U.S.A. and is usually delivered
                within eight weeks after the Seller has been paid in full for
                all goods and services purchased by the buyer. VISA debit card
                is subject to all terms and conditions sets forth on the card
                (including the expiration date) and in the issuer’s Cardholder
                Agreement. Offer not available with USDA/FHA/VA financing and
                cannot be transferred or combined with other offers. Void where
                prohibited by law.
              </>
            }
          />
        </div>

        {dealer && (
          <XLCTA
            phoneNumber={dealer.phoneNumber}
            customCallText={'Find Your Perfect Home'}
            gaPhoneClass={'ga-seasonal-campaign-phone-number'}
            buttons={[
              {
                buttonText: 'Get More Information',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-get-more-information',
                openForm: 'requestInfo',
                retailEventLabel: 'footer_dual_cta_request',
              },
              {
                buttonText: 'Schedule a Visit',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-schedule-a-visit',
                openForm: 'scheduleVisit',
                retailEventLabel: 'footer_dual_cta_schedule',
              },
            ]}
          />
        )}
      </FallPromoStyles>
    </App>
  );
};

FallPromo.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService);
};

export default FallPromo;
