import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import App from '../../../App';
import AffordableHomesStyles from './AffordableHomes.styled';
import Header from './Header';
import Banner from './Banner';
import StoreSection from './StoreSection';
import WhyBuy from './WhyBuy';
import LargestHomeBuilder from './LargestHomeBuilder';
import HomesContainer from './HomesContainer';
import AboutLocation from './AboutLocation';
import Footer from './Footer';
import PricingLegal from './PricingLegal';
import * as dealerService from '../../../services/dealerService';
import * as homesService from '../../../services/homesService';

import {
  formatPhoneNumberFromNumber,
  formatDealerName,
} from '../../Shared/SmokeyTransitional/utils/formatters';
import { parseInitialPropsContext } from '../../../utils';

const AffordableHomes = ({ dealer, homes, isLoading }) => {
  const [home, setHome] = useState(null);
  const [showPricing, setShowPricing] = useState(false);

  const formRef = useRef(null);

  const handleHomeClick = (home) => {
    if (formRef.current) {
      formRef.current.focus();
    }

    if (dataLayer) {
      dataLayer.push({ event: 'homeButton', homeModelId: home.modelId });
    }
    setHome(home);
  };

  const handlePricingClick = (value) => {
    setShowPricing(value);
  };

  const toTitleCase = (wordOrWords) => {
    if (!wordOrWords || typeof wordOrWords !== 'string') {
      return '';
    }

    return wordOrWords
      .split(' ')
      .map((word) => word.toLowerCase())
      .map((word) => {
        if (word === 'ii') {
          return word.toUpperCase();
        }
        if (word.length > 1) {
          return word[0].toUpperCase() + word.substr(1);
        }
        if (word) {
          // some cases cause word to be undefined here
          return word[0].toUpperCase();
        }
      })
      .join(' ');
  };

  return (
    <App
      dealer={dealer}
      homes={homes}
      isLoading={isLoading}
      hideFooter={true}
      hideHeader={true}
    >
      <AffordableHomesStyles>
        <Helmet>
          <title>
            Affordable Homes | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Header dealer={dealer} formatPhone={formatPhoneNumberFromNumber} />
        <Banner
          brandAcronym={dealer.brandAcronym}
          firstInputRef={formRef}
          home={home}
          dealerNumber={dealer.dealerNumber}
          dealer={dealer}
          homes={homes}
        />
        <StoreSection
          dealer={dealer}
          toTitleCase={toTitleCase}
          formatPhone={formatPhoneNumberFromNumber}
          formatStoreName={formatDealerName}
        />
        <WhyBuy />
        <LargestHomeBuilder />
        <HomesContainer
          homes={dealer.contactUsPageData.homeListings}
          toTitleCase={toTitleCase}
          handleHomeClick={handleHomeClick}
          handlePricingClick={() => handlePricingClick(true)}
        />
        <AboutLocation
          cityDescription={dealer.contactUsPageData.cityDescription}
          city={dealer.city}
          toTitleCase={toTitleCase}
        />
        <Footer dealer={dealer} legalUrl={'/legal'} />
        <PricingLegal
          showPricing={showPricing}
          onClose={() => handlePricingClick(false)}
        />
      </AffordableHomesStyles>
    </App>
  );
};

AffordableHomes.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService);
};

export default AffordableHomes;
