import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import Colors from '../../colors';
import EnergySmartBannerImg from '../../images/energy-smart-banner-background.png';
import EnergySmartTopImage from '../../images/energy-smart-video.jpg';
import EnergySmartZeroLogo from '../../images/energysmart-zero-logo.png';
import ArrowDown from '../../images/svg/icons/ArrowDown';
import PlayBtn from '../../images/svg/icons/PlayBtn';
import EnergySmartTopImageWebp from '../../images/webp/energy-smart-video.webp';
import { fireSegmentEvent } from '../../utils';
import OptimizedImage from '../Shared/OptimizedImage/OptimizedImage';
import Modal from '../Shared/SmokeyTransitional/components/Modal/Modal';
import WistiaVideoEmbed from '../Shared/SmokeyTransitional/components/WistiaVideoEmbed/WistiaVideoEmbed';
import { smoothScroll } from '../Shared/SmokeyTransitional/utils/utils';
import StyledBanner from './EnergySmartBanner.styled';

const EnergySmartBanner = ({
  energySmartText,
  energySmartHeadline,
  linkText,
  upgradeText,
  dealer,
  homes,
  buttons,
}) => {
  const [modalShowing, setModalShowing] = useState(false);

  const wistiaID =
    dealer && dealer.brandAcronym.toLowerCase() === 'cla'
      ? 'bm5txmjioe'
      : 'ezikht6ick';
  return (
    <StyledBanner>
      <div className="shape">
        <img src={EnergySmartBannerImg} alt="energy smart banner image" />
      </div>
      <div
        className="video-box"
        onClick={() =>
          setModalShowing(true) +
          fireSegmentEvent('Video Started', {
            eventContext: 'energysmart_hero_video_play',
            videoId: wistiaID || '',
          })
        }
      >
        <OptimizedImage
          alt="Energy Smart Insulation"
          src={EnergySmartTopImage}
          srcSet={[
            {
              source: EnergySmartTopImageWebp,
            },
          ]}
        />
        <PlayBtn />
      </div>
      <div className="logo-content">
        <OptimizedImage
          src={EnergySmartZeroLogo}
          alt={'Energy Smart Zero'}
          imgClass={'energy-smart-logo'}
        />
        <p className="energy-smart-text">{energySmartText}</p>
        <p className="upgrade-home-text">{upgradeText}</p>
        {buttons && (
          <div className="buttons">
            {buttons.map((button) => {
              return (
                <React.Fragment key={button.buttonText}>
                  {button.buttonURL && (
                    <Link
                      to={{
                        pathname: button.buttonURL,
                        state: {
                          dealer,
                          homes,
                          contactForm: button.openForm ? button.openForm : null,
                        },
                      }}
                      onClick={() => {
                        fireSegmentEvent('CTA Clicked', {
                          isNav: false,
                          type: 'button',
                          text: button.buttonText || '',
                          eventContext: button.retailEventLabel || '',
                        });
                      }}
                      className={
                        'cta-button ' +
                        (button.className ? button.className : '')
                      }
                    >
                      {button.buttonText}
                    </Link>
                  )}
                  {button.buttonModal && button.buttonModal == 'information' && (
                    <button
                      onClick={() => {
                        setShowRequestModal(!showRequestModal);
                        fireSegmentEvent('CTA Clicked', {
                          isNav: false,
                          type: 'button',
                          text: 'Get More Information',
                          eventContext: 'energysmart_hero_request',
                        });
                      }}
                      className={
                        'cta-button ' + (button.gaClass ? button.gaClass : '')
                      }
                    >
                      Get More Information
                    </button>
                  )}
                  {button.buttonModal && button.buttonModal == 'schedule' && (
                    <button
                      onClick={() => {
                        setShowScheduleModal(!showScheduleModal);
                        fireSegmentEvent('CTA Clicked', {
                          isNav: false,
                          type: 'button',
                          text: button.buttonText
                            ? button.buttonText
                            : 'Schedule a Tour',
                          eventContext: 'energysmart_hero_schedule',
                        });
                      }}
                      className={
                        'cta-button ' + (button.gaClass ? button.gaClass : '')
                      }
                    >
                      {button.buttonText
                        ? button.buttonText
                        : 'Schedule a Tour'}
                    </button>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
        <p
          className="link-text"
          onClick={() => {
            smoothScroll('#energySmartSlider', -90);
          }}
        >
          <ArrowDown color={Colors.primary.claytonBlue.standard} />
          {linkText}
        </p>
      </div>
      {modalShowing && (
        <Modal onClose={() => setModalShowing(false)}>
          <div className="video-embed-wrap">
            <WistiaVideoEmbed
              wistiaID={wistiaID}
              wistiaOptions="silentAutoPlay=false endVideoBehavior=default autoPlay=true"
            />
          </div>
        </Modal>
      )}
    </StyledBanner>
  );
};

export default EnergySmartBanner;
