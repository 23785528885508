import React from 'react';
import StyledHomeCenterInfo from './HomeCenterInfo.styled';

const HomeCenterInfo = ({ dealerName, dealer, toTitleCase, formatPhone }) => {
  return (
    <StyledHomeCenterInfo>
      <div className="store-info">
        <div className="row">
          <div className="address">
            <p className="section-header">ADDRESS</p>
            <p>
              {dealerName}
              <br />
              {toTitleCase(dealer.address1)}
              <br />
              {toTitleCase(dealer.city)}, {dealer.stateProvince}{' '}
              {dealer.postalCode}
            </p>
            <a className="phone-number" href={`tel:${dealer.phoneNumber}`}>
              {formatPhone(dealer.phoneNumber)}
            </a>
          </div>
          <div className="hours">
            <p className="section-header">HOURS</p>
            <p>
              M-Th: {dealer.weekdayHours}
              <br />
              Fri: {dealer.fridayHours}
              <br />
              Sat: {dealer.saturdayHours}
              <br />
              Sun: {dealer.sundayHours}
            </p>
          </div>
        </div>
      </div>
    </StyledHomeCenterInfo>
  );
};

export default HomeCenterInfo;
