import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import App from '../../App';
import Colors from '../../colors';
import VideoBackgroundImage from '../../images/promo-images/energy-smart-zero-video-banner.jpg';
import LeafIconConfirmation from '../../images/svg/promo-logos/LeafIconConfirmation';
import EnergySmartZeroLogo from '../../images/svg/promo-logos/energy-smart-zero-logo.svg';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import EnergySmartZeroCostSavings from '../Shared/EnergySmartZeroCostSavings/EnergySmartZeroCostSavings';
import EnergySmartZeroSlider from '../Shared/EnergySmartZeroSlider/EnergySmartZeroSlider';
import TextContent from '../Shared/TextContent/TextContent';
import Video from '../Shared/Video/Video';
import XLCTA from '../Shared/XLCTA/XLCTA';
import { StyledFallPromoThankYou } from './FallPromoThankYou.Style';
import PromoDisclaimer from './PromoDisclaimer';

const promoIsInactive = false;

const FallPromoThankYou = ({ isClayton, dealer, homesOnSale, homes }) => {
  // const [width, height] = useWindowSize();
  // const isTablet = width <= 1023;
  // const isMobile = width <= 767;
  const disclaimerRef = useRef();

  const handleScroll = () => {
    window.scrollTo({
      top: disclaimerRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  if (dealer?.websiteTemplateDescription === 'BuilderModel') {
    return <Redirect to="/" />;
  }

  if (promoIsInactive) {
    return <Redirect to="/special-offers" />;
  }

  return (
    <App dealer={dealer} homes={homes}>
      <StyledFallPromoThankYou>
        <Helmet>
          <title>
            EnergySmart Zero - Energy Efficient Manufactured, Mobile, Modular
            Homes | {dealer ? formatDealerName(dealer?.dealerName) : ''}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <div className="ty-message-banner">
          <div className="banner">
            <img
              src={EnergySmartZeroLogo}
              alt="Energy Smart Zero"
              className="energy-smart-zero-logo"
              loading="lazy"
            />
            <h3 className="banner-headline">
              Comfort and energy savings,{' '}
              <span className="bold">redefined.</span>
            </h3>
          </div>
          <div className="confirmation-message">
            <div className="message-container">
              <h3>Thank You!</h3>
              <div className="house-container">
                <LeafIconConfirmation />
              </div>
              <p>
                Thank you for reaching out. A home center
                <br /> consultant will be in touch shortly.
              </p>
              <Link
                to={{
                  pathname: '/sale-homes',
                  state: { dealer, homes },
                }}
                id="viewHomes"
                className="button"
              >
                View Homes
              </Link>
            </div>
          </div>
        </div>
        <TextContent
          smallHeadline={`EnergySmart Zero™`}
          largeHeadline={'The home of tomorrow is here. Today.'}
          content={
            <>
              Every home we build now comes equipped with EnergySmart Zero™
              making them up to 50% more efficient than the average home
              <span className="superscript">1</span>, and saving you tens of
              thousands of dollars over time
              <span className="superscript">2</span>. Enjoy a whole new level of
              home performance with industry leading energy savings, comfort,
              health and durability.
            </>
          }
          links={[
            {
              linkContent: (
                <>
                  <span className="bold">1</span>{' '}
                  <a
                    href="https://www.energy.gov/sites/default/files/2022-11/ZERH%20Name%20and%20Logo%20Use%20Guidelines_0.pdf"
                    target="_blank"
                  >
                    ZERH Logo Use Guidelines (energy.gov)
                  </a>
                </>
              ),
            },
            {
              linkContent: (
                <>
                  <span className="bold">2</span>{' '}
                  <a
                    href="https://stage.energy.gov/eere/buildings/articles/ultra-efficient-fact-sheet"
                    target="_blank"
                  >
                    ZERH Factsheets.pdf (energy.gov)
                  </a>
                </>
              ),
            },
          ]}
          backgroundColor={Colors.primary.white.standard}
          mobileBackgroundColor={Colors.secondary.grey6.standard}
          aboveVideoContent={false}
        />
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          retailEventLabel={'campaign_video'}
          image={{ source: VideoBackgroundImage }}
          videoID={isClayton ? 'impt0zivef' : '1g6vi7x1lf'}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          noSaleHomes={true}
        />
        <EnergySmartZeroSlider dealer={dealer} isPromo={true} />
        <EnergySmartZeroCostSavings
          dealer={dealer}
          isPromoBackgroundImg={true}
        />
        <div id="PromoDisclaimer" ref={disclaimerRef}>
          <PromoDisclaimer
            mainDisclaimer={`* CONDITIONS FOR THE ENERGY SMART ZERO™ EXTENDED SALES EVENT
            OFFER (the “Offer”): Offer only available at participating
            Clayton Family of Brands retailers with purchase of a new
            EnergySmart Zero™ home at the posted retail sales price with a
            Sales Agreement signed 1/1/2024 through 3/31/2024. Eligible
            buyers will receive a $2,000 VISA® prepaid debit card for the
            home purchase. Offer will be applied first to the amount of the
            negotiated or lender required reduction of the home sales price
            if applicable. The $2,000 may not be applied towards the buyer's
            down payment if the buyer obtains financing for the home
            purchase. Offer will be in the form of a VISA® prepaid debit
            card issued in the name of the primary buyer by Pathward®
            pursuant to a license from VISA U.S.A. and is usually delivered
            within eight weeks after the Seller has been paid in full for
            all goods and services purchased by the buyer. VISA debit card
            is subject to all terms and conditions sets forth on the card
            (including the expiration date) and in the issuer’s Cardholder
            Agreement. Offer not available with USDA/FHA/VA financing and
            cannot be transferred or combined with other offers. Void where
            prohibited by law.`}
            disclaimerLinkText={'** ZERH Logo Use Guidelines (energy.gov)'}
            disclaimerLink={
              'https://www.energy.gov/sites/default/files/2022-11/ZERH%20Name%20and%20Logo%20Use%20Guidelines_0.pdf'
            }
          />
        </div>
        {dealer && (
          <XLCTA
            phoneNumber={dealer.phoneNumber}
            customCallText={'Find Your Perfect Home'}
            gaPhoneClass={'ga-seasonal-campaign-phone-number'}
            buttons={[
              {
                buttonText: 'Get More Information',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-get-more-information',
              },
              {
                buttonText: 'Schedule a Visit',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-schedule-a-visit',
              },
            ]}
          />
        )}
      </StyledFallPromoThankYou>
    </App>
  );
};

FallPromoThankYou.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService);
};

export default FallPromoThankYou;
