import React from 'react';
import PropTypes from 'prop-types';

const LeafIcon = ({ color, width, height }) => (
  <svg
    id="leafIcon"
    width={width}
    height={height}
    viewBox="0 0 272 272"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5" clipPath="url(#clip0_3218_1527)">
      <path
        d="M261.367 22.709L180.612 103.457H270.736C273.989 76.6798 270.966 49.0567 261.361 22.709"
        fill={color}
      />
      <path
        d="M267.775 120.502H163.565L103.853 180.215H241.92C254.331 161.925 263.036 141.664 267.775 120.502Z"
        fill={color}
      />
      <path
        d="M150.436 4.51514C129.242 9.39905 108.979 18.2542 90.7173 30.8447V169.244L150.436 109.531V4.51514Z"
        fill={color}
      />
      <path
        d="M167.482 92.4859L249.319 10.6562C222.619 0.924612 194.6 -2.07343 167.482 1.38399V92.4859Z"
        fill={color}
      />
      <path
        d="M86.8059 197.255L57.7235 226.334C54.3927 229.659 49.0006 229.659 45.6698 226.334C42.3391 223.01 42.3391 217.612 45.6698 214.282L73.6702 186.284V44.1851C71.1434 46.4275 68.6347 48.6882 66.2228 51.0999C18.0866 99.2379 2.58121 170.496 25.7637 234.15L2.50262 257.409C-0.834208 260.751 -0.834208 266.155 2.50262 269.498C4.17104 271.166 6.35932 272 8.5476 272C10.7359 272 12.9242 271.166 14.5926 269.498L37.902 246.184C57.3911 253.281 77.5511 256.913 97.5902 256.919C142.988 256.919 187.533 239.173 220.92 205.783C223.676 203.027 226.294 200.162 228.833 197.261H86.7999L86.8059 197.255Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_3218_1527">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

LeafIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LeafIcon.defaultProps = {
  color: '#00959B',
  width: '272',
  height: '272',
};

export default LeafIcon;
