import React from 'react';

const QualityToolsIcon = () => {
  return (
    <svg
      id="qualityToolsIcon"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <title>tools</title>
      <circle className="cls-1" cx="25.07" cy="25.9" r="21.56" />
      <rect
        className="cls-2"
        x="30.43"
        y="15.97"
        width="3.96"
        height="10.09"
        transform="translate(64.82 42.02) rotate(-180)"
      />
      <path
        className="cls-2"
        d="M29.71,7.83h5.4v.51a1.73,1.73,0,0,0,1.74,1.74h0a1.73,1.73,0,0,0,1.74-1.74V7.83H44V16H38.59V15.6a1.74,1.74,0,0,0-1.74-1.74h0a1.74,1.74,0,0,0-1.74,1.74V16h-5.4a2.24,2.24,0,0,0-2.24-2.24h0a3.67,3.67,0,0,0-2.6,1.08L23.2,16.47V14.34a6.51,6.51,0,0,1,6.51-6.51Z"
      />
      <line className="cls-3" x1="41.47" y1="7.83" x2="41.47" y2="15.97" />
      <path
        className="cls-4"
        d="M32.17,26.06h.48a3,3,0,0,1,3,3V46.7a0,0,0,0,1,0,0H29.13a0,0,0,0,1,0,0V29.09A3,3,0,0,1,32.17,26.06Z"
        transform="translate(64.82 72.75) rotate(-180)"
      />
      <path
        className="cls-5"
        d="M32.41,28.35h.24a3,3,0,0,1,3,3V40a3,3,0,0,1-3,3h-.24a0,0,0,0,1,0,0V28.35A0,0,0,0,1,32.41,28.35Z"
        transform="translate(68.09 71.34) rotate(-180)"
      />
      <rect className="cls-2" x="16.24" y="14.55" width="2.73" height="13.45" />
      <path
        className="cls-4"
        d="M13,33.77h9.3a0,0,0,0,1,0,0v8.8a4.65,4.65,0,0,1-4.65,4.65h0A4.65,4.65,0,0,1,13,42.57v-8.8a0,0,0,0,1,0,0Z"
      />
      <rect className="cls-5" x="12.96" y="33.77" width="9.3" height="6.21" />
      <polygon
        className="cls-4"
        points="12.96 33.77 14.13 30.06 21.09 30.06 22.26 33.77 12.96 33.77"
      />
      <rect
        className="cls-2"
        x="12.96"
        y="28"
        width="9.3"
        height="2.06"
        rx="1.03"
        ry="1.03"
      />
      <polygon
        className="cls-2"
        points="18.79 14.55 20.52 10.75 18.79 7.44 17.43 7.44 17.61 7.44 16.24 7.44 14.52 10.75 16.24 14.55 18.79 14.55"
      />
      <line className="cls-3" x1="14.52" y1="10.75" x2="20.52" y2="10.75" />
      <path
        className="cls-2"
        d="M42,27.5h1.55a0,0,0,0,1,0,0v5.33a.77.77,0,0,1-.77.77h0a.77.77,0,0,1-.77-.77V27.5A0,0,0,0,1,42,27.5Z"
        transform="translate(16.35 -14.9) rotate(24.27)"
      />
      <rect
        className="cls-2"
        x="41.55"
        y="26"
        width="5.8"
        height="1.85"
        rx="0.93"
        ry="0.93"
        transform="translate(15 -15.89) rotate(24.27)"
      />
      <path
        className="cls-2"
        d="M8.32,17H9.86a0,0,0,0,1,0,0V22.3a.77.77,0,0,1-.77.77h0a.77.77,0,0,1-.77-.77V17a0,0,0,0,1,0,0Z"
        transform="translate(-6.5 4.51) rotate(-20.73)"
      />
      <rect
        className="cls-2"
        x="4.78"
        y="15.37"
        width="5.8"
        height="1.85"
        rx="0.93"
        ry="0.93"
        transform="translate(-5.27 3.77) rotate(-20.73)"
      />
    </svg>
  );
};

export default QualityToolsIcon;
