import React from 'react';

const HomeIcon = () => {
  return (
    <svg
      id="homeIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="425"
      height="247"
      viewBox="0 0 425 247"
    >
      <defs>
        <path id="a" d="M0 .897h424.92v16.73H0z" />
        <path id="c" d="M0 247h424.92V.766H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#00B0AC" d="M21.047 204.132h367.149v-60.747H21.047z" />
        <g transform="translate(0 -.766)">
          <path
            fill="#00B0AC"
            d="M48.135 174.819v-6.425H33.949v-5.971h2.66v-6.424h-2.66v-5.204h8.188v-6.425H16.656a2.89 2.89 0 0 0-2.89 2.89 2.89 2.89 0 0 1-2.891 2.89H8.79a3.213 3.213 0 0 0 0 6.426h2.27a2.707 2.707 0 0 1 0 5.413H8.79a3.212 3.212 0 1 0 0 6.425h7.966a3.008 3.008 0 1 1 0 6.014h-2.541a3.214 3.214 0 0 0 0 6.425h.208a2.891 2.891 0 0 1 0 5.782H8.79a3.213 3.213 0 0 0 0 6.425h2.27a2.707 2.707 0 0 1 0 5.412H8.79a3.213 3.213 0 0 0 0 6.426h25.159v-5.99h2.66v-6.425h-2.66v-5.205h8.188v-6.425h-2.764v-6.034h8.762zM367.042 174.448v6.425h14.186v5.97h-2.661v6.425h2.66v5.204h-8.187v6.426h25.48a2.891 2.891 0 0 0 2.89-2.891 2.891 2.891 0 0 1 2.893-2.891h2.084a3.213 3.213 0 0 0 0-6.425h-2.27a2.707 2.707 0 0 1 0-5.413h2.27a3.212 3.212 0 1 0 0-6.425h-7.966a3.007 3.007 0 1 1 0-6.014h2.542a3.213 3.213 0 0 0 0-6.425h-.21a2.891 2.891 0 0 1 0-5.782h5.634a3.213 3.213 0 0 0 0-6.425h-2.27a2.707 2.707 0 0 1 0-5.412h2.27a3.213 3.213 0 0 0 0-6.425h-25.16v5.989h-2.66v6.425h2.66v5.205h-8.187v6.425h2.764v6.034h-8.762z"
          />
          <g transform="translate(0 204)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <path
              fill="#E6E6E6"
              d="M416.596 17.626H8.325A8.324 8.324 0 0 1 0 9.302v-.08A8.324 8.324 0 0 1 8.325.896h408.27a8.324 8.324 0 0 1 8.325 8.324v.081a8.324 8.324 0 0 1-8.324 8.324"
              mask="url(#b)"
            />
          </g>
          <path
            fill="#BCBEC0"
            d="M19.846 219.648h-.148a1 1 0 0 1-1-1v-13.75h2.148v13.75a1 1 0 0 1-1 1M68.965 219.648h-.148a1 1 0 0 1-1-1v-13.75h2.148v13.75a1 1 0 0 1-1 1M118.084 219.648h-.147a1 1 0 0 1-1-1v-13.75h2.147v13.75a1 1 0 0 1-1 1M167.204 219.648h-.148a1 1 0 0 1-1-1v-13.75h2.148v13.75a1 1 0 0 1-1 1M216.323 219.648h-.148a1 1 0 0 1-1-1v-13.75h2.148v13.75a1 1 0 0 1-1 1M265.442 219.648h-.147a1 1 0 0 1-1-1v-13.75h2.147v13.75a1 1 0 0 1-1 1M314.562 219.648h-.148a1 1 0 0 1-1-1v-13.75h2.148v13.75a1 1 0 0 1-1 1M362.45 219.648h-.149a1 1 0 0 1-1-1v-13.75h2.148v13.75a1 1 0 0 1-1 1M410.336 219.648h-.148a1 1 0 0 1-1-1v-13.75h2.148v13.75a1 1 0 0 1-1 1"
          />
          <path
            fill="#024391"
            d="M82.654 42.9h23.218l-41.25 36.467-23.217.312z"
          />
          <path fill="#024391" d="M41.297 79.624l41.249-36.78 38.674 36.78z" />
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            fill="#0454A3"
            d="M103.715 79.311h245.788V42.844H103.715z"
            mask="url(#d)"
          />
          <path
            fill="#0454A3"
            d="M61.546 79.624l41.249-36.78 38.674 36.78zM308.397 79.624l41.25-36.78 38.673 36.78z"
            mask="url(#d)"
          />
          <path
            fill="#012A66"
            d="M61.824 148.197h326.117V85.919H61.824z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M197.701 148.281h58.311V92.23h-58.311z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M61.824 165.304H388.32v-17.165H61.824z"
            mask="url(#d)"
          />
          <path
            fill="#0068C6"
            d="M198.032 94.163h57.664v-2.075h-57.664z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M252.969 148.139h2.759V92.045h-2.759z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M192.1 85.862a.31.31 0 0 0-.31.31v5.917h.014v56.05h6.228v-55.74c0-.172.138-.31.31-.31h57.042a.31.31 0 0 1 .313.31v55.74h6.226V86.173a.31.31 0 0 0-.31-.311H192.1z"
            mask="url(#d)"
          />
          <path
            fill="#0068C6"
            d="M41.597 165.304h20.228v-17.165H41.597z"
            mask="url(#d)"
          />
          <path
            fill="#001F44"
            d="M41.597 148.078h20.228V85.862H41.597z"
            mask="url(#d)"
          />
          <path
            fill="#BCBEC0"
            d="M38.894 85.862H66.41v-6.551H38.894z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M56.435 85.862h335.327v-6.551H56.435z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M267.169 153.182H388.32v-5.099H267.169z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M189.046 148.139h2.759V85.862h-2.759z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M195.972 111.95h-6.542a1 1 0 0 1-1-1v-1.344a1 1 0 0 1 1-1h6.542a1 1 0 0 1 1 1v1.345a1 1 0 0 1-1 1"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M198.274 111.95h-6.542a1 1 0 0 1-1-1v-1.344a1 1 0 0 1 1-1h6.542a1 1 0 0 1 1 1v1.345a1 1 0 0 1-1 1"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M259.896 111.95h-6.542a1 1 0 0 1-1-1v-1.344a1 1 0 0 1 1-1h6.542a1 1 0 0 1 1 1v1.345a1 1 0 0 1-1 1"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M262.198 111.95h-6.542a1 1 0 0 1-1-1v-1.344a1 1 0 0 1 1-1h6.542a1 1 0 0 1 1 1v1.345a1 1 0 0 1-1 1M268.368 154.649h-72.812v-6.51h71.812a1 1 0 0 1 1 1v5.51z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M189.046 154.649h6.51v-6.51h-6.51zM189.045 161.159h12.601v-6.51h-12.601z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M189.046 167.669h17.57v-6.511h-17.57zM39.14 153.182h19.366v-5.099H39.14z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M58.506 153.182h130.54v-5.043H58.506z"
            mask="url(#d)"
          />
          <path
            fill="#0454A3"
            d="M292.74 148.139h73.145V82.586H292.74z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M355.724 133.412H342.06V96.558h13.664a1 1 0 0 1 1 1v34.854a1 1 0 0 1-1 1"
            mask="url(#d)"
          />
          <path
            fill="#0385D6"
            d="M345.388 113.803h8.23V99.682h-8.23zM345.388 130.288h8.23v-14.121h-8.23z"
            mask="url(#d)"
          />
          <path
            fill="#0068C6"
            d="M303.652 133.398h13.664V96.544h-13.664a1 1 0 0 0-1 1v34.854a1 1 0 0 0 1 1"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M305.385 133.398h13.665V96.544h-13.665a1 1 0 0 0-1 1v34.854a1 1 0 0 0 1 1"
            mask="url(#d)"
          />
          <path
            fill="#0385D6"
            d="M307.491 113.789h8.23V99.667h-8.23zM307.491 130.274h8.23v-14.121h-8.23z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M319.049 133.398h23.01V96.544h-23.01z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M322.173 130.273h17.13V99.667h-17.13z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M338.936 132.829h3.123V96.544h-3.123z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M341.642 137.07h-20.12v-6.796h23.245z"
            mask="url(#d)"
          />
          <path
            fill="#BCBEC0"
            d="M322.752 137.07h-3.976v-6.796h7.383z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M318.776 130.274h7.383v-1.722h-7.383z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M326.159 130.274h18.607v-1.722h-18.607zM340.812 139.827h-.996a.88.88 0 0 1-.88-.88v-1.876h2.757v1.876c0 .486-.395.88-.88.88M322.02 139.827h-.998a.881.881 0 0 1-.88-.88v-1.876h2.757v1.876c0 .486-.395.88-.88.88M289.488 82.586h79.923v-6.551h-79.923z"
            mask="url(#d)"
          />
          <path
            fill="#EFEFEF"
            d="M282.211 82.587h7.277v-6.551h-7.277z"
            mask="url(#d)"
          />
          <path
            fill="#024391"
            d="M285.849 148.139h7.277V82.586h-7.277z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M282.506 76.035l41.25-36.78 38.673 36.78z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M289.488 76.035l41.25-36.78 38.673 36.78z"
            mask="url(#d)"
          />
          <path
            fill="#0385D6"
            d="M297.423 76.035l33.2-29.602 31.126 29.602z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M330.738 39.255h-6.982l-2.957 2.636 5.735 1.112z"
            mask="url(#d)"
          />
          <path
            fill="#FFA10B"
            d="M325.398 118.277h-3.189v-18.48h8.824v12.844a5.635 5.635 0 0 1-5.635 5.636"
            mask="url(#d)"
          />
          <path
            fill="#FFA10B"
            d="M335.71 118.277h3.19v-18.48h-8.824v12.844a5.635 5.635 0 0 0 5.635 5.636"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M324.58 72.369h10.472v-16.77H324.58z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M325.918 70.948h7.796V57.02h-7.796z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M329.587 70.948h.672V57.02h-.672z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M325.8 64.656h8.246v-.672H325.8zM329.816 128.567h1.477V99.682h-1.477z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M321.494 114.863h18.121v-1.477h-18.121z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M222.294 92.045a3.64 3.64 0 1 0 7.176.858c0-.296-.04-.582-.107-.858h-7.07z"
            mask="url(#d)"
          />
          <path
            fill="#024391"
            d="M90.725 51.413H80.81a1 1 0 0 1-1-1v-1.74a1 1 0 0 1 1-1h9.916a1 1 0 0 1 1 1v1.74a1 1 0 0 1-1 1"
            mask="url(#d)"
          />
          <path
            fill="#FFED0B"
            d="M218.31 92.088c-.001.048-.007.094-.007.142a7.526 7.526 0 0 0 15.052 0c0-.048-.006-.094-.007-.142H218.31z"
            mask="url(#d)"
            opacity=".51"
          />
          <path
            fill="#024391"
            d="M126.653 74.239h-9.918a1 1 0 0 1-1-1v-1.74a1 1 0 0 1 1-1h9.918a1 1 0 0 1 1 1v1.74a1 1 0 0 1-1 1M139.697 66.19h-9.918a1 1 0 0 1-1-1v-1.74a1 1 0 0 1 1-1h9.918a1 1 0 0 1 1 1v1.74a1 1 0 0 1-1 1"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M228.065 92.348a1.2 1.2 0 1 1-2.401 0 1.2 1.2 0 0 1 2.401 0"
            mask="url(#d)"
          />
          <path
            fill="#024391"
            d="M161.233 79.31h-2.415a1 1 0 0 1-1-1v-1.74a1 1 0 0 1 1-1h2.415a1 1 0 0 1 1 1v1.74a1 1 0 0 1-1 1M147.133 66.07h-2.415a1 1 0 0 1-1-1v-1.74a1 1 0 0 1 1-1h2.415a1 1 0 0 1 1 1v1.74a1 1 0 0 1-1 1"
            mask="url(#d)"
          />
          <path
            fill="#00244F"
            d="M118.195 89.615h17.214v-3.374h-17.214zM86.02 111.964h10.241v-3.373H86.02zM92.326 121.521h6.979v-3.373h-6.979zM92.326 102.409h6.979v-3.374h-6.979zM145.888 93.733h10.241v-3.374h-10.241zM86.02 140.252h10.241v-3.374H86.02zM123.389 147.737h10.241v-3.374h-10.241zM143.079 145.225h10.241v-3.373h-10.241z"
            mask="url(#d)"
          />
          <path
            fill="#0454A3"
            d="M92.091 148.139h73.145V82.586H92.091z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M155.076 133.412h-13.665V96.558h13.665a1 1 0 0 1 1 1v34.854a1 1 0 0 1-1 1"
            mask="url(#d)"
          />
          <path
            fill="#0385D6"
            d="M144.74 113.803h8.23V99.682h-8.23zM144.74 130.288h8.23v-14.121h-8.23z"
            mask="url(#d)"
          />
          <path
            fill="#0068C6"
            d="M103.004 133.398h13.664V96.544h-13.664a1 1 0 0 0-1 1v34.854a1 1 0 0 0 1 1"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M104.736 133.398h13.665V96.544h-13.665a1 1 0 0 0-1 1v34.854a1 1 0 0 0 1 1"
            mask="url(#d)"
          />
          <path
            fill="#0385D6"
            d="M106.843 113.789h8.23V99.667h-8.23zM106.843 130.274h8.23v-14.121h-8.23z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M118.401 133.398h23.01V96.544h-23.01z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M121.524 130.273h17.13V99.667h-17.13z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M138.288 132.829h3.123V96.544h-3.123z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M140.994 137.07h-20.121v-6.796h23.245z"
            mask="url(#d)"
          />
          <path
            fill="#BCBEC0"
            d="M122.103 137.07h-3.976v-6.796h7.383z"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M118.128 130.274h7.383v-1.722h-7.383z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M125.511 130.274h18.607v-1.722h-18.607zM140.164 139.827h-.996a.88.88 0 0 1-.88-.88v-1.876h2.757v1.876c0 .486-.395.88-.881.88M121.371 139.827h-.997a.881.881 0 0 1-.88-.88v-1.876h2.757v1.876c0 .486-.395.88-.88.88M88.84 82.586h79.923v-6.551H88.84z"
            mask="url(#d)"
          />
          <path
            fill="#EFEFEF"
            d="M81.562 82.587h7.277v-6.551h-7.277z"
            mask="url(#d)"
          />
          <path
            fill="#024391"
            d="M85.201 148.139h7.277V82.586h-7.277z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M81.857 76.035l41.25-36.78 38.673 36.78z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M88.84 76.035l41.25-36.78 38.673 36.78z"
            mask="url(#d)"
          />
          <path
            fill="#0385D6"
            d="M96.774 76.035l33.2-29.602L161.1 76.034z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M130.09 39.255h-6.982l-2.956 2.636 5.734 1.112z"
            mask="url(#d)"
          />
          <path
            fill="#FFA10B"
            d="M124.714 118.148h-3.19v-18.48h8.825v12.845a5.635 5.635 0 0 1-5.635 5.635"
            mask="url(#d)"
          />
          <path
            fill="#FFA10B"
            d="M135.026 118.148h3.19v-18.48h-8.825v12.845a5.635 5.635 0 0 0 5.635 5.635"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M129.167 128.552h1.477V99.667h-1.477z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M120.846 114.849h18.121v-1.478h-18.121zM125.409 72.369h10.471v-16.77h-10.471z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M126.747 70.948h7.795V57.02h-7.795z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M130.415 70.948h.673V57.02h-.673z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M126.629 64.656h8.246v-.672h-8.246zM273.101 161.159h-71.455v-6.51h70.455a1 1 0 0 1 1 1v5.51z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M276.629 167.204h-71.456v-6.51h70.456a1 1 0 0 1 1 1v5.51z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M384.98 16.333c0 8.598-6.972 15.567-15.569 15.567-8.598 0-15.568-6.969-15.568-15.567 0-8.598 6.97-15.567 15.568-15.567 8.597 0 15.568 6.97 15.568 15.567"
            mask="url(#d)"
          />
          <path
            fill="#E6E6E6"
            d="M227.955 204.898h25.892v-37.693h-25.892z"
            mask="url(#d)"
          />
          <path
            fill="#F68C2C"
            d="M211.615 186.69h-9.003v-4.851a4.15 4.15 0 0 1 4.151-4.15h.702a4.15 4.15 0 0 1 4.15 4.15v4.852z"
            mask="url(#d)"
          />
          <path
            fill="#F68C2C"
            d="M207.114 177.689h3.912v5.965h-4.989z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M215.527 186.69h-9.002v-4.851a4.15 4.15 0 0 1 4.15-4.15h.702a4.15 4.15 0 0 1 4.15 4.15v4.852z"
            mask="url(#d)"
          />
          <path
            fill="#0385D6"
            d="M215.662 183.654h-.135v-11.388h1.732v9.79c0 .882-.715 1.598-1.597 1.598"
            mask="url(#d)"
          />
          <path
            fill="#0454A3"
            d="M215.528 176.167h3.9v-3.901h-3.9z"
            mask="url(#d)"
          />
          <path
            fill="#414042"
            d="M207.662 204.898h2.396v-18.207h-2.396z"
            mask="url(#d)"
          />
          <path
            fill="#00B0AC"
            d="M387.698 229.833h-2.18a4.063 4.063 0 0 1-4.064-4.063v-4.064H21.047v4.064a4.063 4.063 0 0 0 4.063 4.063h12.88c2.267 0 3.962 1.878 4.008 4.146a4.063 4.063 0 0 0 4.062 3.981h70.945a4.519 4.519 0 0 1 4.52 4.52 4.52 4.52 0 0 0 4.52 4.52h85.828a4.52 4.52 0 0 0 4.52-4.52 4.52 4.52 0 0 1 4.52-4.52h166.611c2.122 0 4.017-1.552 4.22-3.665a4.065 4.065 0 0 0-4.046-4.462"
            mask="url(#d)"
          />
          <path
            fill="#F68C2C"
            d="M212.383 184.5h-2a.75.75 0 0 1 0-1.5h2a.75.75 0 0 1 0 1.5"
            mask="url(#d)"
          />
          <path
            fill="#BCBEC0"
            d="M153.968 217.4c0 .694-7.408 1.257-16.547 1.257-9.14 0-16.548-.563-16.548-1.257s7.408-1.257 16.548-1.257c9.139 0 16.547.563 16.547 1.257M112.362 217.4c0 .694-7.408 1.257-16.548 1.257-9.139 0-16.548-.563-16.548-1.257s7.41-1.257 16.548-1.257c9.14 0 16.548.563 16.548 1.257"
            mask="url(#d)"
          />
          <path
            stroke="#352269"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M148.744 203.686l-3.811.622a17.675 17.675 0 0 1-14.792-4.413l-13.205-12.104s-3.844-3.083-5.761 2.98"
            mask="url(#d)"
          />
          <path
            fill="#FF900B"
            d="M131.117 214.528l1.997 2.8a.695.695 0 0 0 .502.292c.592.054 1.347.233 1.85.152.666-.107-.328-.438-1.163-1.271-.836-.834-2.086-1.897-.92-3.2 1.167-1.3 5.084-4.25 3.084-7.61-2-3.357-7.667-1.357-6.5 3.226 1.113 4.377 1.252 3.588 1.044 5.175a.622.622 0 0 0 .106.436M144.55 207.916l1.839 5.026s3.083 4.726 4.997 4.85h1.706s.29-.5-.917-.959c-1.208-.458-3.131-1.797-3.75-5.14-.62-3.344-.625-2.402-.625-2.402l-3.25-1.375z"
            mask="url(#d)"
          />
          <path
            fill="#FFA70B"
            d="M94.209 170.645s-2.762-2.696-5.466 0c-2.705 2.696-14.2 13.4-13.7 19.068h1s3.833-5.766 10.916-10.133c7.084-4.367 8.5-7.253 7.25-8.935"
            mask="url(#d)"
          />
          <path
            fill="#414042"
            d="M83.126 217.353l-.39.793s1.314.75 3.439 0c0 0-.73-.605-1.458-.896-.73-.292-1.591.103-1.591.103M103.832 217.353l-.39.793s1.314.75 3.439 0c0 0-.73-.605-1.458-.896-.73-.292-1.591.103-1.591.103"
            mask="url(#d)"
          />
          <path
            fill="#0051C6"
            d="M96.188 187.047c-2.09 21.074-11.472 30.203-11.472 30.203-1.188.696-1.59.103-1.59.103 4.457-9.424 4.575-19.406 4.317-24.09-.163-2.956-.023-5.919.508-8.83.164-.907.356-1.74.574-2.35h8.185l-.522 4.964z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M98.542 182.083a11.962 11.962 0 0 0 3.15 8.083c2.1 2.291 3.313 5.376 4.018 8.26 1.277 5.225-.378 19.34-.378 19.34-1.306.32-1.814-.104-1.814-.104s1.625-11.225-6.62-18.888c-8.244-7.664-7.198-11.727-7.198-11.727s-.478-2.265 1.612-4.964h7.23z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M97.923 169.166c-.376 3 1.794 14.834.809 15.021-.985.186-1.1-.354-2.222-.194-1.123.16-1.293.34-2.793 0-1.5-.34-1.25-.493-4.417-.077-3.167.417-2-1-2-1 1.333-2.25 4.47-15.366 4.47-15.366l6.153 1.616"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M95.814 169.166s-2.696 2.763 0 5.467c2.697 2.704 13.402 14.2 19.068 13.7v-1s-5.766-3.833-10.132-10.917c-4.368-7.083-7.254-8.5-8.936-7.25"
            mask="url(#d)"
          />
          <path
            fill="#FFF0E4"
            d="M114.883 187.333s.834.208 1.625 0c0 0 .792-.02.429.458 0 0-.484.667-2.054.542"
            mask="url(#d)"
          />
          <path
            fill="#F2D6A5"
            d="M93.717 163.416s-.15 2.95-1.238 4.32l4.093 1.076s-.432-3.479-.311-4.146c.122-.666-2.544-1.25-2.544-1.25"
            mask="url(#d)"
          />
          <path
            fill="#F9E9CA"
            d="M98.062 157.785c1.078.52 3.045 1.567 2.751 6.124-.294 4.555-2.628 4.407-6.236 1.91-3.61-2.498-2.262-10.801 3.485-8.034"
            mask="url(#d)"
          />
          <path
            fill="#FFF0E4"
            d="M75.043 189.713s-.118 1.058-.993 1.37c0 0 .86 1.072 1.993-1.37h-1z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M134.3 204.416s.25 6.635-4.417 7.901c-4.666 1.266-5.5 4.016-5.5 4.016s-1.041 2.146-2.083 1.042c0 0 1.167-1.125 1.167-2.875s2.833-3.053 2.833-3.053 2-3.575 1.167-6.469c-.834-2.895 7-6.228 6.833-.562M151.383 204.416s.25 6.635-4.416 7.901c-4.667 1.266-5.5 4.016-5.5 4.016s-1.042 2.146-2.084 1.042c0 0 1.167-1.125 1.167-2.875s2.833-3.053 2.833-3.053 2-3.575 1.167-6.469c-.833-2.895 7-6.228 6.833-.562"
            mask="url(#d)"
          />
          <path
            fill="#EF4B5D"
            d="M156.268 204.692s1.16 1.179 1.188 1.985c0 0 .938.157.313-1.375-.626-1.531-1.407-1.625-1.501-1.562-.094.062 0 .952 0 .952"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M130.415 201.553s-3.532 2.113-8.115-2.387c-3.902-3.83-6.414-6.452-8.925-5.915-.345.073-.381.545-.074.72.568.322 1.199 1.1 1.582 2.862.834 3.833 6.729 13.94 15.532 4.72M132.25 207.454s5.096 1.963 7.763 3.13c2.667 1.166 8.583.159 9.667-3.13 1.083-3.287 0-7.12 0-7.12s-5.167 2.166-8.834 1.583c-3.666-.584-6.805-1.674-9.444-.254-2.64 1.42.848 5.791.848 5.791"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M147.566 200.988s4.62-1.625 5.12-2.375 2.688-.188 3.563 2.375c0 0 .812 1.062 2.125 1.437 1.312.375.187 2.115-.813 2.245-1 .13-2.952-.536-6.062 2.255-3.11 2.792-2.563 3.98-4.188 3.5-1.625-.48.255-9.437.255-9.437"
            mask="url(#d)"
          />
          <path
            fill="#FF900B"
            d="M153.33 199.333s-1.25-1.094-1.811-.25c-.563.844-.594 2-1.063 2.906-.47.907.562.657 1.594.188 1.03-.469 2.906-1.969 1.28-2.844"
            mask="url(#d)"
          />
          <path
            fill="#012A66"
            d="M101.425 158.958s-2.22-3.224-5.61-2.237c-3.39.987-3.208 1.223-4.674 5.668-1.466 4.444-4.466 5.161-4.466 5.161s1.867 3.59 7.534 3.095c0 0 2.402-2.947 3.714-8.256 0 0 3.002-.306 3.502-3.43"
            mask="url(#d)"
          />
          <path
            fill="#352269"
            d="M152.467 206.866l-.932.674-4.731-6.531.932-.675z"
            mask="url(#d)"
          />
          <path
            stroke="#00B0AC"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M133.804 123.462v5.09"
            mask="url(#d)"
          />
          <path
            fill="#00A0DD"
            d="M136.28 121.775a2.642 2.642 0 0 0-2.477.353 2.64 2.64 0 0 0-2.476-.353 2.64 2.64 0 0 0 .608 2.772 2.613 2.613 0 0 0 1.868.763 2.613 2.613 0 0 0 1.87-.763c.75-.75.947-1.836.607-2.772"
            mask="url(#d)"
          />
          <path
            stroke="#00B0AC"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M127.94 123.462v5.09"
            mask="url(#d)"
          />
          <path
            fill="#0454A3"
            d="M130.416 121.775a2.642 2.642 0 0 0-2.477.353 2.64 2.64 0 0 0-2.476-.353 2.637 2.637 0 0 0 .608 2.772 2.613 2.613 0 0 0 1.868.763 2.613 2.613 0 0 0 1.869-.763c.75-.75.948-1.836.608-2.772"
            mask="url(#d)"
          />
          <path
            stroke="#00B0AC"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M139.668 123.462v5.09"
            mask="url(#d)"
          />
          <path
            fill="#0454A3"
            d="M142.144 121.775a2.642 2.642 0 0 0-2.477.353 2.64 2.64 0 0 0-2.476-.353 2.64 2.64 0 0 0 .608 2.772 2.613 2.613 0 0 0 1.868.763 2.613 2.613 0 0 0 1.87-.763c.75-.75.947-1.836.607-2.772"
            mask="url(#d)"
          />
          <path
            stroke="#00B0AC"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M330.738 123.462v5.09"
            mask="url(#d)"
          />
          <path
            fill="#00A0DD"
            d="M333.214 121.775a2.64 2.64 0 0 0-2.476.353 2.64 2.64 0 0 0-2.476-.353 2.637 2.637 0 0 0 .608 2.772 2.613 2.613 0 0 0 1.868.763 2.61 2.61 0 0 0 1.868-.763c.75-.75.948-1.836.608-2.772"
            mask="url(#d)"
          />
          <path
            stroke="#00B0AC"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M324.874 123.462v5.09"
            mask="url(#d)"
          />
          <path
            fill="#0454A3"
            d="M327.35 121.775a2.64 2.64 0 0 0-2.477.353 2.64 2.64 0 0 0-2.476-.353 2.637 2.637 0 0 0 .608 2.772 2.611 2.611 0 0 0 1.868.763 2.61 2.61 0 0 0 1.868-.763c.75-.75.949-1.836.608-2.772"
            mask="url(#d)"
          />
          <path
            stroke="#00B0AC"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M336.602 123.462v5.09"
            mask="url(#d)"
          />
          <path
            fill="#0454A3"
            d="M339.078 121.775a2.64 2.64 0 0 0-2.476.353 2.64 2.64 0 0 0-2.476-.353 2.637 2.637 0 0 0 .608 2.772 2.613 2.613 0 0 0 1.868.763 2.61 2.61 0 0 0 1.868-.763c.751-.75.948-1.836.608-2.772"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M212.771 148.083h24.603v-39.492h-24.603z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M215.528 123.958h7.818v-11.125h-7.818zM226.732 123.958h7.818v-11.125h-7.818z"
            mask="url(#d)"
          />
          <path
            fill="#F68C2C"
            d="M233.256 137.666a1.294 1.294 0 0 1-1.294-1.294v-4.805a1.295 1.295 0 0 1 2.588 0v4.805c0 .715-.58 1.294-1.294 1.294"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M234.952 133.97a1.696 1.696 0 1 1-3.392 0 1.696 1.696 0 0 1 3.392 0M240.901 121.833h5.399v-13.242h-5.399zM203.916 121.833h5.399v-13.242h-5.399zM250.97 25.964c-.98 3.365-3.373 10.083-8.478 17.811a1.094 1.094 0 0 0 .262 1.498c2.383 1.71 9.196 6.898 13.938 13.516.544.76 1.715.563 1.955-.339 1.173-4.397 4.09-13.724 8.695-18.068a1.091 1.091 0 0 0-.096-1.689c-2.83-2.04-10.223-7.625-14.357-13.078-.543-.716-1.666-.514-1.918.35"
            mask="url(#d)"
          />
          <path
            fill="#009B93"
            d="M327.323 188.663c0 .83-4.09 1.504-9.137 1.504-5.046 0-9.136-.673-9.136-1.504 0-.83 4.09-1.502 9.136-1.502 5.047 0 9.137.672 9.137 1.502"
            mask="url(#d)"
          />
          <path
            stroke="#FFC50A"
            d="M255.696 41.13s6.054 114.223 61.058 121.941"
            mask="url(#d)"
          />
          <path
            fill="#414042"
            d="M324.437 187.16s-.65.624-1.275 1.249c0 0-.063.625 2.5 0 0 0 .937-.248.75-1.248h-1.975z"
            mask="url(#d)"
          />
          <path
            fill="#414042"
            d="M322.836 187.099s-.496.463-.974.928c0 0-.047.464 1.91 0 0 0 .714-.184.572-.928h-1.508z"
            mask="url(#d)"
          />
          <path
            fill="#FFF0E4"
            d="M322.05 161.279s-6.03-.92-6.436-.606c-.404.312 2.56 2.08 6.092 2.08 3.532 0 .344-1.474.344-1.474"
            mask="url(#d)"
          />
          <path
            fill="#0051C6"
            d="M320.3 168.069s-1.325 9.78 2.9 19.092h1.975s3.125-7.618 2.375-19.092h-7.25z"
            mask="url(#d)"
          />
          <path
            fill="#0075C9"
            d="M323.119 168.069s-.951 9.78 2.082 19.092h1.418s2.244-7.618 1.705-19.092h-5.205z"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M321.816 157.551c-.02.155-1.135 9.24-1.852 10.9 0 0 1.992 2.906 8.444 2.231 0 0 2.59-11.366-1.792-15.57 0 0-4.446-.325-4.8 2.44"
            mask="url(#d)"
          />
          <path
            fill="#F2D6A5"
            d="M323.5 154.029l-.521 2.192s3.204-.051 3.637-1.11l-1.478-3.473-1.638 2.39z"
            mask="url(#d)"
          />
          <path
            fill="#F9E9CA"
            d="M322.947 148.445c-.748.359-2.112 1.087-1.908 4.247.204 3.159 1.822 3.058 4.326 1.325 2.503-1.733 1.568-7.492-2.418-5.572"
            mask="url(#d)"
          />
          <path
            fill="#231F20"
            d="M316.366 161.532l-.752-.86s-.444-.17-.404.313c.015.188.5.844.5.844s.581.327.656-.297"
            mask="url(#d)"
          />
          <path
            fill="#231F20"
            d="M316.818 161.1c.22.267.051.767-.376 1.117-.426.352-.949.419-1.169.154-.22-.267-.05-.768.375-1.119.427-.35.95-.419 1.17-.152"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M316.194 161.412l-.573.543a.487.487 0 0 0-.013.694l1.891 1.924a.486.486 0 0 0 .684.01l.738-.71a.486.486 0 0 0-.022-.72l-2.055-1.757a.484.484 0 0 0-.65.017"
            mask="url(#d)"
          />
          <path
            fill="#231F20"
            d="M319.252 163.538c.22.267.052.768-.375 1.118-.427.351-.95.42-1.17.154-.22-.268-.051-.768.376-1.12.426-.35.95-.418 1.169-.151"
            mask="url(#d)"
          />
          <path
            fill="#231F20"
            d="M318.318 164.335l.752.86s.444.17.404-.313c-.015-.188-.5-.844-.5-.844s-.58-.327-.656.297"
            mask="url(#d)"
          />
          <path
            fill="#FFF0E4"
            d="M327.083 157.211s-2.664-1.428-2.896 1.468c-.232 2.895-.302 6.987-.302 6.987l-4.407-1.235s-1.121-.064.115.972c1.235 1.036 3.86 2.04 5.559 2.155 1.699.116 4.13-9.497 1.93-10.347"
            mask="url(#d)"
          />
          <path
            fill="#FFC50A"
            d="M324.467 156.971s-1.398 2.01-.981 4.943c0 0 2.92 2.254 4.18 2.26 0 0 1.245-4.679.509-6.41-.736-1.734-3.708-.793-3.708-.793"
            mask="url(#d)"
          />
          <path
            fill="#012A66"
            d="M323.634 150.776s.25.445 0 .862c0 0 .083.416.666 0 .584-.417.625.333.709.458.083.125-.306.618.54.625.849.007-.273.442 0 .667.275.225.543-.042.876 0 .334.04.459 0 .625-.875.167-.875.167-.681.209-1.737.04-1.055.276-1.805-.508-2.263-.784-.46-1.127-1.084-2.184-1.084-1.058 0-1.017-.375-2.058.375-1.042.75-1.188.798-1.49 1.608-.302.809-.385 1.059-.385 1.559s.416.458.916.167c.5-.292.773-.362 1.429-.362h.655z"
            mask="url(#d)"
          />
          <path
            stroke="#F68C2C"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M242.492 43.775s10.45-.055 24.754-5.081M251.717 25.616s7.392 16.963 6.083 33.174"
            mask="url(#d)"
          />
        </g>
      </g>
    </svg>
  );
};

export default HomeIcon;
