import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SectionButton from './SectionButton';
import SectionModal from './SectionModal';

export const SectionContent = ({
  matterportUrl,
  gaLabel,
  trackGAConversion,
  title,
  header,
  body,
  alt,
}) => {
  const [hovered, setHovered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const sectionContentWrapperStyles = {
    marginLeft: `${alt ? '0' : 'auto'}`,
    marginRight: `${alt ? 'auto' : '0'}`,
    paddingRight: `${alt ? '0px' : '20px'}`,
    paddingLeft: `${alt ? '20px' : '0px'}`,
  };

  return (
    <div
      className="section-content-wrapper"
      style={sectionContentWrapperStyles}
      alt={alt}
    >
      <div className="section-content-container">
        <h4 className="section-title">{title}</h4>
        <h2 className="section-header">{header}</h2>
        <p className="section-body">{body}</p>
        <SectionButton
          onClick={openModal}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          matterportUrl={matterportUrl}
          gaLabel={gaLabel}
          trackGAConversion={trackGAConversion}
        />
        <div className="section-content-background" hovered={hovered} />
        <SectionModal isOpen={modalOpen} closeModal={closeModal}>
          <iframe
            title="matterport"
            width="100%"
            height="100%"
            src={matterportUrl}
          />
        </SectionModal>
      </div>
    </div>
  );
};

SectionContent.props = {
  matterportUrl: PropTypes.string,
  gaLabel: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  header: PropTypes.string,
};

export default SectionContent;
