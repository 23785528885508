import React from 'react';
import styled from 'styled-components';
import Colors from '../../../colors';

export const StyledHouseSmartPromoGrid = styled.section`
  padding: 96px 0 53px;
  position: relative;
  @media only screen and (min-width: 768px) {
    padding: 100px 0px 128px;
  }
  p {
    font-family: 'source-sans-pro';
    font-style: normal;
    font-weight: normal;
    line-height: 1.69;
  }
  .disclaimer {
    margin-top: 30px;
    padding: 0px 20px;
    @media only screen and (max-width: 1120px) {
      flex-direction: column;
      padding: 0px 20px;
      max-width: 512px;
      margin: 30px auto 0;
    }
    p {
      font-size: 12px;
      line-height: 14px;
      color: #000;
    }
    .trademark-disclaimer-mobile {
      font-size: 12px;
      line-height: 14px;
      color: #000;
      @media only screen and (min-width: 1121px) {
        display: none;
      }
    }

    .main-disclaimer {
      margin-top: 96px;

      @media screen and (min-width: 1025px) {
        margin-top: 128px;
      }
    }
  }
  .grid-container {
    position: relative;
    max-width: 1224px;
    margin: 0 auto;
  }
  .main-headline {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 700;
    font-family: 'acumin-pro', sans-serif;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.35px;

    @media only screen and (max-width: 1120px) {
      max-width: 512px;
      margin: 0 auto 50px;
    }
    @media only screen and (max-width: 766px) {
      max-width: 347px;
      margin: 0 auto 50px;
    }
  }
  .housesmart-grid {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding: 0;
    @media only screen and (max-width: 1175px) {
      margin: 0 25px;
    }
    @media only screen and (max-width: 1120px) {
      flex-direction: column;
      padding: 0;
      margin: 0;
    }
  }
  .column {
    position: relative;
    flex: 1;
    @media only screen and (max-width: 1120px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:not(:first-child) {
        margin-top: 72px;
        @media only screen and (max-width: 541px) {
          margin-top: 21px;
        }
      }
    }

    .image {
      position: relative;
      width: 100%;
      height: 216px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      @media only screen and (max-width: 1120px) {
        max-width: 512px;
        height: 341px;
        padding: 0px 25px;
      }
      @media only screen and (max-width: 541px) {
        height: 58vw;
        max-width: 327px;
      }
    }

    .content-wrap {
      position: relative;
      text-align: center;
      padding: 40px 2px 0px;
      min-height: 355px;
      margin-bottom: 88px;

      @media only screen and (max-width: 1175px) {
        margin-bottom: 118px;
      }

      @media only screen and (max-width: 1120px) {
        max-width: 512px;
        margin-bottom: 0;
      }

      .title {
        font-weight: 700;
        font-family: 'acumin-pro', sans-serif;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.3px;
      }

      .content {
        font-size: 16px;
        color: ${Colors.accent.grey1.standard};
      }

      @media only screen and (max-width: 1024px) {
        max-width: 512px;
        padding: 40px 25px 0px;
        min-height: 0px;
        .content {
          margin-bottom: 40px;
        }
      }

      .line {
        display: flex;
        width: 40px;
        background: #cacccf;
        height: 1.5px;
        margin: 25px auto;
      }

      .disclaimer {
        margin-top: 15px;
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #4e5255;
      }

      .trademark-disclaimer-desktop {
        font-size: 12px;
        line-height: 14px;
        color: ${Colors.accent.grey1.standard};
        position: absolute;
        width: 1058px;
        margin-top: 102px;
        @media only screen and (max-width: 1120px) {
          display: none;
        }
      }
    }
  }
  .box {
    position: relative;
    min-height: 290px;
    @media only screen and (max-width: 1240px) {
      min-height: 309px;
    }
    @media only screen and (max-width: 1120px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 0px;
    }
    .box-content {
      position: relative;
      padding: 50px 25px 10px;
      @media only screen and (max-width: 1120px) {
        max-width: 562px;
        padding: 50px 25px;
      }
      div,
      p {
        position: relative;
        z-index: 2;
      }
      .box-attribution {
        margin-top: 15px;
      }
      .line {
        display: flex;
        width: 40px;
        background: #cacccf;
        height: 1.5px;
        margin-bottom: 15px;
        color: white;
      }
    }
    .icon {
      position: absolute;
      top: 25px;
      right: 25px;
    }
  }
  .red-box {
    background: #ef4b5d;
    p {
      color: white;
    }
  }
  .purple-box {
    background: #352269;
    p {
      color: white;
    }
  }
  .teal-box {
    background: #00b0ac;
    p {
      color: white;
    }
  }
  .yellow-box {
    background: #ffc50a;
    .line {
      background: #4e5255 !important;
    }
  }
`;
