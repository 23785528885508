import styled from 'styled-components';
import Colors from '../../colors';

const StyledEnergySmartTestimonials = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 54px;
  }

  @media only screen and (min-width: 1025px) {
    margin-bottom: 76px;
  }

  .energy-smart-quote-img {
    max-width: 396px;
    height: auto;
    display: none;

    @media only screen and (min-width: 1025px) {
      display: block;
    }
  }

  .testimonials-container {
    width: 87%;
    margin: 125px auto 0;

    @media only screen and (min-width: 768px) {
      width: 67%;
      margin: 157px auto 0;
    }

    @media only screen and (min-width: 1025px) {
      width: 49%;
      margin: 110px 15% 0 9%;
    }

    .leaf-icon {
      max-width: 54px;
    }

    .testimonials {
      margin-top: 30px;

      @media only screen and (min-width: 1025px) {
        margin-top: 162px;
      }

      .testimonial {
        margin-bottom: 30px;

        &:last-of-type {
          .divider {
            display: none;
          }
        }

        .quote {
          font-family: 'source-sans-pro';
          font-size: 18px;
          line-height: 29px;
          font-style: normal;
          font-weight: 400;
          color: ${Colors.primary.black.standard};
          margin-bottom: 20px;
        }

        .customer {
          font-family: 'source-sans-pro';
          font-size: 12px;
          line-height: 17px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 0.5px;
          color: ${Colors.primary.black.standard};
          text-transform: uppercase;
          margin-bottom: 30px;
        }

        .divider {
          width: 104px;
          border: 1px solid #cacccf;
        }
      }
    }
  }
`;

export default StyledEnergySmartTestimonials;
