import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';

const CrossModCTAStyles = styled.section`
  position: relative;
  padding: 20px 20px;
  margin-bottom: 40px;

  .cta-box {
    width: 100%;
    max-width: 1016px;
    margin: 70px auto 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 117, 201, 0.1);
    border: 0px solid transparent;
    border-radius: 4px;
    padding: 0px 37px 0px 47px;
    @media only screen and (max-width: 775px) {
      flex-direction: column-reverse;
      padding: 30px;
      margin: 130px auto 10px;
      max-width: 510px;
    }
    .content {
      max-width: 560px;
      padding-right: 30px;
      @media only screen and (max-width: 775px) {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-right: 0px;
      }
      .headline {
        margin-bottom: 10px;
      }
    }
    .buttons {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      a {
        font-weight: 600;
        font-family: 'source-sans-pro';
      }
    }
    .cta-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 17px;
      background-color: ${Colors.primary.claytonBlue.standard};
      color: white;
      text-decoration: none;
      border: 0px solid transparent;
      border-radius: 2px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font: 700 12px/100% 'source-sans-pro', sans-serif;
      margin-right: 7px;
      margin-top: 10px;
      transition: 0.2s;
      &:hover {
        background: #179eff;
      }
    }
    .house-icon {
      position: relative;
      margin-top: -22px;
      @media only screen and (max-width: 775px) {
        margin-top: -170px;
      }
      @media only screen and (max-width: 500px) {
        margin-top: -29vw;
        width: 45vw;
      }
    }
  }
  .fineprint {
    position: relative;
    max-width: 1016px;
    margin: 25px auto 0;
    padding: 0px 47px;
    display: flex;
    @media only screen and (max-width: 775px) {
      padding: 0px 10px;
      max-width: 509px;
    }
    p {
      font-family: source-sans-pro;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
    }
  }
`;

export default CrossModCTAStyles;
