import styled from 'styled-components';
import Colors from '../../../colors';

const StyledHomesContainer = styled.section`
  padding: 3rem 0;
  background-color: ${Colors.primary.white.standard};

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 1025px) {
      max-width: 1140px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .homes-title {
    font-family: 'acumin-pro', sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    color: ${Colors.accent.darkGrey.standard};
    margin-bottom: 2rem;
  }

  .home-card-wrapper {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
`;

export default StyledHomesContainer;
