import React from 'react';

const CustomerServiceIcon = () => {
  return (
    <svg
      id="customerServiceIcon"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <title>customer service</title>
      <circle className="cls-1" cx="25.07" cy="25.9" r="21.56" />
      <path
        className="cls-2"
        d="M22,32.8,17.4,34.58a4.79,4.79,0,0,0-3.07,4.47v6.23H28.48V34.53"
      />
      <path
        className="cls-2"
        d="M34.84,32.8l4.64,1.78a4.79,4.79,0,0,1,3.07,4.47v6.23H28.4V34.53"
      />
      <path
        className="cls-3"
        d="M28.85,34.53l3.26,4,3.37-4.33s-.12-2.31-3.37-2.89Z"
      />
      <path
        className="cls-3"
        d="M28.11,34.53l-3.26,4-3.37-4.33s.11-2.31,3.37-2.89Z"
      />
      <path
        className="cls-2"
        d="M25.11,26.86h6.51a0,0,0,0,1,0,0v4.41a3.26,3.26,0,0,1-3.26,3.26h0a3.26,3.26,0,0,1-3.26-3.26V26.86A0,0,0,0,1,25.11,26.86Z"
      />
      <path
        className="cls-4"
        d="M39.55,15c0-.06,0-.12,0-.18A11.08,11.08,0,0,0,28.48,3.7h0a11.07,11.07,0,0,0-11.07,11A8.47,8.47,0,0,0,13.77,22a8.62,8.62,0,0,0,5,7.29,8,8,0,0,0,5.63.56h.14A14.88,14.88,0,0,0,29.33,29,8.72,8.72,0,1,0,39.55,15Z"
      />
      <circle className="cls-2" cx="19.9" cy="21.16" r="2.06" />
      <circle className="cls-2" cx="37.05" cy="21.16" r="2.06" />
      <path
        className="cls-2"
        d="M34,11.8H33.9a7.69,7.69,0,0,1-7.1,4.73H21.86v.62a2,2,0,0,1-2,2v4.28A8.58,8.58,0,0,0,28.48,32h0a8.58,8.58,0,0,0,8.57-8.58V15.66A5,5,0,0,1,34,11.8Z"
      />
      <line className="cls-2" x1="21.86" y1="16.53" x2="19.9" y2="16.53" />
      <path
        className="cls-2"
        d="M25.11,23.58h6.74a0,0,0,0,1,0,0v.51a3.37,3.37,0,0,1-3.37,3.37h0a3.37,3.37,0,0,1-3.37-3.37v-.51A0,0,0,0,1,25.11,23.58Z"
      />
      <circle className="cls-4" cx="38.02" cy="23.22" r="0.97" />
      <circle className="cls-4" cx="18.81" cy="23.22" r="0.97" />
      <path className="cls-2" d="M24.31,20.65a1,1,0,1,1,2.05,0" />
      <path className="cls-2" d="M30.59,20.65a1,1,0,0,1,2.06,0" />
      <circle className="cls-4" cx="28.36" cy="39.04" r="1.46" />
      <circle className="cls-4" cx="28.36" cy="43.82" r="1.46" />
      <path
        className="cls-2"
        d="M16.19,25a6.34,6.34,0,1,0-6.34,6.34,6.24,6.24,0,0,0,2.33-.45,9.13,9.13,0,0,0,4,.45A8.45,8.45,0,0,1,15,28.73,6.27,6.27,0,0,0,16.19,25Z"
      />
      <line className="cls-3" x1="7.01" y1="25.52" x2="7.01" y2="25.52" />
      <line className="cls-3" x1="10.01" y1="25.52" x2="10.01" y2="25.52" />
      <line className="cls-3" x1="13.01" y1="25.52" x2="13.01" y2="25.52" />
    </svg>
  );
};

export default CustomerServiceIcon;
