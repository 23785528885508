import React from 'react';
import ReactModal from 'react-modal';

export const SectionModal = ({ isOpen, closeModal, children }) => {
  const handleBackgroundClose = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const modalStyles = {
    overlay: {
      background: 'none',
      zIndex: 11,
      padding: 0,
    },
    content: {
      border: 'none',
      position: 'relative',
      overflow: 'visible',
      width: '100%',
      height: '100%',
      background: 'none',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 0,
      padding: 0,
    },
  };

  return (
    <ReactModal
      className="modal"
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Facility Tour Section Modal"
      ariaHideApp={false}
    >
      <div className="modal-inner" onClick={handleBackgroundClose}>
        <button
          className="modal-mobile-close-button"
          type="button"
          onClick={closeModal}
        />
        <div className="modal-content" onClick={() => {}}>
          <button
            className="modal-close-button"
            type="button"
            onClick={closeModal}
          />
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

export default SectionModal;
