import styled from 'styled-components';
import Colors from '../../colors';

const StyledBanner = styled.section`
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
    min-height: 818px;
  }

  .video-embed-wrap {
    width: 95vw;
    max-width: 900px;
  }

  .shape {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: ${Colors.secondary.kiddiePool.standard};
    min-height: 206px;
    /* padding-top: 43px; */

    img {
      display: none;
    }

    @media only screen and (min-width: 768px) {
      height: 324px;
    }

    @media only screen and (min-width: 1025px) {
      background: transparent;

      img {
        display: block;
        max-width: 604px;
      }
    }
  }

  .video-box {
    position: relative;
    padding-top: 43px;
    display: flex;
    justify-content: center;
    text-align: center;

    @media only screen and (min-width: 768px) {
      padding-top: 79px;
    }

    @media only screen and (min-width: 1025px) {
      width: 530px;
      max-width: 600px;
      height: 399px;
      display: unset;
      padding-top: 0;
      top: 116px;
      left: 108px;
    }

    @media only screen and (min-width: 1275px) {
      width: 600px;
    }

    img {
      height: auto;
      max-width: 425px;
      width: 87%;

      @media only screen and (min-width: 768px) {
        max-width: 512px;
        width: 100%;
      }

      @media only screen and (min-width: 1025px) {
        max-width: 530px;
      }

      @media only screen and (min-width: 1275px) {
        max-width: 600px;
      }
    }

    #playBtn {
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      width: 110px;
      top: 25%;

      @media only screen and (min-width: 1025px) {
        max-width: 530px;
        transform: none;
        top: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .logo-content {
    /* max-width: 87%; */
    width: auto;
    margin: 64px auto 56px;
    text-align: center;

    @media only screen and (min-width: 405px) {
      margin-top: 160px;
    }

    @media only screen and (min-width: 768px) {
      margin-top: 71px;
      margin-bottom: 100px;
      /* max-width: 390px; */
    }

    @media only screen and (min-width: 1025px) {
      margin-top: 127px;
      margin-bottom: 0;
      /* max-width: 325px; */
      margin-left: 0;
      margin-right: 5%;
    }

    @media only screen and (min-width: 1200px) {
      /* max-width: 451px; */
      margin-left: auto;
      margin-right: auto;
    }

    .energy-smart-logo {
      max-width: 239px;
    }

    .energy-smart-headline {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 48px;
      letter-spacing: -0.35px;
      color: ${Colors.primary.black.standard};
      margin-bottom: 20px;

      @media only screen and (min-width: 768px) {
        font-size: 40px;
        line-height: 48px;
      }

      .teal-text {
        color: ${Colors.secondary.kiddiePool.standard};
        font-weight: 900;
      }
    }

    .energy-smart-text {
      margin: 20px auto 0;
      font-family: 'source-sans-pro';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      color: ${Colors.accent.grey1.standard};
      max-width: 87%;

      @media only screen and (min-width: 768px) {
        max-width: 390px;
      }

      @media only screen and (min-width: 1025px) {
        max-width: 325px;
      }

      @media only screen and (min-width: 1200px) {
        max-width: 451px;
      }
    }

    .upgrade-home-text {
      margin-top: 50px;
      font-family: 'source-sans-pro';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      color: ${Colors.accent.grey1.standard};
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @media screen and (min-width: 461px) {
        justify-content: space-between;
        flex-wrap: nowrap;
        max-width: 450px;
        margin: 0 auto;
      }

      @media screen and (min-width: 1025px) {
        max-width: 400px;
        margin: 0 auto;
      }
    }

    .cta-button {
      border-radius: 2px;
      padding: 17px 17px;
      font: 500 12px/100% 'source-sans-pro', sans-serif;
      margin-top: 9px;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
      margin: 30px 5px 0px;
      width: 220px;
      box-sizing: border-box;

      @media screen and (max-width: 460px) {
        width: 90%;
        max-width: 330px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .energy-smart-get-more-information,
    .energysmart_hero_request {
      background: ${Colors.accent.energySmartDarkGreen.standard};
      color: ${Colors.primary.white.standard};
      border: 1.5px solid ${Colors.accent.energySmartDarkGreen.standard};
    }

    .energy-smart-schedule-a-visit,
    .energysmart_hero_schedule {
      background: ${Colors.primary.white.standard};
      color: ${Colors.accent.energySmartDarkGreen.standard};
      border: 1.5px solid ${Colors.accent.energySmartDarkGreen.standard};

      @media screen and (max-width: 460px) {
        margin-top: 15px;
      }
    }

    .link-text {
      position: relative;
      margin-top: 41px;

      &:hover {
        cursor: pointer;
      }

      svg {
        position: relative;
        margin-right: 4px;
        top: 3px;
      }
    }
  }
`;

export default StyledBanner;
