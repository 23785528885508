import React from 'react';

export const SectionBackground = ({ alt, imageUrl, videoUrl, posterUrl }) => {
  const backgroundImageStyles = {
    backgroundImage: 'url(' + imageUrl + '?width=400' + ')',
    backgroundPosition: 'center center',
    marginLeft: `${alt ? 'auto' : '0px'}`,
  };
  const videoWrapperStyles = {
    marginLeft: `${alt ? 'auto' : '0px'}`,
  };
  return (
    <div id="sectionBackgroundImageWrapper">
      {imageUrl && (
        <div
          className="section-background-image"
          style={backgroundImageStyles}
          alt={alt}
          imageUrl={imageUrl}
        />
      )}
      {videoUrl && (
        <div className="video-wrapper" style={videoWrapperStyles} alt={alt}>
          <div className="video">
            <video
              muted="true"
              autoplay="true"
              playsinline="true"
              loop
              src={videoUrl}
              poster={posterUrl}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionBackground;
