import React from 'react';
import StyledLargestHomeBuilder from './LargestHomeBuilder.styled';
import HomeIcon from './HomeIcon';

const LargestHomeBuilder = () => {
  return (
    <StyledLargestHomeBuilder>
      <div className="container">
        <div className="row">
          <div className="home-icon-container">
            <HomeIcon />
          </div>
          <div className="largest-home-builder-content">
            <h3 className="largest-home-builder-header">
              We’re One of America’s Largest Home Builders
            </h3>
            <p className="largest-home-builder-text">
              We take pride in providing homes to families across America. With
              hundreds of Home Centers conveniently located across the country,
              it’s never been easier to find the home of your dreams.
            </p>
          </div>
        </div>
      </div>
    </StyledLargestHomeBuilder>
  );
};

export default LargestHomeBuilder;
