import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const TestimonialsStyles = styled.div`
  background-color: #00b0ac;
  height: 700px;
  margin-bottom: 200px;
  position: relative;

  .slide-selected ~ .slide-selected {
    user-select: none;
  }

  .left-bg,
  .right-bg {
    display: none;
  }

  .testimonials-container {
    height: 100%;
    margin: auto;
    max-width: 500px;
    position: relative;
  }

  h2 {
    color: ${Colors.primary.white.standard};
    padding: 50px 0;
    position: relative;
    text-align: center;
    @media only screen and (max-width: 769px) {
      font-size: 32px;
      line-height: 38px;
    }
    @media only screen and (max-width: 553px) {
      font-size: 26px;
      line-height: 31px;
    }
  }

  .testimonials {
    height: 650px;

    .focus-grabber {
      height: 100%;
    }

    .slider-wrapper .slide {
      background: none;
    }

    .control-dots {
      display: none;
    }

    .slideshow,
    .slideshow > div,
    .slideshow > div > div,
    .carousel-root,
    .carousel,
    .slider-wrapper,
    .slider,
    .slider {
      height: 100%;
    }

    .carousel.carousel-slider button.control-arrow {
      display: none;
    }
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: calc(100% - 10px);
    width: 86%;

    .testimonial-quote-container {
      box-shadow: rgb(0 0 0 / 20%) 0px 3px 7px 0px;
      align-items: center;
      background-color: ${Colors.primary.white.standard};
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .testimonial-quote {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 20px;

        .testimonial-divider {
          border-bottom: 1px solid ${Colors.primary.black.tint};
          display: block;
          margin: 20px 0;
          width: 50px;
        }
        .testimonial-author {
          white-space: nowrap;
        }

        svg {
          margin-bottom: 15px;
        }

        a.xsmall,
        a.xsmall:visited,
        a.xsmall:hover,
        a.xsmall:active {
          color: ${Colors.primary.claytonBlue.standard};
          cursor: pointer;
          font-weight: 600;
          margin-top: 10px;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
  }

  .testimonial-controls {
    align-items: center;
    background-color: ${Colors.primary.claytonBlue.standard};
    color: ${Colors.primary.white.standard};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    position: relative;
    top: -33px;
    width: 150px;

    button {
      * {
        pointer-events: none;
      }

      svg {
        margin-top: 5px;

        * {
          fill: ${Colors.primary.white.standard};
        }
      }
    }

    .testimonial-indicator {
      color: ${Colors.primary.white.standard};
    }
  }

  @media screen and (min-width: 1024px) {
    height: 500px;

    .left-bg,
    .right-bg {
      display: block;
      max-height: 100%;
      position: absolute;
    }

    .left-bg {
      left: 0;
      top: 0;
    }

    .right-bg {
      right: 0;
      top: 0;
    }

    .testimonials-container {
      max-width: none;
      width: 900px;
    }

    .testimonials {
      height: calc(100% - 50px);
    }

    .testimonial {
      align-items: center;
      flex-direction: row;
      position: relative;
      width: 100%;

      .testimonial-quote-container {
        height: 350px;
        position: absolute;
        right: 0;
        width: 350px;
      }
    }

    .slider-wrapper .slide div img {
      max-height: none;
      width: calc(100% - 300px);
    }

    .testimonial-controls {
      position: absolute;
      right: 100px;
      top: calc(100% + 9px);
    }
  }
`;
