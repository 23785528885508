import styled from 'styled-components';

const CrossModThreeUpStyles = styled.section`
  position: relative;
  padding: 30px 0px;
  .container {
    position: relative;
    max-width: 1056px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .three-up-items {
    position: relative;
    display: flex;
    @media only screen and (max-width: 910px) {
      flex-direction: column;
      max-width: 511px;
      margin: 0 auto;
    }
    .item {
      flex: 1;
      padding: 0px 20px;
      @media only screen and (max-width: 910px) {
        margin-bottom: 30px;
        &:not(:last-child) {
          margin-bottom: 80px;
        }
      }
      .logo {
        margin-bottom: 10px;
      }
      .markdown {
        position: relative;
        @media only screen and (min-width: 910px) {
          padding-right: 28px;
        }
        ul {
          list-style-position: inside;
          margin-top: 10px;
          li {
            &:before {
              content: '';
              display: inline-block;
              height: 1rem;
              width: 0.5rem;
              margin-left: -17px;
            }
          }
        }
      }
    }
  }
`;

export default CrossModThreeUpStyles;
