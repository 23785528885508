import { createGlobalStyle } from 'styled-components';
import CloseIcon from './images/interactive-facility-tour/modal-close.svg';

const GlobalStyle = createGlobalStyle`

    /* put styles that are sitewide here.
    they should be few and far between. */
    * 
    
    {
        box-sizing: border-box;
        font-family: 'acumin-pro', Helvetica, Arial, sans-serif;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        font-size: 100%;
    }

    .slider {
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform;
      -webkit-perspective: 1000;
      -webkit-font-smoothing: antialiased;
      -webkit-transform-style: preserve-3d;
      -webkit-transform: translateZ(0);
    }
 
    .carousel * {
       -webkit-backface-visibility: hidden;
    }

     .loading-screen {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
      

    .zoom-image-modal {
      position: relative;
      /* img {
        max-width: 100%;
        max-height: 100%;
      } */
    }

    .iiz__zoom-portal {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      width: 100vw;
      overflow: hidden;
      background: white;
      img {
        min-height: 100%;
      }
    }

    html, body, .page-wrapper {
      /* overflow-x: hidden; */
      overflow-x: clip;
        overscroll-behavior-x: none;
    }

      .ReactModalPortal .modal {
    display: block;
    max-height: inherit;
    margin: auto;
    will-change: unset;
    box-shadow: none;

    .modal-inner {
      position: relative;
      padding: 100px;
      background: rgba(0, 0, 0, 0.9);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 970px) {
        padding: 80px 10px;
        height: calc(100% - 160px);
      }
    }

    .modal-mobile-close-button {
      top: 20px;
      right: 20px;
      display: none;

      @media (max-width: 970px) {
        display: block;
      }
    }

    .modal-content {
      position: relative;
      background: #ffffff;
      width: 100%;
      height: 100%;
      padding: 0;

      iframe {
        border-width: 2px;
        border-style: inset;
        border-color: initial;
        border-image: initial;
      }
    }

    .modal-close-button {
      border: none;
      background: url(${CloseIcon})
        no-repeat top left;
      background-size: cover;
      width: 34px;
      height: 34px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      position: absolute;
      top: -17px;
      right: -17px;
      cursor: pointer;

      @media (max-width: 970px) {
        display: none;
      }
    }
  }

    .brouchure-template {
        .nav-container, footer {
            display: none;
        }
    }

    .loading-block {
            min-height: calc(100vh - 89px);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein 1s; /* Firefox < 16 */
                -ms-animation: fadein 1s; /* Internet Explorer */
                    animation: fadein 1s;
        }

        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
            }

            /* Firefox < 16 */
            @-moz-keyframes fadein {
                from { opacity: 0; }
                to   { opacity: 1; }
            }

            /* Safari, Chrome and Opera > 12.1 */
            @-webkit-keyframes fadein {
                from { opacity: 0; }
                to   { opacity: 1; }
            }

            /* Internet Explorer */
            @-ms-keyframes fadein {
                from { opacity: 0; }
                to   { opacity: 1; }
            }
    

    .dark-overlay {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: calc(100% - 56px);
            width: 100%;
            z-index: 7;
            background: rgba(0, 0, 0, 0.5);
            @media screen and (min-width: 1024px) {
                height: calc(100% - 117px);
            }
        }
        
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        header, footer {
            flex: 0 1;
        }
        main {
            flex: 1 1;
            position: relative;
        }
        max-width: 100%;
    }

    .center-horizontal {
        margin-left:auto;
        margin-right: auto;
       
      }

      select {
        border: 1px solid #cacccf;
        box-sizing: border-box;
        border-radius: 2px;
        font-family: source-sans-pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #4E5255;
        width:105px;
        height: 32px;
        background-color: white;

        option {
            font-family: source-sans-pro;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #4E5255;
            color: #4E5255;
            width:105px;
            height: 32px;
            background-color: white;
        }
      }

    /* smokey modal overrides */
    @media screen and (max-width: 767px) {
        #root .page-wrapper {
            .modal-container {            
                width: 95vw; 
                .close-modal {
                    top: -35px;
                    svg {
                        scale: 1.5;
                        /* path {
                            fill: #000;
                        } */
                    }
                }
                .modal-content {
                    height: auto;
                }       
            }
        }
    }
`;

export default GlobalStyle;
