import styled from 'styled-components';

const StyledStoreSection = styled.section`
  margin-top: 2rem;
  margin-bottom: 0;
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 1025px) {
    max-width: 1140px;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-right: 15px;
    padding-left: 15px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
`;

export default StyledStoreSection;
