import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../Shared/SmokeyTransitional/components/TextField/TextField';
import TextArea from '../../Shared/SmokeyTransitional/components/TextArea/TextArea';
import CheckBox from '../../Shared/SmokeyTransitional/components/CheckBox/CheckBox';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import GiveTestimonialFormStyles from './GiveTestimonialForm.styled';
import TestimonialsThankYou from '../TestimonialsThankYou/TestimonialsThankYou';
import { testimonialFormSubmit } from '../../../Api';
import Loader from '../../../images/svg/loader';
import { Link } from 'react-router-dom';
import { fireMNTNConversionPixel } from '../../../utils/utils';

export const GiveTestimonialForm = ({ title, ty, dealer, homes }) => {
  const [fullName, setFullName] = useState({ value: '', error: null });
  const [email, setEmail] = useState({ value: '', error: null });
  const [comments, setComments] = useState({ value: '', error: null });
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [termsAgreementError, setTermsAgreementError] = useState(false);
  const [showThankYou, setShowThankYou] = useState(ty || false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearThankYouPage = () => {
    setShowThankYou(false);
    setFullName({ value: '', error: null });
    setEmail({ value: '', error: null });
    setComments({ value: '', error: null });
    setTermsAgreement();
    setTermsAgreementError(false);
  };

  const commentIsValid = () => {
    let isValid = true;

    if (comments.value == '' || comments.value.length < 2) {
      isValid = false;
      setComments({ error: '*Please leave your comment.' });
    }

    return isValid;
  };

  const updateAgreement = (e) => {
    const isChecked = e.target.checked;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (isChecked) {
      setTermsAgreement(true);
      setTermsAgreementError(false);
    } else {
      setTermsAgreement(false);
      setTermsAgreementError(true);
    }
  };

  const termsIsValid = () => {
    let isValid = true;

    if (termsAgreement == false || termsAgreement == null) {
      isValid = false;
      setTermsAgreementError(true);
    }

    return isValid;
  };

  useEffect(() => {
    if (termsAgreement == true) {
      setTermsAgreementError(false);
    }
  }, [termsAgreement, termsAgreementError]);

  const fullNameIsValid = () => {
    let isValid = true;

    if (!fullName.value || fullName.value.length < 2) {
      setFullName({ ...fullName, error: '*Please enter your full name.' });
      isValid = false;
    } else if (!fullName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setFullName({ ...fullName, error: '*Please enter your full name.' });
      isValid = false;
    } else {
      setFullName({ ...fullName, error: null });
    }

    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email.value) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter your email.' });
    } else if (!pattern.test(email.value)) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter a valid email.' });
    } else {
      setEmail({ ...email, error: '' });
    }

    return isValid;
  };

  const validateForm = () => {
    let formIsValid = true;

    //all must be true to submit, so bitwise 'and' it is
    formIsValid &= fullNameIsValid();
    formIsValid &= emailIsValid();
    formIsValid &= commentIsValid();
    formIsValid &= termsIsValid();

    return formIsValid;
  };

  const formSubmitHandler = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = {
      comments: comments.value,
      name: fullName.value,
      email: email.value,
      hasAgreed: true,
      dealerName: dealer.dealerName,
    };

    setIsSubmitting(true);
    const formSubmitted = await testimonialFormSubmit(
      formData,
      dealer.lotNumber
    );

    if (formSubmitted.status === 200) {
      fireMNTNConversionPixel();
      setShowThankYou(true);
      setIsSubmitting(false);
    }
  };

  if (showThankYou) {
    return (
      <TestimonialsThankYou
        label="Requesting Information"
        title={title}
        closeFunction={clearThankYouPage}
        dealer={dealer}
      />
    );
  }

  return (
    <GiveTestimonialFormStyles>
      <div className="form-container">
        <form>
          <TextField
            id="fullName"
            textFieldStyle="primary"
            label="Full Name"
            value={fullName.value}
            errorMessage={fullName.error}
            onChange={(event) => {
              setFullName({ value: event.target.value, error: null });
            }}
            onBlur={fullNameIsValid}
          />
          <TextField
            id="email"
            textFieldStyle="primary"
            label="Email"
            value={email.value}
            errorMessage={email.error}
            onChange={(event) => {
              setEmail({ value: event.target.value, error: null });
            }}
            onBlur={emailIsValid}
          />
          <TextArea
            id="comments"
            label="Comments"
            inputName="comments"
            error={comments.error}
            errorMessage={comments.error}
            value={comments.value}
            inputMaxLength="20000"
            onChange={(event) => {
              setComments({ value: event.target.value });
            }}
          />
          <CheckBox
            label={
              <>
                I agree to the{' '}
                <a
                  href="https://p.widencdn.net/h4meen/CMH_1035C_Customer_Image_Release_Form"
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Terms and Conditions
                </a>
              </>
            }
            checked={termsAgreement}
            name="termsAgreement"
            id="termsAgreement"
            value={termsAgreement}
            onChange={updateAgreement}
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
          />
          {termsAgreementError && (
            <p className="topic-error">
              *Please agree to Terms and Conditions.
            </p>
          )}
          <p>{termsAgreement}</p>
          <p>{termsAgreementError}</p>
        </form>
        <Button
          text={isSubmitting ? <Loader /> : 'Submit'}
          onClick={isSubmitting ? () => {} : formSubmitHandler}
          disabled={isSubmitting}
          automationId="giveTestimonialBtn"
        />
      </div>
    </GiveTestimonialFormStyles>
  );
};

GiveTestimonialForm.propTypes = {
  title: PropTypes.string.isRequired,
};

export default GiveTestimonialForm;
