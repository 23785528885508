import React from 'react';
import StyledEnergySmartTestimonials from './EnergySmartTestimonials.styled';
import EnergySmartQuoteImg from '../../images/webp/energy-smart-testimonials-quote.webp';
import EnergySmartLeaveIcon from '../../images/svg/energy-smart-leave.svg';
import EnergySmartTestimonialsContent from './EnergySmartTestimonialsContent';
import 'react-lazy-load-image-component/src/effects/blur.css';

const EnergySmartTestimonials = () => {
  return (
    <StyledEnergySmartTestimonials>
      <img
        src={EnergySmartQuoteImg}
        alt="Energy Smart quote"
        className="energy-smart-quote-img"
        loading="lazy"
      />
      <div className="testimonials-container">
        <img
          loading="lazy"
          src={EnergySmartLeaveIcon}
          alt="leaf icon"
          className="leaf-icon"
        />
        <div className="testimonials">
          {EnergySmartTestimonialsContent.map((testimonial, i) => (
            <div className="testimonial" key={i}>
              <p className="quote">{testimonial.quote}</p>
              <p className="customer">{testimonial.customer}</p>
              <div className="divider"></div>
            </div>
          ))}
        </div>
      </div>
    </StyledEnergySmartTestimonials>
  );
};

export default EnergySmartTestimonials;
