import React from 'react';
import HeaderStyles from './Header.styled';
import Phone from '../../../images/svg/icons/Phone';
import Colors from '../../../colors';

const Header = ({ dealer, formatPhone }) => {
  return (
    <HeaderStyles>
      <div id="logoContainer">
        <img src={dealer?.vectorLogoReference} alt="visit us" />
      </div>
      <div className="spacer"></div>
      <a id="hcPhoneNumber" href={`tel:${dealer?.phoneNumber}`}>
        <Phone
          width="20px"
          height="20px"
          color={Colors.primary.white.standard}
        />{' '}
        {formatPhone(dealer?.phoneNumber)}
      </a>
    </HeaderStyles>
  );
};

export default Header;
