import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import App from '../../App';
import TestimonialStyles from './Testimonials.styled';
import { parseInitialPropsContext } from '../../utils';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import GiveTestimonialForm from './GiveTestimonialForm/GiveTestimonialForm';
import { Redirect } from 'react-router-dom';

const Testimonials = ({ dealer, homes, isLoading }) => {
  if (dealer?.websiteTemplateDescription == 'BuilderModel') {
    return <Redirect to="/" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <TestimonialStyles>
        <Helmet>
          <title>
            Give a Testimonial | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={`Tell us what you think about your new home. Drop us a note and share your experience. Become part of our online community now.`}
          />
          <meta
            name="og:description"
            content={`Tell us what you think about your new home. Drop us a note and share your experience. Become part of our online community now.`}
          />
        </Helmet>
        <h1 className="title">Give a Testimonial</h1>
        <div className="gray-section">
          <div className="container">
            <div className="content">
              <h2 className="h5">Our customers are our best sales people.</h2>
              <p>
                Tell us what you think about your new home. Drop us a note and
                share your experience. Become part of our online community now.
              </p>
            </div>
            <div className="form-box">
              <GiveTestimonialForm dealer={dealer} homes={homes} />
            </div>
          </div>
        </div>
      </TestimonialStyles>
    </App>
  );
};

Testimonials.getInitialProps = async (ctx) => {
  const data = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  ).catch((e) => {
    console.error(e);
    return { redirectTo: '/error' };
  });
  return data;
};

export default Testimonials;
