import styled from 'styled-components';
import Colors from '../../colors';

const Testimonials = styled.section`
  .loading-block {
    min-height: calc(100vh - 89px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-family: 'acumin-pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    background: ${Colors.primary.claytonBlue.standard};
    padding: 75px 0;
    color: ${Colors.primary.white.standard};
  }
  .gray-section {
    position: relative;
    background: #f7f9fb;
    padding: 50px 0px;
    .container {
      max-width: 680px;
      margin: 0 auto;
      padding: 20px 20px;
      .content {
        position: relative;
        margin-bottom: 5px;
        .h5 {
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  .form-box {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding-top: 70px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
  }
`;

export default Testimonials;
