import BuiltSmartImage from './images/HouseSmart_Promo_BuiltSmart_big.jpg';
import DesignSmartImage from './images/HouseSmart_Promo_DesignSmart_big.jpg';
import EnergySmartImage from './images/HouseSmart_Promo_EnergySmart_big.jpg';
import BudgetSmartImage from './images/HouseSmart_Promo_BudgetSmart_big.jpg';

import BuiltSmartIcon from './icons/BuiltSmart.svg';
import DesignSmartIcon from './icons/DesignSmart.svg';
import EnergySmartIcon from './icons/EnergySmart.svg';
import BudgetSmartIcon from './icons/BudgetSmart.svg';

const HouseSmartPromoGridContent = {
  mainHeadline: 'The HouseSmart® Difference',
  columns: [
    {
      color: 'red',
      image: BuiltSmartImage,
      title: 'BuiltSmart®',
      content:
        'We use quality products from the world’s most trusted brands, like Frigidaire® and Kwikset®, so your home is built to last*. From floor to ceiling, we have stylish and durable options to really set the tone.',
      disclaimer: '*With proper home maintenance.',
      boxQuote:
        'If a family member wanted to know if a Clayton home was the way to go, I would say go for it.',
      boxAttribution: '– Robert W. from Mars Hill, NC',
      boxIcon: BuiltSmartIcon,
      tradeMarkDisclaimer:
        'Trademarks of companies other than Clayton are the property of those other companies. ENERGY STAR® and the ENERGY STAR® mark are registered trademarks owned by the U.S. Environmental Protection Agency.',
    },
    {
      color: 'purple',
      image: DesignSmartImage,
      title: 'DesignSmart®',
      content:
        'Hit a high note with our smart design features. DesignSmart® has you covered with colorful kitchen cabinets and countertops, drywall throughout the home, walk-in closets and Craftsman exterior features.',
      boxQuote:
        'I never knew manufactured homes could be like this. We took a look around and just couldn’t get over it.',
      boxAttribution: '– Bonny T. from Greeneville, TN',
      boxIcon: DesignSmartIcon,
    },
    {
      color: 'teal',
      image: EnergySmartImage,
      title: 'EnergySmart®',
      content:
        'Our ENERGY STAR® ready homes feature a high-performance water heater, ecobee smart thermostat®, LED recessed lighting and a reliable Carrier® furnace. It all comes together to create the perfect harmony, and a lifetime of savings.',
      boxQuote:
        'Everyone that sees my house falls in love with it. I am so happy.',
      boxAttribution: '– Crystal B, from Swansboro, NC',
      boxIcon: EnergySmartIcon,
    },
    {
      color: 'yellow',
      image: BudgetSmartImage,
      title: 'BudgetSmart®',
      content:
        'Drum roll please! We’re making dream of homeownership possible for more people. Our streamlined home building process inside our climate-controlled facilities can help save you money so you can start writing your own song.',
      boxQuote:
        'We’ve bought a lot of houses through the years, and we looked for what’s going to hold its value. We want to see our investment and our children’s investment out of it.',
      boxAttribution: '– Judy P. from New Tazewell, TN',
      boxIcon: BudgetSmartIcon,
    },
  ],
};

export default HouseSmartPromoGridContent;
