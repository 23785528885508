import React, { useState } from 'react';
import EnergySmartZeroSliderStyles from './EnergySmartZeroSlider.styled';
import { Carousel } from 'react-responsive-carousel';
import nextBtn from '../../../images/svg/next-btn.svg';
import prevBtn from '../../../images/svg/prev-btn.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import EnergySmartHouseWebp from '../../../images/webp/energy-smart-house-cutout.webp';
import EnergySmartZeroSliderContent from './EnergySmartZeroSliderContent';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import OptimizedImage from '../OptimizedImage/OptimizedImage';

const EnergySmartZeroSlider = ({ dealer, isPromo }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const dots = [
    { className: 'dot0', index: 0 },
    { className: 'dot1', index: 1 },
    { className: 'dot2', index: 2 },
    { className: 'dot3', index: 3 },
    { className: 'dot4', index: 4 },
    { className: 'dot5', index: 5 },
    { className: 'dot6', index: 6 },
    { className: 'dot7', index: 7 },
    { className: 'dot8', index: 8 },
    { className: 'dot9', index: 9 },
  ];

  const nextClick = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prevClick = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const carouselClassName = () => {
    if (currentSlide === 0) {
      return 'carrier';
    }
    if (currentSlide === 1) {
      return 'ecobee';
    }
    if (currentSlide === 2) {
      return 'thermoply';
    }
    if (currentSlide === 3) {
      return 'lux';
    }
    if (currentSlide === 4) {
      return 'rheem';
    }
    if (currentSlide === 5) {
      return 'lasalle';
    }
    if (currentSlide === 6) {
      return 'insulation';
    }
    if (currentSlide === 7) {
      return 'solar';
    }
    if (currentSlide === 8) {
      return 'frigidaire';
    }
    if (currentSlide === 9) {
      return 'samsung';
    }
    return '';
  };

  return (
    <EnergySmartZeroSliderStyles
      className={isPromo ? 'promo-background' : ''}
      id="energySmartSlider"
    >
      <div className="energy-smart-img-container">
        <img
          src={EnergySmartHouseWebp}
          loading="lazy"
          alt="energy smart home cutout"
        />
        <div className="custom-dots">
          {dots.map((dot) => (
            <button
              key={dot.index}
              className={`dot ${dot.className} ${
                currentSlide === dot.index ? 'active' : ''
              }`}
              onClick={() => {
                setCurrentSlide(dot.index);
              }}
            ></button>
          ))}
        </div>
        {!isPromo && (
          <p className="energy-smart-disclaimer">
            Trademarks of companies other than{' '}
            {formatDealerName(dealer?.dealerName)} are the property of those
            other companies.
          </p>
        )}
      </div>
      <LazyLoadComponent>
        <div className="energy-smart-slider-container">
          <button
            type="button"
            onClick={prevClick}
            className={`energy-smart-btn-prev slider-btns slider-btn-prev ${
              currentSlide === 0 ? 'disabled' : ''
            }`}
          >
            <img loading="lazy" src={prevBtn} alt="prev slide" />
          </button>

          <button
            type="button"
            onClick={nextClick}
            className={`energy-smart-btn-next slider-btns slider-btn-next ${
              dots.length === currentSlide + 1 ? 'disabled' : ''
            }`}
          >
            <img loading="lazy" src={nextBtn} alt="next slide" />
          </button>
          <Carousel
            className={`energy-smart-slider ${carouselClassName()}`}
            showArrows={false}
            autoPlay={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            swipeable={false}
            emulateTouch={true}
            infiniteLoop={false}
            isFocusWithinTheCarousel={false}
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
          >
            {EnergySmartZeroSliderContent &&
              EnergySmartZeroSliderContent.map((slide, i) => (
                <div className="energy-smart-slide" key={'slide' + i}>
                  {slide.slideImg && (
                    <OptimizedImage
                      src={slide.slideImg}
                      srcSet={[{ source: slide.slideImgWebp }]}
                      alt={slide.title}
                      imgClass="slide-img"
                      lazy
                    />
                  )}
                  <h3
                    className="slide-headline"
                    dangerouslySetInnerHTML={{ __html: slide.title }}
                  ></h3>
                  <p
                    className={`${
                      slide.disclaimer || slide.sourceText
                        ? 'has-disclaimer'
                        : ''
                    } slide-text`}
                    dangerouslySetInnerHTML={{ __html: slide.content }}
                  ></p>
                  {slide.disclaimer && (
                    <p className="slide-disclaimer">{slide.disclaimer}</p>
                  )}
                  {slide.sourceLink && slide.sourceText && (
                    <a
                      href={slide.sourceLink}
                      target="_blank"
                      className="slide-link"
                    >
                      {slide.sourceText}
                    </a>
                  )}
                  {slide.slideLogo && (
                    <OptimizedImage
                      src={slide.slideLogo}
                      alt={slide.title}
                      imgClass={
                        slide.logoClass && `${slide.logoClass} slide-logo`
                      }
                      lazy
                    />
                  )}
                  {slide.slideLogoBottom && (
                    <OptimizedImage
                      src={slide.slideLogoBottom}
                      alt={slide.title}
                      imgClass={
                        slide.secondLogoClass &&
                        `${slide.secondLogoClass} slide-logo`
                      }
                      lazy
                    />
                  )}
                </div>
              ))}
          </Carousel>
        </div>
      </LazyLoadComponent>
    </EnergySmartZeroSliderStyles>
  );
};

export default EnergySmartZeroSlider;
