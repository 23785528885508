import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

const RequestInfoFormStyles = styled.div`
  padding: 0 0 40px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  /* @media screen and (min-width: 768px) {
    height: auto;
    padding: 0 0 67px;
    width: 80%;
  }
  @media only screen and (min-width: 1025px) {
    padding: 0 0 58px;
  } */

  .check-box {
    margin-bottom: 15px;
    .label {
      text-transform: none;
      font-family: 'source-sans-pro', sans-serif;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      letter-spacing: inherit;
      a {
        color: ${Colors.primary.claytonBlue.standard};
        text-decoration: none;
        font-family: 'source-sans-pro', sans-serif;
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .topic-error {
    color: #ef4b5d;
    font-size: 12px;
    height: 22px;
    letter-spacing: -0.2px;
    line-height: 1.83;
    margin-top: 0px;
    text-align: left;
  }

  div {
    &.label {
      text-align: center;
      margin-bottom: 4px;
    }
    &.h3 {
      text-align: center;
      margin-bottom: 25px;
      @media screen and (min-width: 400px) {
        margin-bottom: 30px;
      }
      @media screen and (min-width: 768px) {
        margin-bottom: 50px;
      }
    }
  }
  .formspacer {
    display: flex;
    margin: 30px 0px;
  }
  .form-container {
    max-width: 432px;
    margin: 0 auto;
    padding: 0px 20px;
    .form-intro {
      position: relative;
      margin-bottom: 20px;
      h3 {
        margin-bottom: 10px;
      }
    }
  }
  form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 auto;
    margin-bottom: auto;
    & > div {
      flex: 1 1 100%;

      @media screen and (min-width: 768px) {
        margin-bottom: 30px;
        &.half {
          flex: 0 1 47%;
        }
      }
    }

    .dropdown-list {
      .dropdown-selected-text,
      .error-message {
        font-family: 'source-sans-pro', sans-serif;
      }
    }
  }
  button:not(.has-value) {
    width: 100%;
    svg {
      width: 100px;
      height: 19px;
    }
    @media screen and (min-width: 375px) {
      margin-bottom: 5px;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 35px;
    }
  }
  flex: 0 1 auto;
  .disclaimer {
    font-size: 12px;
    line-height: 17px;
    min-height: 50px;
    @media screen and (min-width: 768px) {
      min-height: 60px;
    }
  }
  .button {
    margin-top: 23px;
    margin-bottom: 25px !important;

    @media only screen and (min-width: 768px) {
      margin-top: 50px;
    }
  }
`;

export default RequestInfoFormStyles;
