import styled from 'styled-components';
import Colors from '../../colors';
import CTABackgroundImage from '../../images/webp/cta-background.webp';
import Arrow from '../../images/interactive-facility-tour/arrow.svg';
import ModalClose from '../../images/interactive-facility-tour/modal-close.svg';

const InteractiveFacilityTourStyles = styled.section`
  position: relative;

  #interactiveFacilityTourContainer {
    .container {
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  #facilityTourWrapper {
    font-family: 'acumin-pro', sans-serif;
    font-size: 12px;

    .facilityTourHeader {
      height: calc(100vh - 90px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: #ffffff;
      text-align: center;

      @media (max-width: 970px) {
        height: calc(100vh - 76px);
      }

      .header-inner {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;

        .content-wrapper {
          max-width: 500px;
          padding: 0px 12px;
          .section-title {
            font-size: 3rem;
            font-weight: 900;
            line-height: normal;
            margin-top: 0px;
            margin-bottom: 10px;
            color: white !important;

            @media (max-width: 970px) {
              font-size: 2.35rem;
              padding: 0px 10px;
            }
          }

          .section-header {
            line-height: 1.2;
            font-size: 1.2rem;
            font-weight: 100;
            padding: 0px 40px;
            color: white !important;

            @media (max-width: 970px) {
              padding: 0px 35px;
              font-size: 0.9rem;
              line-height: 1.5;
            }
          }
        }
      }

      .video {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        min-width: 100%;
        height: 100%;
        overflow: hidden;
        video {
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          object-fit: cover;
          object-position: center center;
          position: absolute;
          z-index: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:before {
          display: block;
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: rgba(0, 0, 0, 0.4);

          video {
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            object-fit: cover;
            object-position: center center;
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .scroll-wrapper {
        position: absolute;
        bottom: 20px;
        left: 0;
        z-index: 20;
        width: 100%;
        text-align: center;
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 100;
        cursor: pointer;

        .scroll-button {
          width: 20px;
          height: 8px;
          background: url(${Arrow}) no-repeat top left;
          background-size: contain;
          margin: 5px auto 0px auto;
        }
      }
    }

    .facility-tour-padded {
      padding: 80px 25px;
      max-width: 1450px;
      margin: 0 auto;

      @media (max-width: 970px) {
        padding: 25px 20px;
      }

      @media (max-width: 767px) {
        padding: 25px 0px;
      }
    }

    #sectionWrapper {
      margin: 0 auto 100px auto;
      max-width: 1450px;
      height: calc(100vh - 90px);
      position: relative;
      width: 100%;

      @media (max-width: 970px) {
        min-height: 450px;
        height: auto;
        margin-bottom: 35px;
      }

      #sectionBackgroundImageWrapper {
        display: block;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        text-align: right;
        width: 100%;
        z-index: 0;

        @media (max-width: 970px) {
          height: 250px;
        }

        .section-background-image {
          background-size: cover;
          height: 100%;
          width: 55%;

          @media (max-width: 970px) {
            width: 100%;
          }
        }

        .video-wrapper {
          width: 55%;
          height: 100%;
          position: relative;

          @media (max-width: 970px) {
            width: 100%;
          }

          .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-width: 100%;
            height: 100%;
            overflow: hidden;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.4);
              width: 100%;
              height: 100%;
              z-index: 1;
            }

            video {
              min-width: 100%;
              min-height: 100%;
              width: auto;
              height: auto;
              object-fit: cover;
              object-position: center center;
              position: absolute;
              z-index: 0;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              @media (max-width: 900px) {
                width: 100%;
                min-height: 100%;
              }
            }
          }
        }
      }

      .section-content-wrapper {
        display: flex;
        align-items: center;
        height: calc(100vh - 90px);
        position: relative;
        top: 0;
        width: 50%;
        z-index: 1;

        @media (max-width: 970px) {
          height: auto;
          align-items: flex-start;
          left: 0;
          padding-left: 10px !important;
          padding-right: 10px !important;
          padding-top: 200px;
          position: relative;
          right: auto;
          width: 100vw;
        }

        .modal {
          .modal-inner {
            position: relative;
            padding: 100px;
            background: rgba(0, 0, 0, 0.9);
            height: calc(100% - 200px);
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 970px) {
              padding: 80px 10px;
              height: calc(100% - 160px);
            }
          }

          .modal-mobile-close-button {
            top: 20px;
            right: 20px;
            display: none;

            @media (max-width: 970px) {
              display: block;
            }
          }

          .modal-content {
            position: relative;
            background: #ffffff;
            width: 100%;
            height: 100%;
          }

          .modal-close-button {
            border: none;
            background: url(${ModalClose}) no-repeat top left;
            background-size: cover;
            width: 34px;
            height: 34px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            position: absolute;
            top: -17px;
            right: -17px;
            cursor: pointer;

            @media (max-width: 970px) {
              display: none;
            }
          }
        }
      }

      .section-content-container {
        line-height: 1.5;
        padding: 100px 200px 100px 100px;
        position: relative;

        @media (max-width: 970px) {
          padding: 30px 30px;
        }

        .section-title {
          color: #fec609 !important;
          font-weight: 700;
          letter-spacing: 2px;
          margin: 0px;
          text-transform: uppercase;
          margin-bottom: 20px;
          font-size: 0.95rem;

          @media (max-width: 970px) {
            margin-bottom: 15px;
            font-size: 0.85rem;
          }
        }

        .section-header {
          color: #231f20;
          font-size: 3rem;
          font-weight: 900;
          line-height: 1;
          margin: 0px;
          margin-bottom: 20px;

          @media (max-width: 970px) {
            font-size: 2.5rem;
          }

          @media (max-width: 320px) {
            font-size: 2rem;
          }
        }

        .section-body {
          color: #414042;
          font-size: 1.1rem;
          font-weight: 100;
          margin: 0px;
          margin-bottom: 20px;

          @media (max-width: 970px) {
            font-size: 1rem;
          }
        }

        .section-button {
          button {
            align-items: center;
            background: #ffffff;
            border: none;
            cursor: pointer;
            display: flex;
            font-size: 1.1rem;
            justify-content: center;
            margin: 0;
            padding: 0;
            text-decoration: none;

            &:focus,
            &:hover {
              outline: none;
            }

            &:hover {
              .see-3d {
                color: #0877bb;
              }

              .filled-circle {
                opacity: 1;
                display: block;
              }

              .cube-path {
                fill: #ffffff;
              }
            }

            @media (max-width: 970px) {
              display: none;
            }
          }

          .mobile-section-link {
            align-items: center;
            background: #ffffff;
            color: #231f20;
            border: none;
            cursor: pointer;
            display: flex;
            font-size: 1.1rem;
            justify-content: flex-start;
            margin: 0;
            padding: 0;
            text-decoration: none;
            display: none;

            &:focus,
            &:hover {
              outline: none;
            }

            @media (max-width: 970px) {
              display: flex;
            }
          }

          .matterport-icon-wrapper {
            margin-right: 15px;
            margin-top: 5px;

            .filled-circle {
              fill: #0877bb;
              display: none;
              opacity: 0;
              transition: all 220ms ease-in-out;
            }

            .circle-path {
              fill: #0877bb;
            }

            .cube-path {
              fill: #4a90e2;
              transition: all 200ms ease-in-out;
            }
          }
        }

        .section-content-background {
          position: absolute;
          top: 0;
          left: 0;
          background: #ffffff;
          width: 100%;
          height: 100%;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          transition: all 220ms ease-in-out;
          z-index: -1;
        }
      }
    }
  }
  #ctaWrapper {
    height: 550px;
    position: relative;
    font-family: 'acumin-pro', sans-serif;

    @media (max-width: 970px) {
      height: 320px;
      margin: 0px 10px;
    }

    .cta-content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 2;

      .cta-content-inner {
        max-width: 350px;

        @media (max-width: 970px) {
          max-width: auto;
          width: 100%;
          padding: 0px 25px;
        }

        .cta-header {
          color: #ffffff;
          font-size: 2.3rem;
          font-weight: 900;
          margin-bottom: 45px;

          @media (max-width: 970px) {
            font-size: 1.7rem;
            max-width: 250px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .ift-cta-button {
          border: none;
          background: #ffffff;
          color: #0175c9;
          padding: 15px 50px;
          border-radius: 2px;
          cursor: pointer;
          text-decoration: none;
          font-size: 18px;
        }
      }
    }

    .cta-border {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 0px solid rgba(255, 255, 255, 0);
      transition: all 220ms ease-in-out;
      z-index: 1;
    }

    .cta-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(${CTABackgroundImage}) no-repeat center center;
      background-size: 100% auto;
      transition: all 220ms ease-in-out;
      z-index: 0;

      @media (max-width: 425px) {
        background-size: auto 100%;
      }
    }

    &:hover {
      .cta-border {
        top: 25px;
        left: 25px;
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: 1px solid rgba(255, 255, 255, 1);
      }

      .cta-background {
        background-size: 105% auto;

        @media (max-width: 425px) {
          background-size: auto 105%;
        }
      }
    }
  }
`;

export default InteractiveFacilityTourStyles;
