import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import DropDownList from 'pubweb-smokey/dist/components/DropDownList/DropDownList';
import ChevronDwnSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { contactFormSubmit } from '../../../Api';
import { getUTMParametersFromSessionCache } from '../../../components/Shared/SmokeyTransitional/utils/utils';
import { DXAccountContext } from '../../../contexts/DXAccountContext';
import Loader from '../../../images/svg/loader';
import {
  fireSegmentEvent,
  formatPhoneNumber,
  isSelectableScheduleVisitDate,
} from '../../../utils';
import { getGAClientId } from '../../../utils/config';
import { fireMNTNConversionPixel } from '../../../utils/utils';
import { formatPhoneNumberFromNumber } from '../../Shared/SmokeyTransitional/utils/formatters';
import ContactModal from '../ContactModal/ContactModal';
import ContactModalThankYou from '../ContactModalThankYou/ContactModalThankYou';
import TextField from '../SmokeyTransitional/components/TextField/TextField';
import ScheduleVisitModalFormStyles from './ScheduleVisitModalForm.styled';

export const ScheduleVisitModalForm = ({
  title,
  closeFunction,
  dealer,
  home,
  isPortalForm,
  updatedUser,
  isHomeDetails,
  isCuratedCoolection,
  retailEventLabelPhone,
  retailEventLabel,
  originWebFormCategory,
  originWebPageType,
}) => {
  const dxContext = useContext(DXAccountContext);
  const [firstName, setFirstName] = useState({
    value: updatedUser?.firstName || '',
    error: null,
  });
  const [lastName, setLastName] = useState({
    value: updatedUser?.lastName || '',
    error: null,
  });
  const [phone, setPhone] = useState({
    value: updatedUser?.phone || '',
    error: null,
  });
  const [email, setEmail] = useState({
    value: updatedUser?.email || '',
    error: null,
  });
  const [apptDate, setApptDate] = useState({ value: null, error: null });
  const [apptTime, setApptTime] = useState({ value: '', error: null });
  const [showThankYou, setShowThankYou] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let utmParams;
  try {
    utmParams = getUTMParametersFromSessionCache();
  } catch (error) {
    utmParams = {};
  }

  const setOriginWebPageType = () => {
    if (
      dealer?.websiteTemplateDescription === 'BuilderModel' &&
      isHomeDetails
    ) {
      return 'retail-builder-home-details';
    }

    if (originWebPageType && originWebPageType.length > 0) {
      return originWebPageType;
    }

    return 'retail-home-details';
  };

  const firstnameIsValid = () => {
    let isValid = true;

    if (!firstName.value || firstName.value.length < 2) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else if (!firstName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else {
      setFirstName({ ...firstName, error: null });
    }

    return isValid;
  };

  const lastnameIsValid = () => {
    let isValid = true;

    if (!lastName.value || lastName.value.length < 2) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else if (!lastName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else {
      setLastName({ ...lastName, error: null });
    }

    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email.value) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter your email.' });
    } else if (!pattern.test(email.value)) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter a valid email.' });
    } else {
      setEmail({ ...email, error: '' });
    }

    return isValid;
  };

  const phoneIsValid = () => {
    let isValid = true;

    if (!phone.value) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter your phone number.' });
    } else if (
      !phone.value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i)
    ) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter a valid phone number.' });
    } else {
      setPhone({ ...phone, error: '' });
    }

    return isValid;
  };

  const apptDateIsValid = () => {
    let isValid = true;

    if (!apptDate.value) {
      isValid = false;
      setApptDate({ ...apptDate, error: '*Please select a preferred day.' });
    } else {
      setApptDate({ ...apptDate, error: '' });
    }
    return isValid;
  };

  const apptTimeIsValid = () => {
    let isValid = true;

    if (!apptTime.value || apptTime.value === 'Select A Time') {
      isValid = false;
      setApptTime({ ...apptTime, error: '*Please select a preferred time.' });
    } else {
      setApptTime({ ...apptTime, error: '' });
    }
    return isValid;
  };

  const validateForm = () => {
    let formIsValid = true;

    //all must be true to submit, so bitwise 'and' it is
    formIsValid &= firstnameIsValid();

    formIsValid &= lastnameIsValid();

    formIsValid &= emailIsValid();

    formIsValid &= phoneIsValid();

    formIsValid &= apptDateIsValid();

    formIsValid &= apptTimeIsValid();

    return formIsValid;
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  };

  const formSubmitHandler = async () => {
    if (!validateForm()) {
      return;
    }

    if (isPortalForm) {
      if (
        (dxContext?.state?.account?.phone &&
          dxContext?.state?.account.phone !== phone) ||
        (dxContext?.state?.account?.firstName &&
          dxContext?.state?.account.firstName !== firstName) ||
        !dxContext?.state?.account?.firstName ||
        (dxContext?.state?.account?.lastName &&
          dxContext?.state?.account.lastName !== lastName) ||
        !dxContext?.state?.account?.lastName
      ) {
        const infoUpdate = {
          ...updatedUser,
          ['phone']: phone?.value,
          ['firstName']: firstName?.value,
          ['lastName']: lastName?.value,
        };
        dxContext?.actions?.updateAccountRetail(infoUpdate);
      }
    }

    const formData = isPortalForm
      ? {
          firstName: firstName.value,
          lastName: lastName.value,
          phoneNumber: phone.value,
          emailAddress: email.value,
          preferredAppointmentDate: formatDate(apptDate.value),
          preferredAppointmentTime: apptTime.value,
          dataExtension: 'FormActivityRetail',
          dealerNumber: dealer.dealerNumber,
          canEmail: true,
          doNotCall: false,
          originWebURL: window?.location?.href,
          originWebApp: 'clayton-retail-website',
          originWebPageType: 'clayton-myhome-account',
          originWebFormType: 'lead-form',
          originWebFormCategory: 'myhome-account-schedule',
          utmSource: utmParams.utm_source || '',
          utmCampaign: utmParams.utm_campaign || '',
          utmTerm: utmParams.utm_term || '',
          utmContent: utmParams.utm_content || '',
          utmMedium: utmParams.utm_medium || '',
          kenshooId: utmParams.kenshooid || '',
          gaClientId: getGAClientId() || '',
          claytonAccountId: '',
          googleClickId: utmParams.gclid || '',
          bingClickId: utmParams.msclkid || '',
          facebookClickId: utmParams.fbclid || '',
        }
      : {
          firstName: firstName.value,
          lastName: lastName.value,
          phoneNumber: phone.value,
          emailAddress: email.value,
          preferredAppointmentDate: formatDate(apptDate.value),
          preferredAppointmentTime: apptTime.value,
          dataExtension: 'FormActivityRetail',
          dealerNumber: dealer.dealerNumber,
          modelNumber: home?.modelNumber || null,
          serialNumber: home?.serialNumber || null,
          canEmail: true,
          doNotCall: false,
          originWebURL: window?.location?.href,
          originWebApp: 'clayton-retail-website',
          originWebPageType: setOriginWebPageType(),
          originWebFormType: 'lead-form',
          originWebFormCategory: isCuratedCoolection
            ? originWebFormCategory
            : 'home-details-schedule',
          utmSource: utmParams.utm_source || '',
          utmCampaign: utmParams.utm_campaign || '',
          utmTerm: utmParams.utm_term || '',
          utmContent: utmParams.utm_content || '',
          utmMedium: utmParams.utm_medium || '',
          kenshooId: utmParams.kenshooid || '',
          gaClientId: getGAClientId() || '',
          claytonAccountId: '',
          googleClickId: utmParams.gclid || '',
          bingClickId: utmParams.msclkid || '',
          facebookClickId: utmParams.fbclid || '',
        };

    setIsSubmitting(true);
    const formSubmitted = await contactFormSubmit(formData);

    if (formSubmitted.status === 200) {
      setShowThankYou(true);
      setIsSubmitting(false);
      fireMNTNConversionPixel();
      if (dataLayer) {
        dataLayer.push({ event: 'ga-schedule-visit-modal-form-submit' });
      }
      fireSegmentEvent('Generic Contact Form Submitted', {
        formId: 'schedule-visit-form',
        email: formData?.emailAddress || '',
        firstName: formData?.firstName || '',
        lastName: formData?.lastName || '',
        phone: formData?.phoneNumber || '',
        comments: '',
        eventContext: retailEventLabel ? retailEventLabel : '',
      });
    }
  };
  if (showThankYou) {
    return (
      <ContactModalThankYou
        label={title == 'Schedule a Visit' ? null : 'Schedule A Tour'}
        title={title}
        closeFunction={closeFunction}
      />
    );
  }

  const DatepickerCustomInput = ({ value, onClick }) => (
    <TextField
      textFieldStyle="primary"
      label="Preferred Date"
      value={value || ''}
      errorMessage={apptDate.error}
      onClick={onClick}
      icon={ChevronDwnSvg}
    />
  );

  return (
    <ScheduleVisitModalFormStyles>
      <ContactModal>
        {title !== 'Schedule a Visit' && !isCuratedCoolection && (
          <div className="label">Schedule a Tour</div>
        )}
        <div className="h3">{title}</div>
        <form>
          <TextField
            id="firstName"
            className="half"
            textFieldStyle="primary"
            label="First name"
            value={firstName.value || ''}
            errorMessage={firstName.error}
            onChange={(event) => {
              setFirstName({ value: event.target.value, error: null });
            }}
            onBlur={firstnameIsValid}
            maxLength="40"
          />
          <TextField
            id="lastName"
            className="half"
            textFieldStyle="primary"
            label="Last name"
            value={lastName.value || ''}
            errorMessage={lastName.error}
            onChange={(event) => {
              setLastName({
                value: event.target.value,
                error: null,
              });
            }}
            onBlur={lastnameIsValid}
            maxLength="50"
          />
          <TextField
            id="phone"
            textFieldStyle="primary"
            label="Phone number"
            value={formatPhoneNumber(phone.value)}
            errorMessage={phone.error}
            onChange={(event) => {
              setPhone({ value: event.target.value, error: null });
            }}
            onBlur={phoneIsValid}
            type="tel"
            minLength="10"
            maxLength="10"
          />
          <TextField
            id="email"
            textFieldStyle="primary"
            label="Email"
            value={email.value || ''}
            errorMessage={email.error}
            onChange={(event) => {
              setEmail({ value: event.target.value, error: null });
            }}
            onBlur={emailIsValid}
            maxLength="80"
          />
          <div className="info-text">
            For same day and next day appointments, please call the Home Center
            at{' '}
            <a
              className="phone ga-schedule-phone"
              href={'tel:' + dealer.phoneNumber}
              onClick={() => {
                fireSegmentEvent('Phone Number Link Clicked', {
                  link: dealer?.phoneNumber
                    ? formatPhoneNumberFromNumber(dealer.phoneNumber)
                    : '',
                  eventContext: retailEventLabelPhone || '',
                });
              }}
            >
              {formatPhoneNumberFromNumber(dealer.phoneNumber)}
            </a>
            . We will contact you within 2 business days to confirm or
            reschedule your appointment request.
          </div>
          <div className="half">
            <DatePicker
              placeholderText="Preferred Date"
              selected={apptDate.value}
              onChange={(date) =>
                setApptDate({
                  value: date,
                  error: null,
                })
              }
              dateFormat="MM/dd/yyyy"
              filterDate={isSelectableScheduleVisitDate}
              className="date-picker"
              customInput={<DatepickerCustomInput />}
            />
          </div>

          <div className="half">
            <DropDownList
              automationId="schedVisitBtnOne"
              label="Preferred Time"
              disabled={false}
              errorMessage={apptTime.error}
              id="flavor-drop-down-error"
              items={[
                {
                  text: '--Please choose an option--',
                  value: '--Please choose an option--',
                },
                { text: '9AM - 10AM', value: '9:00' },
                { text: '10AM - 11AM', value: '10:00' },
                { text: '11AM - 12PM', value: '11:00' },
                { text: '12PM - 1PM', value: '12:00' },
                { text: '1PM - 2PM', value: '13:00' },
                { text: '2PM - 3PM', value: '14:00' },
                { text: '3PM - 4PM', value: '15:00' },
                { text: '4PM - 5PM', value: '16:00' },
              ]}
              onChange={(item) => {
                setApptTime({ value: item.value, error: null });
              }}
              onBlur={apptTimeIsValid}
              selectedItem={apptTime.value}
              selectedValue={apptTime.value}
              showClearIcon={false}
            />
          </div>
        </form>
        <Button
          text={
            isSubmitting ? (
              <Loader />
            ) : title == 'Schedule a Visit' ? (
              'Schedule a Visit'
            ) : (
              'Schedule Your Tour'
            )
          }
          automationId="schedVisitBtnTwo"
          onClick={isSubmitting ? () => {} : formSubmitHandler}
          disabled={isSubmitting}
        />
        <p className="disclaimer">
          By submitting this form, I agree that Clayton may contact me by
          telephone, provide me with marketing communications about Clayton
          products and services, and share my contact information as described
          in our website terms.
        </p>
      </ContactModal>
    </ScheduleVisitModalFormStyles>
  );
};

export default ScheduleVisitModalForm;
