import React from 'react';
import BannerStyles from './Banner.styled';
import Form from './Form';
import BreezeWayBanner from '../../../images/landing-pages/breezeway.jpg';

const Banner = ({
  brandAcronym,
  home,
  firstInputRef,
  dealerNumber,
  dealer,
  homes,
}) => {
  return (
    <BannerStyles>
      <img
        src={BreezeWayBanner}
        className="banner-img"
        alt="Breezeway Banner"
      />
      <Form
        brandAcronym={brandAcronym}
        home={home}
        firstInputRef={firstInputRef}
        dealerNumber={dealerNumber}
        dealer={dealer}
        homes={homes}
      />
    </BannerStyles>
  );
};

export default Banner;
