import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import App from '../../App';
import EnergerySmartLogoWhite from '../../images/energy-smart-zero-logo-white.png';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import { findMetaDescription } from '../../utils/utils';
import EnergySmartZeroCostSavings from '../Shared/EnergySmartZeroCostSavings/EnergySmartZeroCostSavings';
import EnergySmartZeroSlider from '../Shared/EnergySmartZeroSlider/EnergySmartZeroSlider';
import HouseSmartCTA from '../Shared/HouseSmartCTA/HouseSmartCTA';
import XLCTA from '../Shared/XLCTA/XLCTA';
import EnergySmartStyles from './EnergySmart.styled';
import EnergySmartBanner from './EnergySmartBanner';
import EnergySmartTestimonials from './EnergySmartTestimonials';

const EnergySmart = ({ dealer, homes, homesOnSale, isLoading }) => {
  const customPageMetaDescription = findMetaDescription(
    dealer?.websiteMetaDescriptions,
    'energysmart'
  );

  if (
    dealer?.websiteTemplateDescription === 'NoSpecialOffersNoEnergySmart' ||
    dealer?.websiteTemplateDescription === 'BuilderModel'
  ) {
    return <Redirect to="/" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <EnergySmartStyles>
        <Helmet>
          <title>
            Energy Smart Home | {formatDealerName(dealer && dealer?.dealerName)}
          </title>
          <meta
            property="og:description"
            content={customPageMetaDescription?.metaDescription}
          />
        </Helmet>
        <EnergySmartBanner
          energySmartHeadline={
            <>
              <span className="teal-text">Energy</span>Smart™
            </>
          }
          energySmartText="Enjoy a whole new level of home performance with industry leading energy savings, comfort, health and durability."
          linkText="Learn More"
          upgradeText="Upgrade your home today."
          dealer={dealer}
          homes={homes}
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              className: 'energysmart_hero_request',
              retailEventLabel: 'energysmart_hero_request',
              openForm: 'requestInfo',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              className: 'energysmart_hero_schedule',
              retailEventLabel: 'energysmart_hero_schedule',
              openForm: 'scheduleVisit',
            },
          ]}
        />
        <EnergySmartZeroSlider dealer={dealer} isPromo={false} />
        <EnergySmartZeroCostSavings
          dealer={dealer}
          isPromoBackgroundImg={false}
        />
        <EnergySmartTestimonials />
        {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
          <HouseSmartCTA
            gaClass={'ga-energysmart-housesmart-cta'}
            retailEventLabel={'energysmart_housesmart'}
          />
        )}

        <XLCTA
          smallText="Upgrade your home today."
          logo={EnergerySmartLogoWhite}
          dealer={dealer}
          homes={homes}
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              gaClass: 'ga-energy-smart-get-more-information',
              openForm: 'requestInfo',
              retailEventLabel: 'footer_dual_cta_request',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              gaClass: 'ga-energy-smart-schedule-a-visit',
              openForm: 'scheduleVisit',
              retailEventLabel: 'footer_dual_cta_schedule',
            },
          ]}
        />
      </EnergySmartStyles>
    </App>
  );
};

EnergySmart.getInitialProps = async ({
  req,
  res,
  match,
  history,
  location,
  ...ctx
}) => {
  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    { req, location },
    dealerService,
    homesService
  ).catch((e) => {
    console.error(e);
    return { redirectTo: '/error' };
  });

  return { dealer, homes, homesOnSale };
};

export default EnergySmart;
