import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import App from '../../App';
import SiteFeedbackStyles from './SiteFeedback.styled';
import { parseInitialPropsContext } from '../../utils';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import SiteFeedbackForm from './SiteFeedbackForm/SiteFeedbackForm';
import { Link } from 'react-router-dom';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';

const SiteFeedback = ({ dealer, homes, isLoading }) => {
  const [showRequestInfo, setShowRequestInfo] = useState(false);
  const [showScheduleYourVisit, setShowScheduleYourVisit] = useState(false);
  const [hideBtns, setHideBtns] = useState(false);

  const handleRequestInfoToggle = () => {
    setShowRequestInfo(!showRequestInfo) ||
      (showScheduleYourVisit &&
        setShowScheduleYourVisit(false) &&
        setShowRequestInfo(true));
  };

  const handleScheduleYourVisit = () => {
    setShowScheduleYourVisit(!showScheduleYourVisit) ||
      (showRequestInfo &&
        setShowRequestInfo(false) &&
        setShowScheduleYourVisit(true));
  };

  const clearThankYouPage = () => {
    (showRequestInfo && setShowRequestInfo(!showRequestInfo)) ||
      (showScheduleYourVisit &&
        setShowScheduleYourVisit(!showScheduleYourVisit));
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setHideBtns(false);
  };

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <SiteFeedbackStyles>
        <Helmet>
          <title>Site Feedback | {formatDealerName(dealer?.dealerName)}</title>
          <meta
            name="description"
            content={`Tell us what you think about your new home. Drop us a note and share your experience. Become part of our online community now.`}
          />
          <meta
            name="og:description"
            content={`Tell us what you think about your new home. Drop us a note and share your experience. Become part of our online community now.`}
          />
        </Helmet>
        <h1 className="title">Site Feedback</h1>
        <div className="gray-section">
          <div className="container">
            <div className="content">
              <p>
                To get information about homes, pricing, model availability,
                home setup, or warranty issues, please use the{' '}
                <Link
                  to={{ pathname: '/contact-us', state: { dealer, homes } }}
                >
                  contact form
                </Link>
                .
              </p>
              <p>
                Use this form to send comments, suggestions, and questions to
                the webmaster of this site. We welcome hearing your thoughts on
                the content, design and ease of use of this site. If you are
                experiencing load issues or missing content, let us know.
              </p>
            </div>
            <div className="form-box">
              <SiteFeedbackForm
                dealer={dealer}
                closeFunction={clearThankYouPage}
              />
            </div>
          </div>
        </div>
      </SiteFeedbackStyles>
    </App>
  );
};

SiteFeedback.getInitialProps = async (ctx) => {
  const data = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  ).catch((e) => {
    console.error(e);
    return { redirectTo: '/error' };
  });
  return data;
};

export default SiteFeedback;
