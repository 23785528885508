import styled from 'styled-components';
import Colors from '../../../colors';

const StyledLargestHomeBuilder = styled.section`
  background-color: #f5f5f5;
  padding: 2rem 0.5rem;

  @media (min-width: 1201px) {
    padding: 3rem 0;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 1025px) {
      max-width: 1140px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .home-icon-container {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    margin: 0 auto;
    width: 90%;

    @media only screen and (min-width: 768px) {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
      width: 100%;
      margin: 0;
    }

    #homeIcon {
      max-width: 100%;

      @media (min-width: 768px) {
        max-width: 270px;
      }

      @media (min-width: 1025px) {
        max-width: none;
        width: 445px;
      }
    }
  }

  .largest-home-builder-content {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }

    .largest-home-builder-header {
      font-family: 'acumin-pro', sans-serif;
      font-size: 22px;
      font-weight: 600;
      line-height: 1.5;
      color: #414042;
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
      line-height: 1.25;

      @media (min-width: 1025px) {
        margin-top: 1rem;
      }
    }

    .largest-home-builder-text {
      font-family: 'source-sans-pro', sans-serif;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #6d6e71;
      line-height: 1.7;
      margin-top: 0;
      margin-bottom: 1rem;

      @media (min-width: 1025px) {
        font-size: 19px;
      }
    }
  }
`;

export default StyledLargestHomeBuilder;
