import React from 'react';
import StyledAboutLocation from './AboutLocation.styled';

const AboutLocation = ({ city, cityDescription, toTitleCase }) => {
  return (
    <StyledAboutLocation>
      <div className="container">
        <div className="row">
          <h3 className="about-title">
            Things We Love About {toTitleCase(city)}
          </h3>
          <div
            className="about-text"
            dangerouslySetInnerHTML={{ __html: cityDescription }}
          />
        </div>
      </div>
    </StyledAboutLocation>
  );
};

export default AboutLocation;
