import PropTypes from 'prop-types';
import React from 'react';
import houseIcon from '../../../images/svg/crossModCTAIcon.svg';
import { fireSegmentEvent } from '../../../utils';
import Markdown from '../Markdown/Markdown';
import CrossModCTAStyles from './CrossModCTA.styled';

export const CrossModCTA = ({ headline, content, buttons, fineprint }) => {
  return (
    <CrossModCTAStyles>
      <div className="cta-box">
        <div className="content">
          {headline && <h3 className="headline">{headline}</h3>}
          {content && <Markdown markdown={content} />}
          {buttons && (
            <div className="buttons">
              {buttons.map((button) => {
                return (
                  <React.Fragment key={button.buttonText}>
                    {button.buttonURL && (
                      <a
                        href={button.buttonURL}
                        className={
                          'cta-button ' + (button.gaClass ? button.gaClass : '')
                        }
                        target={button.target ? button.target : ''}
                        rel="noopener"
                        onClick={() => {
                          fireSegmentEvent('External Link Clicked', {
                            isNav: false,
                            type: 'button',
                            eventContext: button.retailEventLabel
                              ? button.retailEventLabel
                              : '',
                          });
                        }}
                      >
                        {button.buttonText}
                      </a>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
        <img
          loading="lazy"
          className="house-icon"
          src={houseIcon}
          alt="house icon"
        />
      </div>
      {fineprint && (
        <div className="fineprint">
          <Markdown markdown={fineprint} />
        </div>
      )}
    </CrossModCTAStyles>
  );
};

CrossModCTA.props = {
  headline: PropTypes.string,
  content: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      buttonURL: PropTypes.string,
      buttonModal: PropTypes.string,
      gaClass: PropTypes.string,
    })
  ),
  fineprint: PropTypes.string,
};

export default CrossModCTA;
