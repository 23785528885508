import styled from 'styled-components';
import Colors from '../../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import BreezeWayBanner from '../../../images/landing-pages/breezeway.jpg';

const BannerStyles = styled.section`
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  @media only screen and (min-width: 1025px) {
    background: url(${BreezeWayBanner}) no-repeat top center;
    background-size: cover !important;
    position: relative;
    min-height: 707px;

    img {
      display: none;
    }
  }
`;

export default BannerStyles;
