import CarrierSmartComfortImg from '../../../images/energy-smart-slides/carrier-smartcomfort.jpg';
import EcobeeThermostatImg from '../../../images/energy-smart-slides/ecobee-thermostat.jpg';
import ThermoplySheathingImg from '../../../images/energy-smart-slides/thermoply-sheathing.jpg';
import LuxWindowsImg from '../../../images/energy-smart-slides/lux-windows.jpg';
import RheemWaterHeaterImg from '../../../images/energy-smart-slides/rheem-water-heater.jpg';
import LedLasalleLightsImg from '../../../images/energy-smart-slides/led-lasalle-lightbulbs.jpg';
import InsulationImg from '../../../images/energy-smart-slides/knauf-johns-manville-insulation.jpg';
import SolarPanelImg from '../../../images/energy-smart-slides/solar-panel-roof.jpg';
import FrigidaireApplicancesImg from '../../../images/energy-smart-slides/frigidaire-applicances.jpg';

import CarrierSmartComfortImgWebp from '../../../images/webp/carrier-smartcomfort.webp';
import EcobeeThermostatImgWebp from '../../../images/webp/ecobee-thermostat.webp';
import ThermoplySheathingImgWebp from '../../../images/webp/thermoply-sheathing.webp';
import LuxWindowsImgWebp from '../../../images/webp/lux-windows.webp';
import RheemWaterHeaterImgWebp from '../../../images/webp/rheem-water-heater.webp';
import LedLasalleLightsImgWebp from '../../../images/webp/led-lasalle-lightbulbs.webp';
import InsulationImgWebp from '../../../images/webp/knauf-johns-manville-insulation.webp';
import SolarPanelImgWebp from '../../../images/webp/solar-panel-roof.webp';
import FrigidaireApplicancesImgWebp from '../../../images/webp/frigidaire-applicances.webp';

import SmartComfortLogo from '../../../images/svg/energy-smart-slides/smart-comfort-logo-color.svg';
import EcobeeLogo from '../../../images/svg/energy-smart-slides/ecobee-logo.svg';
import LuxLogo from '../../../images/svg/energy-smart-slides/lux-logo-color.svg';
import RheemLogo from '../../../images/svg/energy-smart-slides/rheem-logo-color.svg';
import LasalleBristolLogo from '../../../images/svg/energy-smart-slides/lasalle-bristol-logo-color.svg';
import JohnsManvilleLogo from '../../../images/svg/energy-smart-slides/johns-manville-logo-color.svg';
import KnaufLogo from '../../../images/svg/energy-smart-slides/knauf-logo-color.svg';
import ThermoplyLogo from '../../../images/svg/energy-smart-slides/thermoply-logo.svg';
import FrigidaireLogo from '../../../images/svg/energy-smart-slides/frigidaire-logo.svg';
import SamsungImage from '../../../images/energy-smart-slides/samsung.jpg';
import SamsungLogo from '../../../images/svg/energy-smart-slides/samsung-logo.svg';

const EnergySmartZeroSliderContent = [
  {
    title: `Carrier SmartComfort®<br /> Heat Pump<span class="superscript">§</span>`,
    content:
      'Industry leading dependability & efficiency deliver year-round comfort you can count on.',
    slideImg: CarrierSmartComfortImg,
    slideImgWebp: CarrierSmartComfortImgWebp,
    slideLogo: SmartComfortLogo,
    logoClass: 'smart-comfort-logo',
    disclaimer:
      '§ Heat pump not required for DOE Zero Energy Rating with gas furnace.',
  },
  {
    title: 'ecobee Smart Thermostat®',
    content:
      'Thoughtfully designed with features that bring you comfort at home and control from anywhere.',
    slideImg: EcobeeThermostatImg,
    slideImgWebp: EcobeeThermostatImgWebp,
    slideLogo: EcobeeLogo,
    logoClass: 'ecobee-logo',
  },
  {
    title: 'ThermoPly® Structural<br /> Sheathing',
    content:
      'Provides durability, moisture resistance and best-in-class air barrier.',
    slideImg: ThermoplySheathingImg,
    slideImgWebp: ThermoplySheathingImgWebp,
    slideLogo: ThermoplyLogo,
    logoClass: 'thermoply-logo',
  },
  {
    title: 'Lux® Argon Gas<br /> Low-E Windows',
    content:
      'Reduce glare, block harmful UV rays & reduce energy loss by 30% to 50%.*',
    slideImg: LuxWindowsImg,
    slideImgWebp: LuxWindowsImgWebp,
    slideLogo: LuxLogo,
    logoClass: 'lux-windows-logo',
    sourceLink:
      'https://www.energy.gov/energysaver/window-types-and-technologies',
    sourceText: 'Source',
  },
  {
    title: 'Rheem® Hybrid Electric<br /> Heat Pump Water Heater',
    content:
      'Save about $315/yr with 4X the efficiency of a standard water heater.*',
    slideImg: RheemWaterHeaterImg,
    slideImgWebp: RheemWaterHeaterImgWebp,
    slideLogo: RheemLogo,
    logoClass: 'rheem-logo',
    disclaimer:
      '* Compared to a standard electric tank with minimum efficiency.',
  },
  {
    title: 'LED Light Bulbs',
    content:
      'Use up to 90% less energy and last up to 25 times longer than incandescent bulbs.*',
    slideImg: LedLasalleLightsImg,
    slideImgWebp: LedLasalleLightsImgWebp,
    slideLogo: LasalleBristolLogo,
    logoClass: 'lasalle-bristol-logo',
    sourceLink: 'https://www.energy.gov/energysaver/energy-saver',
    sourceText: 'Source',
  },
  {
    title: 'Next-Gen Insulation',
    content:
      'Johns Manville® or Knauf® R38 in the roof, R13 in the walls, and R22 in the subfloor.<span class="superscript">†</span>',
    slideImg: InsulationImg,
    slideImgWebp: InsulationImgWebp,
    slideLogo: JohnsManvilleLogo,
    slideLogoBottom: KnaufLogo,
    logoClass: 'johns-manville-logo',
    secondLogoClass: 'knauf-logo',
    disclaimer:
      '† Thermal Zone 3 homes will have R21 wall insulation and R27 in the subfloor',
  },
  {
    title: 'Solar Panel Ready',
    content:
      'Offset your annual energy consumption with renewable energy by adding a solar system.',
    slideImg: SolarPanelImg,
    slideImgWebp: SolarPanelImgWebp,
  },
  {
    title:
      'ENERGY STAR® Frigidaire Appliances<span class="superscript">ǂ</span>',
    content:
      'Enjoy the latest in design, functionality and efficiency with energy saving appliances.',
    slideImg: FrigidaireApplicancesImg,
    slideImgWebp: FrigidaireApplicancesImgWebp,
    slideLogo: FrigidaireLogo,
    logoClass: 'frigidaire-logo',
    disclaimer:
      'ǂ ENERGY STAR® is a registered trademark owned by the U.S. Environmental Protection Agency.',
  },
  {
    title:
      'ENERGY STAR® Certified Samsung Appliances<span class="superscript">◊</span>',
    content:
      'Modern stainless-steel refrigerators and dishwashers with fingerprint-resistant finishes.',
    slideImg: SamsungImage,
    slideLogo: SamsungLogo,
    logoClass: 'samsung-logo',
    disclaimer:
      '◊ ENERGY STAR® is a registered trademark owned by the U.S. Environmental Protection Agency.',
  },
];

export default EnergySmartZeroSliderContent;
