import styled from 'styled-components';

const AboutStyles = styled.div`
  .spacer {
    position: relative;
    margin: 20px 0px;
    display: flex;
  }
`;

export default AboutStyles;
