import styled from 'styled-components';
import Colors from '../../colors';

const StyledDisclaimer = styled.div`
  margin: 65px auto;
  background-color: ${Colors.primary.white.standard};
  width: 85%;

  @media screen and (min-width: 768px) {
    margin: 43px auto;
    width: 67%;
  }

  @media screen and (min-width: 1025px) {
    margin: 65px auto;
    width: 85%;
  }

  .other-companies-disclaimer {
    font-family: 'source-sans-pro', sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400;
    color: ${Colors.accent.grey1.standard};
    margin-bottom: 54px;

    @media screen and (min-width: 1025px) {
      display: none;
    }
  }

  .main-disclaimer {
    font-family: 'source-sans-pro', sans-serif;
    font-size: 12px;
    line-height: 15px;
    font-style: normal;
    font-weight: 400;
    color: #000;

    @media screen and (min-width: 1025px) {
      font-size: 16px;
      line-height: 27px;
    }

    .superscript {
      vertical-align: super;
      font-size: 10px;
      line-height: 0;
      font-family: 'source-sans-pro', sans-serif;

      @media screen and (min-width: 1025px) {
        font-size: 12px;
      }
    }
  }

  a {
    font-family: 'source-sans-pro', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: #000;
    text-decoration: none;
    display: block;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: #000;
    }
    &:visited {
      color: #000;
    }
    &:last-of-type {
      margin-top: 20px;

      @media screen and (min-width: 1025px) {
        margin-top: 49px;
      }
    }
  }
`;

export default StyledDisclaimer;
