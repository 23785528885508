export const formatNumberWithCommas = (x) => {
  if (!x) {
    return '';
  }

  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const formatStringToDisplayCase = (str) => {
  return str
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .replace('Of', 'of')
    .replace(' Ii', ' II');
};

export const formatDealerName = (str) => {
  let dealerName;
  let dealerNameSet;
  let dealerNameFormatted;
  if (str.indexOf('-') >= 7) {
    dealerName = str.replace('-', ' of ');
    dealerNameSet = formatStringToDisplayCase(dealerName);
  } else if (str === 'A-1 HOMES-DONNA') {
    dealerNameSet = 'A-1 Homes of Donna';
  } else {
    dealerNameSet = formatStringToDisplayCase(str);
  }
  if (dealerNameSet.indexOf('Llc') >= 7) {
    dealerName = dealerNameSet.replace('Llc', 'LLC');
    dealerNameFormatted = dealerName;
  } else {
    dealerNameFormatted = dealerNameSet;
  }
  return dealerNameFormatted;
};

/**
 * Format a phone number as (XXX) XXX-XXXX from a number.
 * @param {string} phone number to format.
 * @returns {?string} formatted phone number or null if invalid input.
 */
export const formatPhoneNumberFromNumber = (phone) => {
  if (phone && phone.length === 10) {
    return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(
      6,
      4
    )}`;
  }
  return null;
};

export const formatDescription = (
  text,
  name,
  bedroomCount,
  bathroomCount,
  squareFeet
) => {
  if (!text || text === '') {
    return null;
  }
  let bathroomCheck = bedroomCount <= 1 ? 'bathroom' : 'bathrooms';
  let bedroomCheck = bathroomCount <= 1 ? 'bedroom' : 'bedrooms';
  let formattedText = text
    .split('[model name]')
    .join(name.replace('The', ''))
    .split('[bedroom count]')
    .join(bedroomCount)
    .split('[bathroom count]')
    .join(bathroomCount)
    .split('[sq.ft.]')
    .join(squareFeet.toLocaleString())
    .split('[sq. ft.]')
    .join(squareFeet.toLocaleString())
    .split('bathrooms')
    .join(bathroomCheck)
    .split('bedrooms')
    .join(bedroomCheck)
    .split('||')
    .join('\n\n');
  return formattedText;
};
