import React from 'react';

const SaveMoneyIcon = () => {
  return (
    <svg
      id="saveMoneyIcon"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <title>affordable</title>
      <circle className="cls-1" cx="25.07" cy="25.9" r="21.56" />
      <polyline
        className="cls-2"
        points="20.14 33.3 20.14 41.17 22.85 41.17 27.14 34.15"
      />
      <polyline
        className="cls-2"
        points="29.51 33.3 29.51 41.17 32.23 41.17 36.51 34.15"
      />
      <path className="cls-2" d="M11,21.38V15.87h0a5.37,5.37,0,0,1,5.38,5.37" />
      <path
        className="cls-3"
        d="M13.73,19.91A3.91,3.91,0,0,0,11,16.19v4.87L13.56,21A3.81,3.81,0,0,0,13.73,19.91Z"
      />
      <path
        className="cls-2"
        d="M15.5,18.63H33.94A10.08,10.08,0,0,1,44,28.7v0A10.08,10.08,0,0,1,33.94,38.78H19.71A10.08,10.08,0,0,1,9.64,28.7V24.49A5.86,5.86,0,0,1,15.5,18.63Z"
      />
      <polyline
        className="cls-2"
        points="34.64 34.96 34.64 41.17 37.35 41.17 40.77 35.58"
      />
      <polyline
        className="cls-2"
        points="15.39 34.3 15.39 41.17 18.1 41.17 22.39 34.15"
      />
      <path
        className="cls-4"
        d="M42.51,23.43l1.93-1.94a1.2,1.2,0,0,0,0-1.7h0a1.2,1.2,0,0,0-1.7,0l-.34.34"
      />
      <rect
        className="cls-3"
        x="6.64"
        y="26.03"
        width="8.75"
        height="6.45"
        rx="3.23"
        ry="3.23"
      />
      <path
        className="cls-2"
        d="M15.39,21.38V15.87h0a5.36,5.36,0,0,1,5.37,5.37"
      />
      <line className="cls-2" x1="9.12" y1="29.28" x2="9.12" y2="29.12" />
      <line className="cls-2" x1="11.01" y1="29.28" x2="11.01" y2="29.12" />
      <path
        className="cls-3"
        d="M18.11,20.24a3.92,3.92,0,0,0-2.72-3.72v4.86l2.55-.06A3.93,3.93,0,0,0,18.11,20.24Z"
      />
      <polygon
        className="cls-3"
        points="18.1 41.17 16.75 43.17 15.39 43.17 15.39 41.17 18.1 41.17"
      />
      <polygon
        className="cls-3"
        points="22.85 41.17 21.5 43.17 20.14 43.17 20.14 41.17 22.85 41.17"
      />
      <polygon
        className="cls-3"
        points="32.23 41.17 30.87 43.17 29.51 43.17 29.51 41.17 32.23 41.17"
      />
      <polygon
        className="cls-3"
        points="37.35 41.17 36 43.17 34.64 43.17 34.64 41.17 37.35 41.17"
      />
      <path
        className="cls-4"
        d="M13.93,24.51a.72.72,0,0,1,.73-.73h0a.73.73,0,0,1,.73.73"
      />
      <path
        className="cls-4"
        d="M9.91,24.51a.72.72,0,0,1,.73-.73h0a.73.73,0,0,1,.73.73"
      />
      <line className="cls-4" x1="31.01" y1="21.06" x2="31.01" y2="21.06" />
      <circle className="cls-5" cx="31.59" cy="7.83" r="2.95" />
      <line className="cls-4" x1="25.07" y1="21.06" x2="29.01" y2="21.06" />
      <circle className="cls-5" cx="27.04" cy="11.64" r="4.55" />
      <circle className="cls-5" cx="41.24" cy="32.96" r="2.77" />
      <rect className="cls-5" x="6.64" y="39.88" width="6.25" height="2.3" />
      <rect className="cls-5" x="6.64" y="37.63" width="6.25" height="2.3" />
      <circle className="cls-5" cx="27.04" cy="11.64" r="2.67" />
    </svg>
  );
};

export default SaveMoneyIcon;
