import styled from 'styled-components';
import Colors from '../../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const FormStyles = styled.div`
  width: 100%;
  background-color: #0075c9;
  min-height: 0;
  display: flex;

  @media only screen and (min-width: 1025px) {
    width: 50%;
    background-color: rgba(0, 117, 201, 0.9);
    min-height: 707px;
  }

  #heroContent {
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: ${Colors.primary.claytonBlue.standard};
    padding: 1.5rem;

    @media only screen and (min-width: 768px) {
      padding: 2rem 2rem 3rem;
    }

    @media (max-width: 1024px) and (min-width: 768px) {
      max-width: 720px;
    }

    @media only screen and (min-width: 1025px) {
      background-color: transparent;
      margin: 0 0 0 auto;
      max-width: 50%;
      padding: 3rem 2rem 3rem 0;
    }

    .form-headline {
      font-family: 'acumin-pro', sans-serif;
      font-size: 26px;
      font-weight: 600;
      line-height: 1.15;
      color: ${Colors.primary.white.standard};
      margin-bottom: 0.75rem;
      text-align: left;
      letter-spacing: normal;

      @media only screen and (min-width: 1025px) {
        font-size: 36px;
      }
    }

    .form-text {
      font-size: 1.125rem;
      color: ${Colors.primary.white.standard};
      line-height: 1.5;
      margin-bottom: 1rem;

      @media only screen and (min-width: 1201px) {
        max-width: 90%;
        margin-bottom: 1.5rem;
      }
    }

    #formWrapper {
      max-width: none;

      @media only screen and (min-width: 1025px) {
        max-width: 320px;
      }

      form {
        @media only screen and (max-width: 1024px) {
          max-width: none;
          margin: 0 auto;
          display: block;
        }
      }

      .form-group {
        display: flex;
        justify-content: space-between;
        max-width: 330px;
        width: 100%;
        margin-bottom: 0.5rem;

        @media only screen and (min-width: 1025px) {
          margin-bottom: 1rem;
        }

        input {
          width: 100%;
          height: 38px;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          background-color: ${Colors.primary.white.standard};
          background-clip: padding-box;
          border: 2px solid transparent;
          border-radius: 0;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          font-family: 'source-sans-pro', sans-serif;
        }
      }

      .form-check {
        position: relative;
        display: block;
        padding-left: 1.25rem;
        margin-top: 1rem !important;

        .form-check-input {
          position: absolute;
          margin-top: 0.3rem;
          margin-left: -1.25rem;
          box-sizing: border-box;
          padding: 0;
        }

        .terms {
          font-family: 'source-sans-pro', sans-serif;
          font-size: 12px;
          letter-spacing: -0.3px;
          color: ${Colors.primary.white.standard} !important;
          margin-bottom: 0;
        }
      }

      label {
        color: ${Colors.primary.white.standard};
        margin-bottom: 0.5rem;
        font-size: 15px;
        font-family: 'source-sans-pro', sans-serif;
        font-weight: 400;
        line-height: 1.5;
      }

      .half-width-input {
        width: 48%;
      }

      .full-width-input {
        width: 100%;
      }

      #submitBtn {
        background-color: ${Colors.accent.cheesePuff.standard};
        color: ${Colors.primary.white.standard};
        margin-top: 1rem;
        font-size: 16px;
        border: none;
        padding: 16px 1.75rem;
        border-radius: 2px;
        transition: all 0.1s ease-in;
        box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
        margin-bottom: 1rem;
        width: 100%;
        text-transform: capitalize;

        @media (min-width: 768px) {
          min-width: 280px;
          width: auto;
        }
      }

      .has-error {
        border: 2px solid red !important;
      }

      .error-message {
        margin: 0;
        padding: 0.125rem 0.25rem;
        background-color: #e83641;
        color: ${Colors.primary.white.standard};
        transition: all 0.2s ease-in;
        -webkit-margin-after: 0;
        -webkit-margin-before: 0;
        width: 100%;
        box-sizing: border-box;
        font-size: 11px;
      }
    }
    .thank-you {
      min-height: 300px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 320px;

      .thank-you-text {
        color: ${Colors.primary.white.standard};
        text-align: center;
        margin-bottom: 2rem;
        font-size: 15px;
      }
    }

    a.button-link {
      font-size: 17px;
      background-color: ${Colors.accent.cheesePuff.standard};
      color: ${Colors.primary.white.standard} !important;
      border: none;
      padding: 0.75rem 1.75rem;
      border-radius: 2px;
      transition: all 100ms ease-in;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
      text-decoration: none;

      &:hover {
        background-color: darken(${Colors.accent.cheesePuff.standard}, 8%);
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
        cursor: pointer;
        color: ${Colors.primary.white.standard} !important;
      }

      &:disabled {
        opacity: 0.8;
      }
    }
  }
`;

export default FormStyles;
