import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import { Carousel } from 'react-responsive-carousel';
import LeftBgImage from './images/left-pattern.png';
import RightBgImage from './images/top-right-pattern.png';
import DoubleQuote from './images/double-quote.svg';
import StArrowLeftSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-left.svg';
import StArrowRightSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/st-arrow-right.svg';

import { TestimonialsStyles } from './Testimonials.styled';

const getTestimonials = (customerTestimonials, selectedIndex) => {
  // setTimeout(() => {
  //   document.querySelectorAll('.carousel-root')[0].tabIndex = -1;
  // }, 1);

  return customerTestimonials.map((testimonial, index) => {
    return (
      <div key={`testimonial-${index}`} className="testimonial">
        <img alt={testimonial.image.alt} src={testimonial.image.source} />
        <div className="testimonial-quote-container">
          <div className="testimonial-quote">
            <ReactSVG src={DoubleQuote} />
            <span className="testimonial-content standard intro">
              {testimonial.quote}
            </span>
            <span className="testimonial-divider"></span>
            <span className="testimonial-author caption">
              {testimonial.attribution}
            </span>
            <a
              tabIndex="-1"
              className="xsmall"
              href={testimonial.slug}
              target="_blank"
              rel="noopener"
            >
              Read
            </a>
          </div>
        </div>
      </div>
    );
  });
};

const Testimonials = ({ testimonials }) => {
  const history = useHistory();
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(testimonials.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextSlide = () => {
    if (currentSlide === testimonials.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const showArrows = imageArray && imageArray.length > 1;

  const imageArray = getTestimonials(testimonials, currentSlide);

  return (
    <TestimonialsStyles>
      <img alt="left-pattern" className="left-bg" src={LeftBgImage} />
      <img alt="right-pattern" className="right-bg" src={RightBgImage} />
      <div className="testimonials-container">
        <h2>From our customers</h2>
        <div className="testimonials">
          <div
            tabIndex="0"
            className="focus-grabber"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                history.push(testimonials[currentSlide].studioPostSlug);
              }
            }}
          >
            <Carousel
              showArrows={showArrows}
              showIndicators={false}
              showStatus={false}
              transitionTime={300}
              thumbWidth={130}
              infiniteLoop={true}
              tabIndex="-1"
              transitionTime={200}
              showThumbs={false}
              selectedItem={currentSlide}
              onChange={(index) => {
                setCurrentSlide(index);
              }}
            >
              {imageArray.map((image, i) => {
                if (image.source) {
                  return (
                    <div key={i}>
                      <img
                        src={
                          image.source +
                          (viewportWidth ? '?width=' + viewportWidth : '')
                        }
                        alt={image.alt ? image.alt : 'Detail Image'}
                      />
                    </div>
                  );
                } else {
                  return image;
                }
              })}
            </Carousel>
          </div>
          <div className="testimonial-controls">
            <Button
              className="gtm-testimonial-controls"
              buttonStyle="compact"
              type="button"
              aria-label="previous testimonial"
              onClick={prevSlide}
              automationId="testimaonialBtnOne"
            >
              <ReactSVG src={StArrowLeftSvg} />
            </Button>
            <span className="testimonial-indicator xsmall">
              {currentSlide + 1} / {testimonials.length}
            </span>
            <Button
              className="gtm-testimonial-controls"
              buttonStyle="compact"
              type="button"
              aria-label="next testimonial"
              onClick={nextSlide}
              automationId="testimaonialBtnTwo"
            >
              <ReactSVG src={StArrowRightSvg} />
            </Button>
          </div>
        </div>
      </div>
    </TestimonialsStyles>
  );
};

export default Testimonials;
