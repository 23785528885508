import styled from 'styled-components';
import Colors from '../../../colors';

const StyledAboutLocation = styled.section`
  padding: 3rem 0.5rem;
  background-color: #f5f5f5;

  @media (min-width: 768px) {
    padding: 3rem 0;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 1025px) {
      max-width: 1140px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 768px) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .about-title {
    font-family: 'acumin-pro', sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    color: ${Colors.accent.darkGrey.standard};
    margin-bottom: 1.5rem;
    letter-spacing: normal;
  }

  .about-text {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 400;

    p {
      font-family: 'source-sans-pro', sans-serif;
      font-size: 19px;
      letter-spacing: -0.3px;
      color: #6d6e71;
      line-height: 1.7;
    }

    p:first-of-type {
      margin-bottom: 1rem;
    }
  }
`;

export default StyledAboutLocation;
