import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import clock from '../../images/svg/iconography-16x16/clock.svg';
import arrowDown from '../../images/svg/iconography-16x16/st-arrow-down.svg';
import { fireSegmentEvent } from '../../utils';
import { modelNameTransformer } from '../Shared/SmokeyTransitional/utils/modelNameTransformer';
import { smoothScroll } from '../Shared/SmokeyTransitional/utils/utils';
import NavbarStyles from './Navbar.styled';

const Navbar = ({
  sticky,
  homeObject,
  onRequestClick,
  onShowScheduleClick,
  brochureLink,
  dealer,
  homes,
}) => (
  <NavbarStyles>
    <div className={sticky ? 'navbar navbar-sticky portal-sticky' : 'navbar'}>
      <div className="left-items-container">
        {homeObject && homeObject.description && (
          <h2 className="home-title">
            {modelNameTransformer(homeObject.description)}
          </h2>
        )}

        <div className="spacer"></div>
        <div className="menu-items">
          {homeObject && homeObject.lineDescription && (
            <span
              onClick={() => {
                smoothScroll('.home-details-overview', -90);
              }}
            >
              OVERVIEW
            </span>
          )}
          {homeObject &&
            (homeObject.modelFeatures.length > 0 ||
              homeObject.inventoryFeatures.length > 0) && (
              <span
                onClick={() => {
                  smoothScroll('.home-details-features', -90);
                }}
              >
                FEATURES
              </span>
            )}
          <span
            onClick={() => {
              smoothScroll('.home-details-floorplan', -90);
            }}
          >
            FLOOR PLAN
          </span>
        </div>
      </div>
      <div className="right-items-container">
        <Link
          rel="noopener"
          className="button white-button brochure-button ga-brochure-sticky-nav"
          target="_blank"
          to={{
            pathname: brochureLink,
            state: { dealer, homes },
          }}
          onClick={() => {
            fireSegmentEvent('CTA Clicked', {
              eventContext: 'home_details_sticky_nav_brochure',
              value: brochureLink ? brochureLink : '',
            });
          }}
        >
          <img src={arrowDown} />
          BROCHURE
        </Link>
        <Button
          onButtonClick={onShowScheduleClick}
          className="button white-button ga-schedule-sticky-nav"
          automationId="navBarButtonOne"
        >
          <img src={clock} />
          SCHEDULE A TOUR
        </Button>
        <Button
          className="ga-home-details-request-info-sticky-nav"
          onButtonClick={onRequestClick}
          automationId="navBarButtonTwo"
        >
          REQUEST INFO
        </Button>
      </div>
    </div>
  </NavbarStyles>
);
export default Navbar;
