import React from 'react';
import Form from './Form';
import { StyledBanner } from './Banner.Style';
import useWindowSize from '../../hooks/useWindowSize';

const Banner = ({
  showThankYouCallBack,
  isClayton,
  dealer,
  bannerHeadline,
  bannerDisclaimer,
  formOfferText,
  formLogo,
}) => {
  const [width, height] = useWindowSize();
  return (
    <StyledBanner>
      <div className="banner">
        {bannerHeadline && <h3>{bannerHeadline}</h3>}
        {bannerDisclaimer && <p className="disclaimer">{bannerDisclaimer}</p>}
      </div>
      <Form
        formOfferText={formOfferText}
        formLogo={formLogo}
        showThankYouCallBack={showThankYouCallBack}
        isClayton={isClayton}
        dealer={dealer}
      />
    </StyledBanner>
  );
};

export default Banner;
