import styled from 'styled-components';
import Colors from '../../../colors';

const StyledCityBlurb = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .title {
    font-family: 'acumin-pro', sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.27;
    color: ${Colors.accent.darkGrey.standard};
    margin-bottom: 1.5rem;
    text-align: left;
    letter-spacing: normal;

    @media (min-width: 768px) {
      font-size: 26px;
      line-height: 1.4;
    }
  }

  .description {
    margin-top: 0;
    margin-bottom: 1rem;

    p {
      font-family: 'source-sans-pro', sans-serif;
      letter-spacing: -0.3px;
      color: #6d6e71;
      line-height: 1.7;
      font-size: 1.125rem;
    }
  }
`;

export default StyledCityBlurb;
