import React from 'react';
import StyledHomeCard from './HomeCard.styled';
import InfoCircleIcon from '../../../images/svg/iconography-16x16/info-circle.svg';

const HomeCard = ({
  home,
  toTitleCase,
  handleHomeClick,
  handlePricingClick,
}) => {
  const homePrice = home.startingInThePrices.sort(
    (a, b) => b.startingInThePrice - a.startingInThePrice
  )[0].startingInThePrice;

  return (
    <StyledHomeCard>
      <div
        className="home-card-banner"
        style={{ background: `url(${home.thumbnailReference})` }}
      />
      <div className="home-card-content">
        <h3 className="model-name">{toTitleCase(home.modelDescription)}</h3>
        <p className="model-price">
          ${homePrice.toLocaleString()}s BEFORE OPTIONS
          <a onClick={() => handlePricingClick()} className="svg-container">
            <img src={InfoCircleIcon} alt="pricing info" />
          </a>
        </p>
        <p className="model-specs">
          {home.squareFeet} sq ft &bull; {home.beds} beds &bull; {home.baths}{' '}
          baths
        </p>
        <a
          className="info-button"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleHomeClick(home);
          }}
        >
          Request Info
        </a>
      </div>
    </StyledHomeCard>
  );
};

export default HomeCard;
