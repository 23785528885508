import styled from 'styled-components';
import Colors from '../../../colors';

const StyledWhyBuySection = styled.section`
  width: 100%;
  padding: 0 0 4rem;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 1025px) {
    max-width: 1140px;
  }

  .why-buy-header {
    font-family: 'acumin-pro', sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    color: ${Colors.accent.darkGrey.standard};
    margin-bottom: 3rem;
    text-align: center;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 1025px) {
      max-width: 1140px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .why-buy-section {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    .svg-container {
      width: 100%;
      height: auto;
      position: relative;
      margin-left: 2%;

      svg {
        margin: 0 auto 1.5rem;
        display: block;
        height: 138px;
      }
    }

    .why-buy-section-header {
      font-family: 'acumin-pro', sans-serif;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: ${Colors.accent.darkGrey.standard};
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    .why-buy-section-content {
      font-family: 'source-sans-pro', sans-serif;
      font-size: 19px;
      line-height: 1.5;
      letter-spacing: -0.3px;
      color: #6d6e71;
    }
  }

  #saveMoneyIcon {
    width: 138px;

    .cls-1 {
      fill: #e6e6e6;
    }

    .cls-2 {
      fill: ${Colors.primary.white.standard};
    }

    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5 {
      stroke: ${Colors.accent.darkGrey.standard};
      strokelinecap: round;
      strokelinejoin: round;
    }

    .cls-3 {
      fill: ${Colors.primary.claytonBlue.standard};
    }

    .cls-4 {
      fill: none;
    }

    .cls-5 {
      fill: ${Colors.secondary.rubberDucky.standard};
    }
  }

  #qualityToolsIcon {
    width: 138px;

    .cls-1 {
      fill: #e6e6e6;
    }

    .cls-2 {
      fill: ${Colors.primary.white.standard};
    }

    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5 {
      stroke: ${Colors.accent.darkGrey.standard};
      strokelinecap: round;
      strokelinejoin: round;
    }

    .cls-3 {
      fill: none;
    }

    .cls-4 {
      fill: ${Colors.primary.claytonBlue.standard};
    }

    .cls-5 {
      fill: ${Colors.secondary.rubberDucky.standard};
    }
  }

  #customerServiceIcon {
    max-width: 138px;

    .cls-1 {
      fill: #e6e6e6;
    }

    .cls-2 {
      fill: ${Colors.primary.white.standard};
    }

    .cls-2,
    .cls-3,
    .cls-4 {
      stroke: ${Colors.accent.darkGrey.standard};
      strokelinecap: round;
      strokelinejoin: round;
    }

    .cls-3 {
      fill: ${Colors.primary.claytonBlue.standard};
    }

    .cls-4 {
      fill: ${Colors.secondary.rubberDucky.standard};
    }
  }
`;

export default StyledWhyBuySection;
