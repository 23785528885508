import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Color from '../../../colors';

const LargeCTAStyles = styled.div`
  .cta-container {
    position: relative;
    max-width: 1250px;
    margin: 0 auto;
    width: 90%;
    @media only screen and (max-width: 768px) {
      max-width: ${(props) =>
        props.absoluteLogoPositionRight ? '90%' : '512px'};
    }
  }
  picture {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    img {
      object-fit: cover;
      height: auto;
      width: 100% !important;
      object-position: left;
    }
  }
  .logo {
    margin-bottom: 20px;
    max-width: 100%;
  }
  .purple-background {
    background: ${Colors.secondary.summerNight.standard};
    .cta-button {
      color: ${Colors.secondary.summerNight.standard};
    }
  }
  .navy-background {
    background: #021f66;
    .cta-button {
      color: #021f66;
    }
  }
  .red-background {
    background: #e41d3a;
    .cta-button {
      color: #e41d3a;
    }
  }
  .teal-background {
    background: #4495a7;
    .cta-button {
      color: #06292f !important;
      background: #fdba19 !important;
    }
  }
  .blue-background {
    background: ${Colors.primary.claytonBlue.standard};
    .cta-button {
      color: ${Colors.primary.claytonBlue.standard};
    }
  }
  .large-cta {
    position: relative;
    padding: 48px 44px;
    border: 0px solid;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: ${(props) =>
      props.backgroundPosition ? props.backgroundPosition : 'center right'};
    background-size: cover;
    overflow: hidden;
    width: 100%;
    display: flex;
    @media only screen and (min-width: 1100px) {
      min-height: 250px;
    }
    @media only screen and (max-width: 1099px) {
      background-image: none !important;
      padding: 40px 25px;
    }
    .content {
      position: relative;
      max-width: 385px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 2;
      @media only screen and (max-width: 1099px) {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 auto;
      }
    }
    .small-text {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 12px;
      line-height: 1;
      margin-bottom: 12px;
      letter-spacing: 0.5px;
      font-family: 'source-sans-pro';
    }
    .large-text {
      color: white;
      line-height: 38px;
      margin-bottom: 28px;
      @media only screen and (max-width: 1100px) {
        line-height: 1.1;
        margin-bottom: 20px;
      }
    }
    .cta-button {
      background: ${Colors.primary.white.standard};
      border-radius: 2px;
      padding: 17px 17px;
      font: 500 12px/100% 'source-sans-pro', sans-serif;
      margin-top: 9px;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .absolute-logo-wrap {
    display: flex;
    justify-content: ${(props) =>
      props.absoluteLogoPositionRight ? 'flex-end' : 'left'};
    /* padding-right: ${(props) =>
      props.absoluteLogoPositionRight ? '100px' : '0px'}; */
    flex: 1;
    @media only screen and (max-width: 1099px) {
      display: none;
    }
  }
  .have-it-made-background {
    @media only screen and (max-width: 1099px) {
      background: ${Colors.accent.bedtime.standard};
    }
    .small-text {
      color: ${Colors.primary.white.standard} !important;
      @media only screen and (min-width: 1100px) {
        display: none;
      }
    }
    .large-text {
      /* @media only screen and (min-width: 1100px) { */
      color: ${Colors.primary.white.standard} !important;
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      /* } */
    }
    .cta-button {
      color: ${Colors.accent.bedtime.standard} !important;
      background: ${Colors.accent.hoseWater.standard} !important;
    }
  }

  .blue-background {
    .absolute-logo-wrap {
      display: flex;
      -webkit-box-pack: end;
      justify-content: flex-end;
      flex: 1 1 0%;
      position: relative;
      @media only screen and (max-width: 1099px) {
        display: none;
      }
      img {
        max-width: 494px;
        height: auto;
        position: absolute;
        right: 0;
        max-height: 100%;
      }
    }
  }

  .inventory-clearance-event-background {
    padding: 34px 18px 34px 48px;
    @media only screen and (max-width: 1099px) {
      background: ${Color.accent.darkClearanceRed.standard};
      padding: 35px 48px;
    }
    .small-text {
      color: ${Colors.primary.white.standard} !important;
    }
    .content {
      max-width: 500px !important;
    }
    .large-text {
      color: ${Colors.primary.white.standard} !important;
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      letter-spacing: -0.3px;
      margin-bottom: 12px;
      @media only screen and (max-width: 1100px) {
        font-size: 26px;
        margin-bottom: 19px;
      }
    }
    .cta-button {
      color: ${Color.accent.darkClearanceRed.standard} !important;
      background:${Colors.primary.white.standard} !important;
      font-size: 14px;
      margin-top: 18px;
    }
    .absolute-logo-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex-grow: 1;
      z-index: 5;
      margin-top: 27px;
      @media only screen and (max-width: 1099px) {
        display: none;
      }
      img {
        height: auto;
        position: absolute;
        right: 18px;
        top: 21px;
        }
      }
    }
    .logo-text {
      color: ${Colors.primary.white.standard};
      font-size: 28px;
      font-weight: 400;
      text-align: right;
      line-height: 1;
      letter-spacing: -0.35px;
      padding-right: 5px;
    }
    .bold {
      font-weight: 900;
    }
    .disclaimer {
      color: ${Colors.primary.white.standard};
      font-size: 12px;
      font-weight: 900;
      @media only screen and (max-width: 1300px) {
      max-width: 400px;
      }
    }
  }
  .large-cta.ebuilt-summer-promo-background{
      display: flex;
      justify-content: flex-end;
      padding: 35px 52px;
      background: linear-gradient(180deg, #0075C9 32.81%, #00519A 100%) !important;

      .content{
        max-width: 750px !important;
        @media only screen and (max-width: 1199px) {
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 0 auto;
        }
      }

      .large-text{
        font-weight: 400;
        margin-bottom: 28px;
        max-width: 310px; 
        @media only screen and (min-width: 768px) {
          max-width: 400px;
        }
        @media only screen and (min-width: 1200px) {
          color: ${Color.accent.eBuiltprimaryBlue.standard};
          text-shadow: 0px 4px 4px #48484833;
          font-size: 50px;
          line-height: 50px;
          text-align: left;
          align-self: flex-end;
          .bold, span{
            color: #1B468D;
          }
        }
        @media only screen and (min-width: 1335px) {
          font-size: 65px;
          line-height: 60px;
          max-width: 600px;
        }
      }
      .disclaimer-container {
        color: ${Colors.primary.white.standard};
        @media only screen and (min-width: 1200px) {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: auto;
          gap: 40px;
        }
        @media only screen and (min-width: 1300px) {
          gap: 60px;
          max-width: 
        }
      }
    
      .cta-button{
        color: #00519A;
        display: inline-flex;
        @media only screen and (min-width: 1200px) {
          background: ${Color.accent.eBuiltprimaryBlue.standard};
          color: ${Colors.primary.white.standard};
        }
      }
      a{
        color: ${Colors.primary.white.standard};
      }

      @media only screen and (min-width: 1200px) {
        padding: 27px 30px 17px 0;
        background: none !important;
      }
      span{
        font-weight: 700;
      }
    }
`;

export default LargeCTAStyles;
