import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { getNewsAndEvents } from '../../Api.js';
import App from '../../App';
import staticTestimonials from '../../content/staticTestimonials';
import useSalesAgents from '../../hooks/useSalesAgents';
import AboutUsHeaderImage from '../../images/about-us-header-image.jpg';
import AboutUsTextImage from '../../images/about-us-text-image.jpg';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import { findMetaDescription, getFOBShortName } from '../../utils/utils';
import FeaturedEvent from '../Shared/FeaturedEvent/FeaturedEvent';
import HeaderWithHero from '../Shared/HeaderWithHero/HeaderWithHero';
import HouseSmartCTA from '../Shared/HouseSmartCTA/HouseSmartCTA';
import MeetOurTeam from '../Shared/MeetOurTeam/MeetOurTeam';
import Testimonials from '../Shared/Testimonials/Testimonials';
import TextWithImage from '../Shared/TextWithImage/TextWithImage';
import AboutStyles from './About.styled';
import AboutUsStructuredData from './AboutUsStucturedData';

const About = ({ dealer, homes, isLoading, featuredEvent }) => {
  const { salesAgents } = useSalesAgents(dealer.dealerId);
  const customPageMetaDescription = findMetaDescription(
    dealer?.websiteMetaDescriptions,
    'about-us'
  );

  if (dealer?.websiteTemplateDescription === 'BuilderModel') {
    return <Redirect to="/" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <AboutStyles>
        <Helmet>
          <title>About Us | {formatDealerName(dealer?.dealerName)}</title>
          <meta
            name="description"
            content={customPageMetaDescription?.metaDescription}
          />
          <meta
            name="og:description"
            content={customPageMetaDescription?.metaDescription}
          />
        </Helmet>
        <AboutUsStructuredData
          dealer={dealer}
          homes={homes}
          salesAgents={salesAgents}
          description={customPageMetaDescription?.metaDescription}
        />
        <HeaderWithHero
          smallHeadline={`About Us`}
          largeHeadline={`At ${getFOBShortName(
            dealer.brandAcronym
          )}, we're building homes a different way.`}
          containerWidth={`825px`}
          backgroundPosition={`66% 0%`}
          lowerTextOnMobile={true}
          hideDisclaimer={true}
          image={{
            source: AboutUsHeaderImage,
            alt: 'About Us hero image',
          }}
        />

        <TextWithImage
          smallHeadline={'Why Choose Us'}
          largeHeadline={'Opening doors to a better life.'}
          content={`At ${formatDealerName(
            dealer?.dealerName
          )} we are ready to help you find your dream home. We are here to help answer all your questions, from floor plans to design features. Check out our fully furnished new home models and see all the options available to create the perfect home. With many manufactured, mobile and modular home floor plans to choose from, we are certain we can help you find the home you’ve been looking for.`}
          image={{
            source: AboutUsTextImage,
            alt: 'family playing in living room',
          }}
          overlapImage={true}
          headlineWidth={`300px`}
        />
        {dealer?.websiteTemplateDescription !==
          'NoSpecialOffersNoEnergySmart' && (
          <HouseSmartCTA
            gaClass={'ga-about-us-housesmart-cta'}
            retailEventLabel={'about_us_housesmart'}
          />
        )}
        <Testimonials testimonials={staticTestimonials} />
        <MeetOurTeam
          dealer={dealer}
          homes={homes}
          gaClass="ga-meet-our-team"
          hideButton={true}
          gaPhoneClass="ga-about-us-meet-team-phone"
          gaEmailClass="ga-about-us-meet-team-email"
        />
        {featuredEvent && (
          <FeaturedEvent
            buttonText={'See Details'}
            smallHeadline={'Upcoming Events'}
            featuredEvent={featuredEvent}
          />
        )}
        <section className="spacer"></section>
      </AboutStyles>
    </App>
  );
};

About.getInitialProps = async (ctx) => {
  let featuredEvent;

  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  try {
    const result = await getNewsAndEvents(dealer.dealerId);
    featuredEvent = result.filter((event) => event.isFeatured)[0];
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, featuredEvent };
};

export default About;
