import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextWithVideoStyles from './TextWithVideo.styled';
import videoGraphic from '../../../images/svg/text-with-video-bg.svg';
import triangles from '../../../images/svg/triangles.svg';
import PlayBtn from '../../../images/svg/icons/PlayBtn';
import Modal from '../../Shared/SmokeyTransitional/components/Modal/Modal';
import WistiaVideoEmbed from '../SmokeyTransitional/components/WistiaVideoEmbed/WistiaVideoEmbed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const TextWithVideo = ({
  smallHeadline,
  largeHeadline,
  content,
  features,
  videoImage,
  wistiaID,
}) => {
  const [ModalShowing, setModalShowing] = useState(false);

  return (
    <TextWithVideoStyles>
      <div className="triangle-box">
        <LazyLoadImage src={triangles} alt="triangle" />
      </div>
      <div className="container">
        <div className="video-wrap" onClick={() => setModalShowing(true)}>
          <LazyLoadImage
            className="video-graphic"
            src={videoGraphic}
            alt="crossmod video graphic"
          />
          <div className="video-box ga-crossmod-video">
            <LazyLoadImage
              alt={videoImage.alt}
              effect="blur"
              src={videoImage.source}
            />
            <PlayBtn />
          </div>
        </div>
        <div className="content-wrap">
          <div className="content">
            {largeHeadline && (
              <h2 className="large-headline">{largeHeadline}</h2>
            )}
            {content && <p className="content">{content}</p>}
            {features && (
              <ul className="feature-list">
                {features.map((feature, i) => (
                  <li key={`${i}-feature`}>
                    {feature.icon && (
                      <img
                        loading="lazy"
                        src={feature.icon}
                        alt={feature.text + ' icon'}
                      />
                    )}
                    <p>{feature.text}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {ModalShowing && (
          <Modal onClose={() => setModalShowing(false)}>
            <div className="video-embed-wrap">
              <WistiaVideoEmbed
                wistiaID={wistiaID}
                wistiaOptions="silentAutoPlay=false endVideoBehavior=loop autoPlay=true"
              />
            </div>
          </Modal>
        )}
      </div>
    </TextWithVideoStyles>
  );
};

TextWithVideo.props = {
  smallHeadline: PropTypes.string,
  largeHeadline: PropTypes.string,
  content: PropTypes.string,
  wistiaID: PropTypes.string,
  features: PropTypes.array,
  videoImage: PropTypes.string,
};

export default TextWithVideo;
