const crossmodContentfulSample = [
  {
    headerWithImage: {
      smallHeadline: 'Housing Innovation',
      largeHeadline: 'CrossMod' + String.fromCharCode(8482),
      content: 'A new, stylish and innovative category of homes',
      image: {
        source: require('../images/webp/crossmodimage-opt.webp'),
        alt: 'CrossMod house and yard',
      },
    },
    textWithImage: {
      smallHeadline: 'Attainable Housing',
      largeHeadline: 'About CrossMod' + String.fromCharCode(8482),
      content:
        'We believe that everyone deserves a chance to achieve the dream of homeownership, which is why we helped develop a new category of homes called CrossMod™. This new category of off-site built homes is an industry movement to offer a more attainable alternative to site-built homes for families across America.',
      citation:
        'CrossMod™ is a trademark of the Manufactured Housing Institute',
      image: {
        source: require('../images/webp/crossmod-house-opt.webp'),
        alt: 'CrossMod house and porch with kids',
      },
    },
    textWithVideo: {
      largeHeadline: 'CrossMod' + String.fromCharCode(8482) + ' Features',
      content:
        'The CrossMod™ class of homes offers features typically associated with site-built housing.',
      features: [
        {
          icon: require('../images/svg/crossmod-features/permanent-foundation.svg'),
          text: 'Permanent Foundation',
        },
        {
          icon: require('../images/svg/crossmod-features/elevated-roof-pitch.svg'),
          text: 'Elevated Roof Pitch',
        },
        {
          icon: require('../images/svg/crossmod-features/covered-porch.svg'),
          text: 'Covered Porch/ Garage/ Carport',
        },
        {
          icon: require('../images/svg/crossmod-features/drywall-interiors.svg'),
          text: 'Drywall Interiors',
        },
        {
          icon: require('../images/svg/crossmod-features/enhanced-cabinets.svg'),
          text: 'Enhanced Cabinets',
        },

        {
          icon: require('../images/svg/crossmod-features/energy-efficient-standards.svg'),
          text: 'Energy Efficient Standards',
        },
      ],
      videoImage: {
        source: require('../images/webp/crossmodvideoimage.webp'),
        alt: 'CrossMod graphic',
      },
      wistiaID: '07ndlrjxmp',
    },
    textContent: {
      smallHeadline: 'Quality and Innovation',
      largeHeadline: 'Closing the Gap',
      content:
        'CrossMod™ is bridging the gap between off-site built homes and on-site built homes by combining the features of both. With CrossMod™ you get a quality home built indoors in a home building facility, with the potential for it to appraise similarly to an on-site built home, creating the opportunity to increase your property value over time.',
    },
    threeUp: {
      items: [
        {
          image: {
            source: require('../images/svg/BuiltSmart-Icon.svg'),
            alt: 'BuiltSmart graphic',
          },
          content:
            'For the first time in industry history, CrossMod™ homes are combining the best features of off-site built homes with aesthetics and features typically seen in site-built housing, making homeownership more attainable.',
        },
        {
          image: {
            source: require('../images/svg/DesignSmart-Icon.svg'),
            alt: 'DesignSmart logo',
          },
          content:
            'The unique features of the CrossMod™ design allows the homes to be appraised like site-built homes. \n* Permanent foundation  \n* Elevated roof pitch \n*All drywall interiors \n*Porch, garage or carport',
        },
        {
          image: {
            source: require('../images/svg/BudgetSmart-Icon.svg'),
            alt: 'BudgetSmart logo',
          },
          content: `With the construction and aesthetic requirements of CrossMod™ homes, homeowners have the potential to qualify for more traditional financing options. CrossMod™ homes also can appraise similarly to traditional on-site built housing, giving the possibility of increased property value over time.`,
        },
      ],
    },
    crossModCTA: {
      headline: 'Find Out More',
      content:
        'To learn more about special mortgage loan financing programs available for CrossMod™ homes, visit the links below.',
      buttons: [
        {
          buttonURL:
            'https://singlefamily.fanniemae.com/originating-underwriting/mortgage-products/manufactured-housing-financing',
          buttonText: 'Fannie Mae®',
          gaClass: 'ga-crossmod-fannie-mae',
          retailEventLabel: 'crossmod_financing_fannie_mae',
          target: '_blank',
        },
        {
          buttonURL:
            'https://sf.freddiemac.com/working-with-us/origination-underwriting/mortgage-products/choicehome-mortgages',
          buttonText: 'Freddie Mac®',
          gaClass: 'ga-crossmod-freddie-mac',
          retailEventLabel: 'crossmod_financing_freddie_mac',
          target: '_blank',
        },
      ],
      fineprint:
        'Freddie Mac® is a registered trademark of Federal Home Loan Mortgage Corporation. \n \nFannie Mae® is a registered trademark of Federal National Mortgage Association Corporation',
    },
  },
];

export default crossmodContentfulSample;
