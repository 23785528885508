import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import VideoBackgroundPromo from '../../../images/promo-images/ebuilt-promo-video-background.png';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const VideoStyles = styled.section`
  position: relative;
  padding: 0px;
  margin: 0;

  @media screen and (min-width: 1025px) {
    margin: ${(props) => (props.noSaleHomes ? '0 0 50px' : '0')};
  }

  &.promo {
    @media screen and (min-width: 1025px) {
      background: url(${VideoBackgroundPromo}) top center/cover no-repeat;
      height: 950px;
      width: 100%;
      padding-top: 94px;
    }

    @media screen and (min-width: 1450px) {
      height: 1100px;
      padding-top: 135px;
    }

    @media screen and (min-width: 1600px) {
      height: 1180px;
    }

    @media screen and (min-width: 1800px) {
      height: 1270px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 85%;
    width: 100%;
    display: block;
  }
  .no-pointer {
    pointer-events: none;
  }
  .inner {
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;

    @media screen and (max-width: 1439px) {
      top: 0;
      min-height: 398px;
      max-height: 520px;
      height: 51vw;
    }

    @media only screen and (min-width: 1440px) {
      max-width: 70%;
      aspect-ratio: 16/9;
    }

    cursor: pointer;
    .show-modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      cursor: pointer;
    }
    .content-wrap {
      position: relative;
      z-index: 5;
      text-align: center;
    }
    .small-headline,
    .large-headline {
      color: white;
    }
    .small-headline {
      font-family: 'source-sans-pro';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
    }
    .large-headline {
      margin-bottom: 17px;
      font-size: 40px;
      line-height: 48px;
      font-family: 'acumin-pro';
      font-weight: 700;
      @media only screen and (max-width: 553px) {
        font-size: 34px;
        line-height: 40px;
      }
    }
    .image-box {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: ${Colors.accent.grey5.standard};
      z-index: 1;

      span,
      img {
        height: 100%;
        width: 100%;
      }
      img {
        object-position: center;
        object-fit: cover;
      }
    }
    .mobile-image-box {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: ${Colors.accent.grey5.standard};
      z-index: 1;
      display: none;

      @media only screen and (max-width: 767px) {
        display: block;
      }
      span,
      img {
        height: 100%;
        width: 100%;
      }
      img {
        object-position: center;
        object-fit: cover;
      }
    }
  }
  .video-embed-wrap {
    width: 95vw;
    max-width: 1350px;
  }
  .wistia_embed {
    height: 360px;
    width: 100%;
    position: relative;
    width: 640px;
  }
`;

export default VideoStyles;
