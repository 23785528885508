import { useEffect, useReducer, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PRICE_INCREMENT } from '../utils/config';

const homesReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_HOMES_INIT': {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case 'FETCH_HOMES_SUCCESS': {
      return {
        ...state,
        homes: action.payload,
        isLoading: false,
        isError: false,
      };
    }
    case 'FETCH_HOMES_FAILURE': {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    }
    case 'LOAD_MORE_HOMES': {
      return {
        ...state,
        homesOffset: state.homesOffset + action.payload,
      };
    }

    case 'SET_FILTERS': {
      return {
        ...state,
        filters: {
          ...action.payload.filters,
        },
        defaultFilterValues: { ...action.payload.defaultFilters },
      };
    }
    case 'UPDATE_FILTERS': {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    }
    case 'CLEAR_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload]:
            state.defaultFilterValues[action.payload] instanceof Object
              ? {
                  ...state.defaultFilterValues[action.payload],
                }
              : state.defaultFilterValues[action.payload],
        },
      };
    }
    case 'SET_SORT_ORDER': {
      return {
        ...state,
        sortOrder: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const initialState = {
  homesOffset: 10,
  defaultFilterValues: {
    beds: {
      min: 0,
      max: 0,
      lowerLimit: 0,
      higherLimit: 0,
      isActive: false,
      label: 'Beds',
    },
    baths: {
      min: 0,
      max: 0,
      higherLimit: 0,
      lowerLimit: 0,
      isActive: false,
      label: 'Baths',
    },
    squareFeet: {
      min: 0,
      max: 0,
      higherLimit: 0,
      lowerLimit: 0,
      isActive: false,
      label: 'Sq. Ft.',
    },
    acres: {
      min: 0,
      max: 0,
      higherLimit: 0,
      lowerLimit: 0,
      isActive: false,
      label: 'Acres',
    },
    priceSort: {
      min: 0,
      max: 0,
      higherLimit: 0,
      lowerLimit: 0,
      isActive: false,
      label: 'Price',
    },
    features: {
      selected: [],
      available: [],
      isActive: false,
      label: 'Features',
    },
    isLand: null,
    isOnLand: null,
    isInStock: null,
    onlyModels: false,
    isMultiSection: null,
    isSingleSection: null,
    isCrossMod: null,
    text: '',
  },
  filters: null,
  isLoading: false,
  isError: false,
  error: undefined,
};

const getFiltersWithAdjustedLimits = (homes, filterValues, isLandPage) => {
  // called when homes load - given loaded homes
  // (for highest and lowest values in given set of data)
  // and initial filter values, create filter object with correct pre-filled values
  //
  // for example: we get list of homes with min price 25,000 and max price 247,433
  // and our biz rules want us to allow user to only select price filter values
  // at 25000 increments
  // we will want to set the filter to start at the nearest 25000 increment below and above
  // the min and max prices in our current list of homes
  // so in this example we should end up with lower limit of 0 and  higher limit of 250,000

  const limits = getFilterLimits(homes);

  const calculateLowestPrice = (value) => {
    const dividend = value / PRICE_INCREMENT;

    const lowestPriceIncrement = Math.floor(dividend) * PRICE_INCREMENT;

    return lowestPriceIncrement;
  };

  const res = {
    ...filterValues,
    priceSort: {
      higherLimit:
        Math.ceil(limits.priceSort.max / PRICE_INCREMENT) * PRICE_INCREMENT,
      lowerLimit: calculateLowestPrice(limits.priceSort.min),
      max:
        filterValues.priceSort.max ||
        Math.ceil(limits.priceSort.max / PRICE_INCREMENT) * PRICE_INCREMENT,
      min:
        filterValues.priceSort.min ||
        calculateLowestPrice(limits.priceSort.min),
      isActive: false,
    },
    beds: {
      higherLimit: limits.beds.max,
      lowerLimit: limits.beds.min,
      max: filterValues.beds.max || limits.beds.max,
      min: filterValues.beds.min || limits.beds.min,
      isActive: false,
    },
    baths: {
      higherLimit: limits.baths.max,
      lowerLimit: limits.baths.min,
      max: filterValues.baths.max || limits.baths.max,
      min: filterValues.baths.min || limits.baths.min,
      isActive: false,
    },
    acres: {
      higherLimit: limits.acres.max,
      lowerLimit: limits.acres.min,
      max: filterValues.acres.max || limits.acres.max,
      min: filterValues.acres.min || limits.acres.min,
      isActive: false,
    },
    squareFeet: {
      higherLimit: Math.ceil(limits.squareFeet.max / 100) * 100,
      lowerLimit: Math.floor(limits.squareFeet.min / 100) * 100,
      max:
        filterValues.squareFeet.max ||
        Math.ceil(limits.squareFeet.max / 100) * 100,
      min:
        filterValues.squareFeet.min ||
        Math.floor(limits.squareFeet.min / 100) * 100,
      isActive: false,
    },
    features: {
      selected:
        filterValues.features.selected.length > 0
          ? filterValues.features.selected
          : [],
      available: limits.features.available.sort((a, b) => a.localeCompare(b)),
      isActive: false,
    },
  };

  return res;
};

const getUrlParamValues = (filtersWithAdjustedLimits, params) => {
  const filterWithParams = filtersWithAdjustedLimits;

  if (params.get('features')) {
    let featuresArray = params.get('features').split(',');
    filterWithParams.features.selected = featuresArray;
  }
  if (params.get('isOnLand')) {
    filterWithParams.isOnLand = params.get('isOnLand') == 'true' ? true : null;
  }
  if (params.get('isInStock')) {
    filterWithParams.isInStock =
      params.get('isInStock') == 'true' ? true : null;
  }
  if (params.get('onlyModels')) {
    filterWithParams.onlyModels =
      params.get('onlyModels') == 'true' ? true : null;
  }
  if (params.get('isMultiSection')) {
    filterWithParams.isMultiSection =
      params.get('isMultiSection') == 'true' ? true : null;
  }
  if (params.get('isCrossMod')) {
    filterWithParams.isCrossMod =
      params.get('isCrossMod') == 'true' ? true : null;
  }
  if (params.get('isSingleSection')) {
    filterWithParams.isSingleSection =
      params.get('isSingleSection') == 'true' ? true : null;
  }
  if (params.get('name')) {
    filterWithParams.text = params.get('name');
  }
  if (params.get('minBeds')) {
    filterWithParams.beds.min = parseInt(params.get('minBeds'));
  }
  if (params.get('maxBeds')) {
    filterWithParams.beds.max = parseInt(params.get('maxBeds'));
  }
  if (params.get('minBaths')) {
    filterWithParams.baths.min = parseInt(params.get('minBaths'));
  }
  if (params.get('maxBaths')) {
    filterWithParams.baths.max = parseInt(params.get('maxBaths'));
  }
  if (params.get('minSquareFeet')) {
    filterWithParams.squareFeet.min = parseInt(params.get('minSquareFeet'));
  }
  if (params.get('maxSquareFeet')) {
    filterWithParams.squareFeet.max = parseInt(params.get('maxSquareFeet'));
  }
  if (params.get('minAcres')) {
    filterWithParams.acres.min = parseFloat(params.get('minAcres'));
  }
  if (params.get('maxAcres')) {
    filterWithParams.acres.max = parseFloat(params.get('maxAcres'));
  }
  if (params.get('minPrice')) {
    filterWithParams.priceSort.min = parseInt(params.get('minPrice'));
  }
  if (params.get('maxPrice')) {
    filterWithParams.priceSort.max = parseInt(params.get('maxPrice'));
  }

  return filterWithParams;
};

const getFilterLimits = (homes) => {
  // scan loaded homes for min and max values available and return bounds in object
  return homes.reduce(
    (acc, cur) => {
      // Square Feet
      if (cur.squareFeet) {
        if (acc.squareFeet.min > cur.squareFeet) {
          acc.squareFeet.min = cur.squareFeet;
        }
        if (acc.squareFeet.max < cur.squareFeet) {
          acc.squareFeet.max = cur.squareFeet;
        }
      } else {
        if (cur.squareFeet === null) {
          acc.squareFeet.min = 0;
        }
      }
      // Acres
      if (cur.acres !== null) {
        if (acc.acres.min > cur.acres) {
          acc.acres.min = cur.acres;
        }
        if (acc.acres.max < cur.acres) {
          acc.acres.max = cur.acres;
        }
      }
      // Price
      if (cur.priceSort !== null) {
        if (acc.priceSort.min > cur.priceSort) {
          acc.priceSort.min =
            cur.priceSort < PRICE_INCREMENT ? 0 : cur.priceSort;
        }
        if (acc.priceSort.max < cur.priceSort) {
          acc.priceSort.max = cur.priceSort;
        }
      }
      // Beds
      if (cur.beds) {
        if (acc.beds.min > cur.beds) {
          acc.beds.min = cur.beds;
        }
        if (acc.beds.max < cur.beds) {
          acc.beds.max = cur.beds;
        }
      } else {
        if (cur.beds === null) {
          acc.beds.min = 0;
        }
      }
      // Baths
      if (cur.baths) {
        if (acc.baths.min > cur.baths) {
          acc.baths.min = cur.baths;
        }
        if (acc.baths.max < cur.baths) {
          acc.baths.max = cur.baths;
        }
      } else {
        if (cur.baths === null) {
          acc.baths.min = 0;
        }
      }
      // Features
      if (cur.modelFeatures) {
        acc.features.available = [
          ...new Set([
            ...acc.features.available,
            ...cur.modelFeatures.map((featureName) => featureName),
          ]),
        ];
      }

      return acc;
    },
    {
      // Start with absurdly high values
      acres: { min: 100000000, max: 0 },
      squareFeet: { min: 100000000, max: 0 },
      priceSort: { min: 100000000, max: 0 },
      beds: { min: 100000000, max: 0 },
      baths: { min: 1000000, max: 0 },
      features: { selected: [], available: [] },
    }
  );
};

const cleanFeatureName = (featureName) => {
  return featureName.replace(/\s+/g, ' ').trim().toLowerCase();
};

const useHomes = (
  initialHomes,
  initialHomesOffset,
  initialFilters,
  params,
  noSpecialOffers,
  builderModel
) => {
  const [loadMoreNumber, setLoadMoreNumber] = useState(null);

  const [state, dispatch] = useReducer(homesReducer, {
    ...initialState,
    homes: initialHomes,
    homesOffset: initialHomesOffset ? initialHomesOffset : 10,
    filters: initialFilters
      ? { ...initialFilters }
      : { ...initialState.defaultFilterValues },
  });

  const location = useLocation();
  const isLandPage =
    location.pathname == '/land' || location.pathname == '/Land';

  const { homesOffset, filters, sortOrder } = state;

  useEffect(() => {
    (async () => {
      let filtersWithAdjustedLimits = null;
      let defaultFiltersWithAdjustedLimits = null;
      let urlParamsFilters = null;

      dispatch({ type: 'FETCH_HOMES_INIT' });
      try {
        const cleanedData = cleanData(initialHomes).filter((home) =>
          isLandPage ? home.isOnLand || home.isLand : true
        );

        if (Array.isArray(cleanedData)) {
          filtersWithAdjustedLimits = getFiltersWithAdjustedLimits(
            cleanedData,
            filters || initialState.defaultFilterValues,
            isLandPage
          );
          defaultFiltersWithAdjustedLimits = getFiltersWithAdjustedLimits(
            cleanedData,
            initialState.defaultFilterValues,
            isLandPage
          );
        }

        urlParamsFilters = getUrlParamValues(filtersWithAdjustedLimits, params);

        dispatch({ type: 'FETCH_HOMES_SUCCESS', payload: cleanedData });

        dispatch({
          type: 'SET_FILTERS',
          payload: {
            filters: {
              ...urlParamsFilters,
            },
            defaultFilters: defaultFiltersWithAdjustedLimits,
          },
        });
      } catch (error) {
        dispatch({ type: 'FETCH_HOMES_FAILURE', payload: error.message });
      }
    })();
  }, [initialHomes]);

  const cleanData = (data) => {
    return data.map((home) => {
      return {
        ...home,
        inventoryFeatures: [
          ...home.inventoryFeatures.map((featureName) =>
            cleanFeatureName(featureName)
          ),
        ],
        modelFeatures: [
          ...home.modelFeatures.map((featureName) =>
            cleanFeatureName(featureName)
          ),
        ],
      };
    });
  };

  const isHomeInStock = (home) => {
    return (
      !home.isAvailableFloorPlan &&
      home.serialNumber != null &&
      home.serialNumber !== ''
    );
  };

  const filteredHomes = useMemo(() => {
    if (!state.homes || !state.homes.length) return null;

    const filteredHomes = state.homes.filter((home) => {
      const filtered = Object.entries(filters).map(([key, value]) => {
        // Ranges
        if (
          ['beds', 'baths', 'priceSort', 'squareFeet', 'acres'].includes(key)
        ) {
          return (
            home[key] === null ||
            (home[key] >= value.min && home[key] <= value.max)
          );
        }
        // Home Type

        if (key === 'isMultiSection') {
          return value === null ? true : home.isMultiSection === value;
        }
        if (key === 'isCrossMod') {
          return value === null ? true : home.isCrossMod === value;
        }
        if (key === 'isSingleSection') {
          return value === null
            ? true
            : !home.isMultiSection && !home.isCrossMod;
        }
        // Land
        if (key === 'isLand') {
          return value === null ? true : home.isLand === value;
        }
        // Home on Land
        if (key === 'isOnLand') {
          return value === null ? true : home.isOnLand === value;
        }
        // isInStock
        if (key === 'isInStock') {
          return value === null || value === undefined
            ? true
            : home.stickerTypeId !== -1 && isHomeInStock(home) === value;
        }
        // Name or model
        if (key === 'text') {
          return value === null || value === ''
            ? true
            : (home.name !== null &&
                home.name.toLowerCase().includes(value.toLowerCase())) ||
                (home.modelNumber !== null &&
                  home.modelNumber
                    .toLowerCase()
                    .includes(value.toLowerCase())) ||
                (home.serialNumber !== null &&
                  home.serialNumber
                    .toLowerCase()
                    .includes(value.toLowerCase())) ||
                (home.modelName !== null &&
                  home.modelName.toLowerCase().includes(value.toLowerCase()));
        }
        // Features
        if (key === 'features') {
          if (value.selected.length === 0) return true;
          if (
            home.hasOwnProperty('modelFeatures') &&
            value.selected.every((selectedFeatureName) => {
              return home.modelFeatures.indexOf(selectedFeatureName) >= 0;
            })
          ) {
            return true;
          }
          return false;
        }
      });

      if (filtered.indexOf(false) < 0) {
        return home;
      }
      return false;
    });

    return filteredHomes;
  }, [state.homes, filters]);

  const sortedHomes = useMemo(() => {
    if (!filteredHomes) return [];
    switch (sortOrder) {
      case 'Default':
        return filteredHomes.sort(
          (prev, current) => prev.originalIndex - current.originalIndex
        );
      case 'Price (High to Low)':
        return filteredHomes.sort(
          (prev, current) => current.priceSort - prev.priceSort
        );
      case 'Price (Low to High)':
        return filteredHomes.sort(
          (prev, current) => prev.priceSort - current.priceSort
        );
      case 'Bedrooms':
        return filteredHomes.sort((prev, current) => current.beds - prev.beds);
      case 'Bathrooms':
        return filteredHomes.sort(
          (prev, current) => current.baths - prev.baths
        );
      case 'Square Feet':
        return filteredHomes.sort(
          (prev, current) => current.squareFeet - prev.squareFeet
        );
      case 'Acres':
        return filteredHomes.sort(
          (prev, current) => current.acres - prev.acres
        );
      default:
        return filteredHomes;
    }
  }, [filteredHomes, sortOrder]);

  const visibleHomes = useMemo(() => {
    return sortedHomes.slice(0, homesOffset);
  }, [sortedHomes, homesOffset, sortOrder]);

  useEffect(() => {
    if (visibleHomes && visibleHomes.length >= 21) {
      setLoadMoreNumber(12);
    } else if (visibleHomes && visibleHomes.length <= 21 && !builderModel) {
      setLoadMoreNumber(11);
    } else if (builderModel) {
      setLoadMoreNumber(12);
    } else {
      setLoadMoreNumber(10);
    }
  }, [visibleHomes, builderModel]);

  const loadMoreHomes = useMemo(() => {
    if (
      !visibleHomes ||
      !filteredHomes ||
      visibleHomes.length === filteredHomes.length
    ) {
      return null;
    }
    return () => {
      dispatch({ type: 'LOAD_MORE_HOMES', payload: loadMoreNumber });
    };
  }, [visibleHomes, filteredHomes, loadMoreNumber]);

  const updateFilters = (filters) => {
    dispatch({ type: 'UPDATE_FILTERS', payload: filters });
  };

  const clearFilter = (filterName) => {
    dispatch({ type: 'CLEAR_FILTER', payload: filterName });
  };

  const isFilterActive = (filterName) => {
    if (!state.defaultFilterValues) return false;

    return (
      JSON.stringify(state.filters[filterName]) !==
      JSON.stringify(state.defaultFilterValues[filterName])
    );
  };

  const updateSortOrder = (sortOrder) => {
    dispatch({ type: 'SET_SORT_ORDER', payload: sortOrder });
  };

  const totalHomes = useMemo(() => {
    return filteredHomes ? filteredHomes.length : 0;
  }, [filteredHomes]);

  return {
    ...state,
    visibleHomes,
    totalHomes,
    loadMoreHomes,
    updateFilters,
    clearFilter,
    isFilterActive,
    updateSortOrder,
  };
};

export default useHomes;
