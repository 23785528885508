import styled from 'styled-components';
import Colors from '../../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const HeaderStyles = styled.header`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 80px;
  }

  #logoContainer {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;

    @media only screen and (min-width: 768px) {
      margin-left: 2rem;
    }

    img {
      max-height: 36px;
      max-width: 115px;
      height: auto;
      width: 100%;

      @media only screen and (min-width: 768px) {
        max-height: 48px;
      }
    }
  }

  #hcPhoneNumber {
    display: flex;
    align-items: center;
    background-color: #0075c9;
    color: #fff;
    height: 100%;
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    padding: 0 1rem;
    text-decoration: none;
    font-family: 'source-sans-pro', sans-serif;

    @media only screen and (min-width: 768px) {
      padding: 0 2rem;
    }

    svg {
      margin-right: 0.75rem;
    }
  }
`;

export default HeaderStyles;
