import React from 'react';
import StyledWhyBuySection from './WhyBuy.styled';
import SaveMoneyIcon from './SaveMoneyIcon';
import QualityToolsIcon from './QualityToolsIcon';
import CustomerServiceIcon from './CustomerServiceIcon';

const WhyBuy = () => {
  return (
    <StyledWhyBuySection>
      <h3 className="why-buy-header">Why Buy From us</h3>
      <div className="container">
        <div className="row">
          <div className="why-buy-section">
            <div className="svg-container">
              <SaveMoneyIcon />
            </div>
            <h4 className="why-buy-section-header">Affordability</h4>
            <p className="why-buy-section-content">
              With a variety of mobile home floor plans to choose from, we have
              options to fit any lifestyle and budget!
            </p>
          </div>
          <div className="why-buy-section">
            <div className="svg-container">
              <QualityToolsIcon />
            </div>
            <h4 className="why-buy-section-header">Quality</h4>
            <p className="why-buy-section-content">
              Our mobile homes are built inside climate controlled home building
              facilities to minimize the risk of weather damage or delays.
            </p>
          </div>
          <div className="why-buy-section">
            <div className="svg-container">
              <CustomerServiceIcon />
            </div>
            <h4 className="why-buy-section-header">Friendly Service</h4>
            <p className="why-buy-section-content">
              Our team members are knowledgeable about mobile homes and eager to
              answer any questions you might have.
            </p>
          </div>
        </div>
      </div>
    </StyledWhyBuySection>
  );
};

export default WhyBuy;
