const EnergySmartTestimonialsContent = [
  {
    quote: '"I love my new home - special ordered it with Energy Smart!"',
    customer: '- Bonnie C., New Iberia, LA',
  },
  {
    quote:
      '"This is a great investment and we are enjoying every day in our new home. With money we have saved we are able to do more adventurous activities with our family."',
    customer: '- PATRICIA R., TALLAHASSEE, FL',
  },
  {
    quote:
      '"Not worrying about unexpected heating and cooling cost has been a very relieving experience, especially since this was my first time buying a home."',
    customer: '- MICHAEL M., GREENSBORO, NC',
  },
  {
    quote:
      '"I have been impressed with the Energy Smart package. Having lived in a mobile home most of my life I could tell the difference within the first week. I am very happy I made this choice."',
    customer: '- Paul M., Houston, TX',
  },
];

export default EnergySmartTestimonialsContent;
