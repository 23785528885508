import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../../App';
import InteractiveFacilityTourStyles from './InteractiveFacilityTour.styled';
import Section from './InteractiveTourSections/Section';
import InteractiveTourCTA from './InteractiveTourCTA';
import { parseInitialPropsContext } from '../../utils';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { formatDealerName } from '../Shared/SmokeyTransitional/utils/formatters';
import VideoPoster from '../../images/webp/cover-poser.webp';
import FlooringSectionImage from '../../images/webp/flooring-section.webp';
import FramingSectionImage from '../../images/webp/framing-section.webp';
import CabinetVideoImage from '../../images/webp/cabinet-video.webp';
import RoofVideoImage from '../../images/webp/roof-video.webp';
import FinalFinishImage from '../../images/webp/final-finish-section.webp';
import { Redirect } from 'react-router-dom';

const InteractiveFacilityTour = ({ dealer, homes, isLoading }) => {
  const scrollDown = () => {
    const top = window.innerHeight - 10;
    window.scroll({ top, behavior: 'smooth' });
  };

  const isOdd = (x) => x % 2 !== 0;

  const send = (
    eventCategory,
    eventAction,
    eventLabel = null,
    eventValue = null
  ) => {
    if (!eventCategory) {
      throw new Error('eventCategory is required');
    }

    if (!eventAction) {
      throw new Error('eventAction is required');
    }

    try {
      if (ga) {
        ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue);
      }
    } catch (err) {}
  };

  const trackGAConversion = (eventCategory, eventAction, eventLabel) => {
    send(eventCategory, eventAction, eventLabel);
  };

  const sections = [
    {
      id: 0,
      body: 'Our process begins by constructing a subfloor system that sits on top of a solid foundation. This creates a strong infrastructure for your home’s flooring to ensure it withstands the tests of time.',
      header: 'Above and beyond.',
      imageUrl: FlooringSectionImage,
      videoUrl: null,
      posterUrl: null,
      matterportUrl:
        'https://my.matterport.com/show?m=1HNVdCzGPfV&start=%7B%22camera_mode%22%3A1%2C%22camera_position%22%3A%7B%22x%22%3A63.33538818359375%2C%22y%22%3A1.5739681720733643%2C%22z%22%3A0.41876694560050964%7D%2C%22camera_quaternion%22%3A%7B%22x%22%3A-0.06708390853090276%2C%22y%22%3A-0.29631707043669897%2C%22z%22%3A-0.020869353291771264%2C%22w%22%3A0.9525021853398621%7D%2C%22scan_id%22%3A%22a50c46800f084d2596c19adc22efcf79%22%7D&qs=1',
      title: 'Flooring',
      gaLabel: 'flooring',
    },
    {
      id: 1,
      body: 'At an adjacent station, trained craftsmen are busy working on your walls. Once complete, the interior walls are installed first, then exterior walls are lifted into place by a track system in the ceiling.',
      header: 'Rising to the occasion.',
      imageUrl: FramingSectionImage,
      videoUrl: null,
      posterUrl: null,
      matterportUrl:
        'https://my.matterport.com/show?m=Up5vmLqoZ65&start=%7B%22camera_mode%22%3A1%2C%22camera_position%22%3A%7B%22x%22%3A61.018165588378906%2C%22y%22%3A1.547214388847351%2C%22z%22%3A-30.575090408325195%7D%2C%22camera_quaternion%22%3A%7B%22x%22%3A0.022315426561194122%2C%22y%22%3A0.8640257171941721%2C%22z%22%3A0.03844830682691767%2C%22w%22%3A-0.5014811157627292%7D%2C%22scan_id%22%3A%223055d66a5bc84b2dbbe89f4ab6165d6b%22%7D&qs=1',
      title: 'Framing',
      gaLabel: 'framing',
    },
    {
      id: 2,
      body: 'For us, cabinets aren’t an afterthought. We have a team of dedicated carpenters building custom cabinetry right alongside the rest of the house. It’s the same kind of installation you’d find in the finest site-built homes, only better.',
      header: 'Made in house.',
      imageUrl: null,
      videoUrl:
        'https://www.claytonhomes.com/videos/dreamtoreality/cabinet-video.mp4',
      mobileVideoUrl:
        'https://www.claytonhomes.com/videos/dreamtoreality/cabinet-video-mobile.mp4',
      posterUrl: CabinetVideoImage,
      matterportUrl:
        'https://my.matterport.com/show?m=fGdt2ZMVDb7&start=%7B%22camera_mode%22%3A1%2C%22camera_position%22%3A%7B%22x%22%3A34.97455596923828%2C%22y%22%3A1.4619108438491821%2C%22z%22%3A5.526968002319336%7D%2C%22camera_quaternion%22%3A%7B%22x%22%3A-0.034001093404030874%2C%22y%22%3A-0.11909213884977944%2C%22z%22%3A-0.0040806926622200925%2C%22w%22%3A0.9922924649814245%7D%2C%22scan_id%22%3A%228f4a1b6645004df6985fba2af192a035%22%7D&qs=1',
      title: 'Cabinet Shop',
      gaLabel: 'cabinet-shop',
    },
    {
      id: 3,
      body: 'It’s a whole lot easier to make a better roof when your feet are firmly planted on the ground. That’s why we assemble the foundation for your roof — a meticulously engineered truss system — at eye-level.',
      header: (
        <span>
          Make the roof,
          <br /> then raise it.
        </span>
      ),
      imageUrl: null,
      videoUrl:
        'https://www.claytonhomes.com/videos/dreamtoreality/roof-video.mp4',
      posterUrl: RoofVideoImage,
      matterportUrl:
        'https://my.matterport.com/show?m=Ut3maZU8v8o&start=%7B%22camera_mode%22%3A1%2C%22camera_position%22%3A%7B%22x%22%3A31.13219451904297%2C%22y%22%3A1.3611764907836914%2C%22z%22%3A20.3480281829834%7D%2C%22camera_quaternion%22%3A%7B%22x%22%3A-0.06191793668843797%2C%22y%22%3A0.8633985158632239%2C%22z%22%3A0.1094124780985135%2C%22w%22%3A0.4886083109790206%7D%2C%22scan_id%22%3A%22a34d227400df4b8e8d117d5a87286537%22%7D',
      title: 'Roof Build',
      gaLabel: 'roof-build',
    },
    {
      id: 4,
      body: "Here's where we install the home features — like stainless steel appliances and energy-efficient light bulbs — and add all the finishing touches. Once everything has been inspected and approved, we give your house a long, heartfelt goodbye before sending it off to its new home.",
      header: "It's hard to say goodbye.",
      imageUrl: FinalFinishImage,
      videoUrl: null,
      posterUrl: null,
      matterportUrl:
        'https://my.matterport.com/show/?m=6ptgfr8Xx1q&start=%7B%22camera_position%22:%7B%22x%22:-16.1535,%22y%22:1.564,%22z%22:8.2684%7D,%22camera_quaternion%22:%7B%22x%22:-0.0075,%22y%22:0.9319,%22z%22:0.0194,%22w%22:0.3621%7D,%22camera_mode%22:1,%22scan_id%22:%22ad7697e220fd47ee87077f4078594793%22%7D&qs=1',
      title: 'Final Finish',
      gaLabel: 'final-finish',
    },
  ];

  if (dealer?.websiteTemplateDescription == 'BuilderModel') {
    return <Redirect to="/" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <InteractiveFacilityTourStyles>
        <Helmet>
          <title>
            Interactive Facility Tour | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={`Take a look at these beautiful sale homes from ${formatDealerName(
              dealer.dealerName
            )}. We build beautiful, durable and quality built homes at many different price points. Come by and visit our location today!`}
          />
          <meta
            property="og:description"
            content={`Take a look at these beautiful sale homes from ${formatDealerName(
              dealer.dealerName
            )}. We build beautiful, durable and quality built homes at many different price points. Come by and visit our location today!`}
          />
        </Helmet>
        <div className="container">
          <div id="facilityTourWrapper">
            <div className="facilityTourHeader">
              <div className="header-inner">
                <div className="content-wrapper">
                  <h2 className="section-title">The great indoors.</h2>
                  <p className="section-header">
                    Every Clayton Built&reg; home is constructed inside a
                    state-of-the-art, climate-controlled facility, away from the
                    elements. It’s a smarter way to build that fosters
                    innovation and ensures every aspect of your home is of the
                    highest quality.
                  </p>
                </div>
              </div>
              <div className="video">
                <video
                  muted={true}
                  autoplay="true"
                  playsinline="true"
                  loop
                  src="https://www.claytonhomes.com/videos/dreamtoreality/cover-video.mp4"
                  poster={VideoPoster}
                />
              </div>
              <div className="scroll-wrapper" onClick={scrollDown}>
                <div>SCROLL</div>
                <div className="scroll-button" />
              </div>
            </div>
            <div className="facility-tour-padded">
              {sections.map((section, i) => (
                <Section
                  alt={isOdd(i)}
                  body={section.body}
                  header={section.header}
                  imageUrl={section.imageUrl}
                  matterportUrl={section.matterportUrl}
                  videoUrl={section.videoUrl}
                  mobileVideoUrl={section.mobileVideoUrl}
                  posterUrl={section.posterUrl}
                  title={section.title}
                  gaLabel={section.gaLabel}
                  key={section.id}
                  trackGAConversion={trackGAConversion}
                />
              ))}
              <InteractiveTourCTA dealer={dealer} homes={homes} />
            </div>
          </div>
        </div>
      </InteractiveFacilityTourStyles>
    </App>
  );
};

InteractiveFacilityTour.getInitialProps = async (ctx) => {
  const data = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  ).catch((e) => {
    console.error(e);
    return { redirectTo: '/error' };
  });
  return data;
};

export default InteractiveFacilityTour;
