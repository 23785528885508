import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import App from '../../App';
import crossmodContentfulSample from '../../content/crossmodContentful';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import CrossModCTA from '../Shared/CrossModCTA/CrossModCTA';
import CrossModThreeUp from '../Shared/CrossModThreeUp/CrossModThreeUp';
import HeaderWithImage from '../Shared/HeaderWithImage/HeaderWithImage';
import TextContent from '../Shared/TextContent/TextContent';
import TextWithImage from '../Shared/TextWithImage/TextWithImage';
import TextWithVideo from '../Shared/TextWithVideo/TextWithVideo';
import XLCTA from '../Shared/XLCTA/XLCTA';
import CrossModStyles from './CrossMod.styled';

const CrossMod = ({ dealer, homes, isLoading }) => {
  if (dealer?.websiteTemplateDescription === 'BuilderModel') {
    return <Redirect to="/" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <CrossModStyles>
        <Helmet>
          <title>CrossMod Homes | {formatDealerName(dealer?.dealerName)}</title>
          <meta
            name="description"
            content={`Learn more about Clayton's new class of manufactured homes called CrossMod Homes which are affordable homes that hold many of the benefits of traditional site built homes.`}
          />
        </Helmet>
        {crossmodContentfulSample &&
          crossmodContentfulSample.map((item, i) => (
            <React.Fragment key={item.headerWithImage.smallHeadline.trim()}>
              <HeaderWithImage
                smallHeadline={item.headerWithImage.smallHeadline}
                largeHeadline={item.headerWithImage.largeHeadline}
                content={item.headerWithImage.content}
                image={item.headerWithImage.image}
              />
              <TextWithImage
                smallHeadline={item.textWithImage.smallHeadline}
                largeHeadline={item.textWithImage.largeHeadline}
                content={item.textWithImage.content}
                citation={item.textWithImage.citation}
                image={item.textWithImage.image}
              />
              <TextWithVideo
                largeHeadline={item.textWithVideo.largeHeadline}
                content={item.textWithVideo.content}
                features={item.textWithVideo.features}
                videoImage={item.textWithVideo.videoImage}
                wistiaID={item.textWithVideo.wistiaID}
              />
              <TextContent
                smallHeadline={item.textContent.smallHeadline}
                largeHeadline={item.textContent.largeHeadline}
                content={item.textContent.content}
              />
              <CrossModThreeUp items={item.threeUp.items} />
              <CrossModCTA
                headline={item.crossModCTA.headline}
                content={item.crossModCTA.content}
                buttons={item.crossModCTA.buttons}
                fineprint={item.crossModCTA.fineprint}
              />
            </React.Fragment>
          ))}
        <XLCTA
          largeText="Get the perks of a CrossMod™ home"
          dealer={dealer}
          homes={homes}
          buttons={[
            {
              buttonText: 'View Homes',
              buttonURL: '/homes',
              gaClass: 'ga-crossmod-view-homes',
              retailEventLabel: 'crossmod_footer_view_homes',
            },
          ]}
        />
      </CrossModStyles>
    </App>
  );
};

CrossMod.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService).catch(
    (e) => {
      console.error(e);
      return { redirectTo: '/error' };
    }
  );
};

export default CrossMod;
