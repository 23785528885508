import React from 'react';
import { Link } from 'react-router-dom';

export const InteractiveTourCTA = ({ dealer, homes }) => {
  return (
    <div id="ctaWrapper">
      <div className="cta-content">
        <div className="cta-content-inner">
          <h2 className="cta-header">See the finished product.</h2>
          <Link
            className="ift-cta-button"
            to={{ pathname: '/homes', state: { dealer, homes } }}
          >
            View Homes
          </Link>
        </div>
      </div>
      <div className="cta-border" />
      <div className="cta-background" />
    </div>
  );
};

export default InteractiveTourCTA;
