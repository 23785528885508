import React from 'react';
import StyledFooter from './Footer.styled';

import txLogo from '../../../images/txLogo.png';

const Footer = ({ legalUrl, dealer }) => {
  const year = new Date().getFullYear();

  const registrationNumber = () => {
    if (dealer.licenseRegistrationNumber.length === 7) {
      return (
        <p>
          RBI# MHDRET000
          {dealer.licenseRegistrationNumber
            .replace(/\D/g, '')
            .slice(dealer.licenseRegistrationNumber.length - 5)}
        </p>
      );
    }

    if (dealer.licenseRegistrationNumber.length <= 6) {
      return (
        <p>
          RBI# MHDRET000
          {dealer.licenseRegistrationNumber.replace(/\D/g, '')}
        </p>
      );
    }
  };

  return (
    <StyledFooter>
      <div
        className={
          dealer?.stateProvince.toLowerCase() == 'tx'
            ? 'footer-wrap tx'
            : 'footer-wrap'
        }
      >
        <p className="footer-copyright">
          &copy; 1998-{year} Clayton. All rights reserved. &nbsp;&nbsp;
        </p>
        <a href={legalUrl}>Legal</a>
        <a href="https://www.claytonhomes.com/privacy/" target="_blank">
          Privacy
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            typeof window !== 'undefined' &&
              window?.Osano?.cm &&
              window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
          }}
        >
          Do Not Sell or Share My Personal Information
        </a>
        {dealer?.stateProvince.toLowerCase() == 'tx' && (
          <div className="tx-logo-wrap">
            <a
              className="tx-logo"
              target="_blank"
              href="http://www.tdhca.state.tx.us/mh/"
            >
              <img src={txLogo} alt="Texas Department of Housing Logo" />
            </a>
            {dealer?.licenseRegistrationNumber && registrationNumber()}
          </div>
        )}
      </div>
    </StyledFooter>
  );
};

export default Footer;
