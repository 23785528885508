import styled from 'styled-components';
import Colors from '../../colors';

const SiteFeedback = styled.section`
  .loading-block {
    min-height: calc(100vh - 89px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-family: 'acumin-pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    background: ${Colors.primary.claytonBlue.standard};
    padding: 75px 0;
    color: ${Colors.primary.white.standard};
  }
  .gray-section {
    position: relative;
    background: #f7f9fb;
    padding: 50px 0px;
    .container {
      max-width: 680px;
      margin: 0 auto;
      padding: 20px 20px;
      .content {
        position: relative;
        margin-bottom: 5px;
        p {
          font-size: 16px;
          line-height: 27px;
          margin-bottom: 10px;
        }
        a {
          color: ${Colors.primary.claytonBlue.standard};
          text-decoration: none;
          font-family: 'source-sans-pro', sans-serif;
          font-weight: 400;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .form-box {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding-top: 70px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-bottom: 15px;
    display: flex;
    @media only screen and (max-width: 775px) {
      padding-top: 50px;
      padding-bottom: 0px;
    }
  }
`;

export default SiteFeedback;
