import React from 'react';
import PropTypes from 'prop-types';
import SectionContent from './SectionContent';
import SectionBackground from './SectionBackground';
import useWindowSize from '../../../hooks/useWindowSize';

export const Section = ({
  alt,
  body,
  header,
  imageUrl,
  videoUrl,
  mobileVideoUrl,
  posterUrl,
  matterportUrl,
  title,
  gaLabel,
  trackGAConversion,
}) => {
  const [width, height] = useWindowSize();
  const isDesktop = width && width >= 900;

  const getVideoUrl = () => {
    return isDesktop || !mobileVideoUrl ? videoUrl : mobileVideoUrl;
  };

  return (
    <div id="sectionWrapper">
      <SectionContent
        alt={alt}
        title={title}
        header={header}
        body={body}
        matterportUrl={matterportUrl}
        gaLabel={gaLabel}
        trackGAConversion={trackGAConversion}
      />
      <SectionBackground
        alt={alt}
        imageUrl={imageUrl}
        videoUrl={getVideoUrl()}
        posterUrl={posterUrl}
      />
    </div>
  );
};

Section.defaultProps = {
  alt: false,
  body: '',
  header: '',
  imageUrl: null,
  videoUrl: null,
  mobileVideoUrl: null,
  posterUrl: null,
  matterportUrl: '',
  title: '',
  gaLabel: '',
};

Section.props = {
  alt: PropTypes.bool,
  body: PropTypes.string,
  header: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  mobileVideoUrl: PropTypes.string,
  posterUrl: PropTypes.string,
  matterportUrl: PropTypes.string,
  title: PropTypes.string,
  gaLabel: PropTypes.string,
};

export default Section;
