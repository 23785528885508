import React from 'react';
import PropTypes from 'prop-types';

const PlayBtn = ({ color, width, height }) => (
  <svg
    id="playBtn"
    width={width}
    height={height}
    viewBox="0 0 147 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.5 94.0723C97.5244 94.0723 117 74.5966 117 50.5723C117 26.5479 97.5244 7.07227 73.5 7.07227C49.4756 7.07227 30 26.5479 30 50.5723C30 74.5966 49.4756 94.0723 73.5 94.0723Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.2736 49.7953C85.8602 50.1929 85.8586 51.0577 85.2705 51.453L68.0579 63.0249C67.3936 63.4715 66.5 62.9955 66.5 62.195V38.958C66.5 38.1558 67.397 37.6802 68.061 38.1302L85.2736 49.7953Z"
      fill="#0075C9"
    />
    <defs>
      <filter
        id="filter0_dd"
        x="0"
        y="0.0722656"
        width="147"
        height="150"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="26" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.146647 0 0 0 0 0.159478 0 0 0 0 0.175516 0 0 0 0.0467657 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow"
          result="effect2_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

PlayBtn.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

PlayBtn.defaultProps = {
  color: '#0075C9',
  width: '147',
  height: '151',
};

export default PlayBtn;
