import React from 'react';
import StyledCityBlurb from './CityBlurb.styled';

const CityBlurb = ({ dealerName, dealerDescription }) => {
  return (
    <StyledCityBlurb>
      <h2 className="title">{dealerName}</h2>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: dealerDescription }}
      />
    </StyledCityBlurb>
  );
};

export default CityBlurb;
