import TestimonialImg01 from '../images/testimonialImage01.jpg';
import TestimonialImg02 from '../images/testimonialImage02.jpg';
import TestimonialImg03 from '../images/testimonialImage03.jpg';
import TestimonialImg04 from '../images/testimonialImage04.jpg';
import TestimonialImg05 from '../images/testimonialImage05.jpg';

const staticTestimonials = [
  {
    id: 1,
    image: {
      alt: 'testimonial image',
      source: TestimonialImg01,
    },
    quote:
      'It has 9 foot ceilings. People are shocked. I probably couldn’t have found a stick-built house I would want more.',
    attribution: 'The Bird Family',
    slug: 'https://www.claytonhomes.com/studio/clayton-home-review-bird-family/',
  },
  {
    id: 2,
    image: {
      alt: 'testimonial image',
      source: TestimonialImg02,
    },
    quote:
      'We did our research online and learned about the building process. We liked how they were energy efficient and how prefabs were built.',
    attribution: 'The Duran Family',
    slug: 'https://www.claytonhomes.com/studio/duran-clayton-homes-review/',
  },
  {
    id: 3,
    image: {
      alt: 'testimonial image',
      source: TestimonialImg03,
    },
    quote:
      'Everyone that comes to visit the first time talks about how nice it is. It is great for living the life we want without being in debt.',
    attribution: 'The Phillips Family',
    slug: 'https://www.claytonhomes.com/studio/how-the-phillips-have-it-made/',
  },
  {
    id: 4,
    image: {
      alt: 'testimonial image',
      source: TestimonialImg04,
    },
    quote: `We've always had to travel during the holidays. We're looking forward to telling everyone, "We're here, the kids are here, come see us," and doing things in our own home this year.`,
    attribution: 'The Fountain Family',
    slug: 'https://www.claytonhomes.com/studio/prefabricated-home-testimonial-fountain/',
  },
  {
    id: 5,
    image: {
      alt: 'testimonial image',
      source: TestimonialImg05,
    },
    quote: `We knew they'd made all kinds of improvements since we bought our first in 1991, so we definitely wanted to go with Clayton again.`,
    attribution: 'The Wood Family',
    slug: 'https://www.claytonhomes.com/studio/once-a-clayton-homeowner-always-a-clayton-homeowner/',
  },
];

export default staticTestimonials;
