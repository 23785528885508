import React from 'react';
import PropTypes from 'prop-types';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import SiteFeedbackThankYouStyles from './SiteFeedbackThankYou.styled';
import ContactHouse from './ContactHouse';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';

const SiteFeedbackThankYou = ({ closeFunction, dealer }) => {
  return (
    <SiteFeedbackThankYouStyles>
      <div className="h3">{formatDealerName(dealer?.dealerName)}</div>
      <ContactHouse />
      <p>Thank you for reaching out.</p>
      <Button
        text="Okay"
        onClick={closeFunction}
        automationId="siteFeedTYBtn"
      />
      <div className="disclaimer" />
    </SiteFeedbackThankYouStyles>
  );
};

SiteFeedbackThankYou.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SiteFeedbackThankYou;
