import React from 'react';
import StyledHomesContainer from './HomesContainer.styled';
import HomeCard from './HomeCard';

const HomesContainer = ({
  homes,
  toTitleCase,
  handleHomeClick,
  handlePricingClick,
}) => {
  return (
    <StyledHomesContainer>
      <div className="container">
        <h3 className="homes-title">Schedule A Tour To View These Homes</h3>
        <div className="row">
          {homes.map((home, index) => (
            <div key={index} className="home-card-wrapper">
              <HomeCard
                home={home}
                toTitleCase={toTitleCase}
                handleHomeClick={handleHomeClick}
                handlePricingClick={handlePricingClick}
              />
            </div>
          ))}
        </div>
      </div>
    </StyledHomesContainer>
  );
};

export default HomesContainer;
