import styled from 'styled-components';
import Colors from '../../../colors';

const StyledHomeCard = styled.div`
  max-width: 480px;
  margin: 0 0 3rem;
  background-color: #fff;
  box-shadow: 0 2px 16px rgb(0 0 0 / 8%);

  .home-card-banner {
    padding-bottom: 62%;
    background-position: 50% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  .home-card-content {
    padding: 2rem;

    .model-name {
      font-family: 'acumin-pro', sans-serif;
      font-size: 26px;
      font-weight: 600;
      color: #414042;
      line-height: 1.1;
      margin-bottom: 0.75rem;
      letter-spacing: normal;
    }

    .model-price {
      font-family: 'source-sans-pro', sans-serif;
      font-size: 19px;
      line-height: 1;
      font-weight: 500;
      color: #6d6e71;
      margin-bottom: 1rem;
    }

    .model-specs {
      padding: 0;
      margin-bottom: 1.5rem;
      font-family: 'source-sans-pro', sans-serif;
      font-size: 1rem;
      line-height: 1;
      font-weight: 500;
      color: #6d6e71;
    }

    .info-button {
      background-color: #0075c9;
      padding: 16px 0;
      width: 137px;
      font-size: 17px;
      color: #fff;
      border: none;
      border-radius: 2px;
      text-decoration: none;
      box-shadow: 0 2px 16px rgb(0 0 0 / 8%);
      display: block;
      text-align: center;
      font-family: 'source-sans-pro', sans-serif;
    }
  }
`;

export default StyledHomeCard;
